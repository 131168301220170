import { createSelector, createSlice } from '@reduxjs/toolkit'
import {
  getCoachOnboardingSteps,
  updateItemStatus,
  updateOnboardingStatusForAdmin,
} from '../actions/coachOnboardingActions'


const initialState = {
  onboardingSteps: [],
  isOnboardingStepsLoading: false,
  isAdminOnboardingStatusUpdating: false,
  onboardingStatusUpdateError: false,
  isModalVisible: false,
  focusedStep: 0,
  // Used for Admin Panel modal
  selectedCoachOnboardingStatus: { status: 'started', lastModified: '' },
}

const coachOnboardingSlice = createSlice({
  name: 'coachOnboarding',
  initialState,
  reducers: {
    setFocusedStep: (state, action) => {
      state.focusedStep = action.payload
    },
    setIsModalVisible: (state, action) => {
      state.isModalVisible = action.payload
    },
    setSelectedCoachOnboardingStatus: (state, action) => {
      state.selectedCoachOnboardingStatus.status = action.payload.status
      state.selectedCoachOnboardingStatus.lastModified = action.payload.lastModified
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCoachOnboardingSteps.pending, state => {
        state.isOnboardingStepsLoading = true
      })
      .addCase(getCoachOnboardingSteps.fulfilled, (state, action) => {
        state.isOnboardingStepsLoading = false
        state.onboardingSteps = action.payload.onboardingSteps
      })
      .addCase(getCoachOnboardingSteps.rejected, state => {
        state.isOnboardingStepsLoading = false
      })
      .addCase(updateItemStatus.fulfilled, (state, action) => {
        const { coachOnboardingStep: updatedStep, ...updatedItem } = action.payload.coachOnboardingItem
        const previousStep = state.onboardingSteps.find(step => step.id === updatedStep.id)

        if (previousStep) {
          const previousItem = previousStep.coachOnboardingItems.find(item => item.id === updatedItem.id)

          if (previousItem) {
            Object.assign(previousItem, updatedItem)
          }
        }
      })
      .addCase(updateOnboardingStatusForAdmin.pending, state => {
        state.isAdminOnboardingStatusUpdating = true
        state.onboardingStatusUpdateError = false
      })
      .addCase(updateOnboardingStatusForAdmin.rejected, state => {
        state.isAdminOnboardingStatusUpdating = false
        state.onboardingStatusUpdateError = true
      })
      .addCase(updateOnboardingStatusForAdmin.fulfilled, state => {
        state.isAdminOnboardingStatusUpdating = false
        state.onboardingStatusUpdateError = false
      })
  },
})

export const selectOnboardingItems = createSelector(
  state => state.coachOnboarding.onboardingSteps,
  onboardingSteps => onboardingSteps.flatMap(step => step.coachOnboardingItems)
)

export const selectIsOnboardingComplete = createSelector(
  [selectOnboardingItems],
  onboardingItems => onboardingItems.every(item => item.completed)
)

export const selectCompleteItemsCount = createSelector(
  [selectOnboardingItems],
  onboardingSteps => onboardingSteps.filter(item => item.completed).length
)

export const selectTotalItemsCount = createSelector(
  [selectOnboardingItems],
  onboardingItems => onboardingItems.length
)

export const selectOnboardingProgress = createSelector(
  [selectCompleteItemsCount, selectTotalItemsCount],
  (completedItemsCount, totalItemsCount) => Math.ceil(completedItemsCount / (totalItemsCount || 1) * 100)
)

export const selectFirstUncompletedStepIndex = createSelector(
  state => state.coachOnboarding.onboardingSteps,
  (onboardingSteps) => onboardingSteps.findIndex(step => step.coachOnboardingItems.some(item => !item.completed))
)

export const selectIsFocusedStepCompleted = createSelector(
  [state => state.coachOnboarding.onboardingSteps, state => state.coachOnboarding.focusedStep],
  (onboardingSteps, focusedStep) => {
    if (!onboardingSteps?.[focusedStep]) { return false }

    return onboardingSteps[focusedStep].coachOnboardingItems.every(item => item.completed)
  }
)

export const selectIsOnboardingEnabled = createSelector(
  [state => state.user.current_user.user_settings.coach_onboarding_status,
    state => state.user.current_user.type],
  (onboarding_status, userType) => userType === 'Coach' && onboarding_status === 'started'
)

export const { setFocusedStep, setIsModalVisible, setSelectedCoachOnboardingStatus } = coachOnboardingSlice.actions

export default coachOnboardingSlice.reducer
