import { UncontrolledPopover } from 'reactstrap'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

import { ReactComponent as DropDownIcon } from '../../../assets/images/common/icons/drop-down-arrow.svg'
import styles from './DefaultMultiActionsPopover.module.scss'
import PropTypes from 'prop-types'

export const DefaultMultiActionsPopover = ({ title, isModalOpen, actions }) => (
  <div>
    <button id='page-header-actions-button' className='btn btn-primary px-3'>
      <span className='mr-2'>{title}</span>
      <DropDownIcon stroke='white' style={{ marginLeft: '0.3rem' }} />
    </button>
    {!isModalOpen && (
      <UncontrolledPopover
        placement='bottom'
        target='page-header-actions-button'
        trigger='legacy'
        popperClassName={styles.popper}
      >
        <div className='d-flex flex-column pt-1 pb-2'>
          {actions.map((action, idx) => (
            action.link ? (
              <Link key={idx} className={classnames(styles.action, { [styles[action.color]]: !!action.color })} to={action.to}>
                {action.label}
              </Link>
            ) : (
              <button
                key={idx}
                className={classnames(
                  styles.action,
                  {
                    [styles[action.color]]: !!action.color,
                    'd-flex justify-content-between align-items-center': action.isLoading,
                  })}
                onClick={action.onClick}
              >
                {action.label}
                {action.isLoading && <i className='fa fa-spinner fa-spin'/>}
              </button>
            )
          ))}
        </div>
      </UncontrolledPopover>
    )}
  </div>
)

DefaultMultiActionsPopover.propTypes = {
  title: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.bool,
    label: PropTypes.string.isRequired,
    to: PropTypes.string,
    onClick: PropTypes.func,
    color: PropTypes.string,
  })).isRequired,
}

export default DefaultMultiActionsPopover
