import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { ReactComponent as BackArrowIcon } from '../../../assets/images/common/icons/programBuilder/back-arrow-icon.svg'
import { ReactComponent as OpenEyeIcon } from '../../../assets/images/common/icons/open-eye-icon.svg'
import { resetBuilder } from '../../../reducers/programBuilderReducer'
import ExitModal from './Modals/ExitModal'
import PreviewModal from './Modals/PreviewModal'

import styles from './BuilderHeader.module.scss'

const BuilderHeader = ({ onSaveProgram, setShowSaveModal, loader }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { id, isDraft } = useSelector(state => state.programBuilder)
  const [showExitModal, setShowExitModal] = useState(false)
  const [showPreviewModal, setShowPreviewModal] = useState(false)

  return (
    <section className={`row ${styles['header-container']}`}>
      <div
        className={`col-12 col-md-6 d-flex align-items-center ${styles['back-section']}`}
        onClick={() => setShowExitModal(true)}
        aria-hidden='true'
      >
        <BackArrowIcon aria-hidden='true' className='flex-shrink-0 mr-3' />
        <p className={`text-nowrap ${styles.title}`}>{id ? 'Edit Program' : 'Create Program'}</p>
      </div>

      <div className='col-12 col-md-6 d-flex flex-wrap justify-content-center align-items-center mb-2 mb-md-0'>
        <button
          className={`btn btn-primary ${styles['action-button']} ml-md-auto my-1 mx-3`}
          onClick={() => setShowPreviewModal(true)}
        >
          <div className='d-flex align-items-center'>
            <OpenEyeIcon aria-hidden='true' className={styles['preview-icon']} />
            <span className='ml-2'>See Preview</span>
          </div>
        </button>

        <button
          className={`btn btn-primary ${styles['action-button']} my-1`}
          disabled={loader}
          onClick={(id && !isDraft) ? () => onSaveProgram(false) : () => setShowSaveModal(true)}
        >
          {(id && !isDraft) ? 'Save' : 'Save or Publish'}
        </button>
      </div>

      <ExitModal
        showModal={showExitModal}
        closeModal={() => setShowExitModal(false)}
        onExit={() => { id ? navigate(`/programs/${id}`) : navigate('/programs') }}
      />

      <PreviewModal
        showModal={showPreviewModal}
        closeModal={() => setShowPreviewModal(false)}
        onSaveProgram={onSaveProgram}
        loader={loader}
        setShowSaveModal={setShowSaveModal}
      />
    </section>
  )
}

BuilderHeader.propTypes = {
  onSaveProgram: PropTypes.func.isRequired,
  setShowSaveModal: PropTypes.func.isRequired,
  loader: PropTypes.bool.isRequired,
}

export default BuilderHeader
