import PropTypes from 'prop-types'
import React, { Component } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { displayWithCurrency } from '../../../utility/currency'
import { checkLicensePermission, licenseLabel, licenseColor } from '../../../utility/communityLicense'

//REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouting } from '../../common/hooks'
import * as commonActions from '../../../actions/commonActions'
import * as communitiesActions from '../../../actions/communitiesActions'
import * as communityFlagsActions from '../../../actions/communityFlagsActions'
import * as representativesActions from '../../../actions/representativesActions'
import * as representativeNotesActions from '../../../actions/representativeNotesActions'
import * as reportsActions from '../../../actions/reportsActions'
import * as authActions from '../../../actions/authActions'
import * as adminTagActions from '../../../actions/adminTagActions'
import { requestMakeLeadCoach } from '../../../actions/coachActions'
import { allFilters } from '../../../accolades/communityMapFilters'

// CHILDREN
import Loader from '../../common/Op36Loader-web'
import CommunityStatCard from './CommunityStatCard'
import CommunityStatCircle from './CommunityStatCircle'
import CommunityNotes from './Notes/CommunityNotes'
import CommunityFlags from './Flags/CommunityFlags'
import CoachRow from './CoachRow'
import RepresentativeNotesForm from './Notes/RepresentativeNotesForm'
import CommunityDetailCard from './CommunityDetailCard'
import CommunityFlagsForm from './Flags/CommunityFlagsForm'
import FeatureCard from './FeatureCard'
import DatePicker from '../../common/DatePicker'
import AdminTagIndex from '../../common/AdminTagIndex'
import BaseModal from '../../common/BaseModal'
import SubmitButton from '../../common/buttons/SubmitButton'

// ASSETS
import './CommunityRepDetail.scss'
import Placeholder from '../../../assets/images/mock/StockProfilePic.png'

class CommunityRepDetail extends Component {
  state = {
    sectionState: 'notes',
    support_rating: 0,
    showNoteSection: false,
    showFlagSection: false,
    note: '',
    representative_id: -1,
    sales_rep_id: -1,
    status_id: -1,
    contact_made: 'unselected',
    note_type: 'unselected',
    flag_type: 'unselected',
    is_resolved: 'unselected',
    ratingLoader: false,
    noteLoader: false,
    isEdit: false,
    id: -1,
    flagLoader: false,
    overrideDateModal: false,
    overrideButtonClicked: false,
    overrideDate:
      this.props.reports.community && this.props.reports.community.network_date
        ? moment(this.props.reports.community.network_date)
        : null,
    purchased_upsell_products:
      this.props.reports.community &&
      this.props.reports.community.upsell_products &&
      this.props.reports.community.upsell_products.length > 0
        ? this.props.reports.community.upsell_products
        : [],
    mapFilterLoader: false,
    supportCardOpen:
      this.props.reports.community &&
      this.props.reports.community.community_stage_id &&
      this.props.reports.community.community_stage_id < 4,
    upsellProdcutCardOpen: false,
    mapFilterCardOpen: false,
    leadsPreviewCardOpen: true,
    featureCardOpen: false,
    keyMetricsLoader: true,
    adminTagLoader: true,
    adminTagCardOpen: false,
    manageTagModal: false,
    adminTagsRequested: false,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      community_flags,
      reports: { community },
    } = this.props
    const { keyMetricsLoader } = this.state
    if (nextProps.reports.community) {
      this.setState({
        overrideDate: nextProps.reports.community.network_date
          ? moment(nextProps.reports.community.network_date)
          : null,
        purchased_upsell_products:
          nextProps.reports.community.upsell_products.length > 0
            ? nextProps.reports.community.upsell_products
            : [],
      })
      if (!this.state.adminTagsRequested && community) {
        this.setState({ adminTagsRequested: true }, () => {
          Promise.all([
            this.props.admin_tag_actions.getAllTags(),
            this.props.admin_tag_actions.getCommunityAdminTags(community.id),
          ]).then(() => {
            this.setState({ adminTagLoader: false })
          })
        })
      }
    }
    if (nextProps.key_metrics && keyMetricsLoader) {
      this.setState({ keyMetricsLoader: false })
    }
    if (
      community_flags &&
      community_flags.length === 0 &&
      nextProps.loader &&
      community
    ) {
      this.setState(
        {
          flagLoader: true,
        },
        () => {
          this.fetchCommunityFlags()
        }
      )
    } else if (
      nextProps.reports &&
      community &&
      community.id !== nextProps.reports.community.id
    ) {
      this.setState(
        {
          flagLoader: true,
        },
        () => {
          this.fetchCommunityFlags()
        }
      )
    }
  }

  componentWillUnmount() {
    this.props.communities_actions.clearCommunityKeyMetrics()
  }

  render() { // eslint-disable-line max-lines-per-function, complexity
    const {
      reports: { coaches, community, last_contact_made, last_attempt_made },
      loader,
      representatives,
      representative_action_types,
      community_flags,
      key_metrics,
    } = this.props
    const {
      sectionState,
      showNoteSection,
      showFlagSection,
      noteLoader,
      flagLoader,
      noteSectionLoader,
      isEdit,
      overrideDateModal,
      overrideButtonClicked,
      overrideDate,
      keyMetricsLoader,
      tagManagerModal,
    } = this.state
    const firstCoach = coaches && coaches.length > 0 && coaches[0]

    if (loader) {
      return (
        <div className='col-md-6 mx-auto'>
          <Loader message='loading community report' />
        </div>
      )
    } else {
      return (
        <div id='CommunityRepDetail' className='animated fadeIn'>
          {this.renderFlagIcon()}
          {overrideDateModal && (<div className='grey-out' aria-hidden='true' onClick={this._closeOverrideModal} />)}
          {overrideDateModal && (
            <div className='override-date-modal'>
              <p>{'Override this community\'s network join date'}</p>
              <div className='datepicker-container'>
                <p className='add-date-prompt'>Format: 09/15/1989</p>
                <DatePicker
                  selected={overrideDate}
                  onChange={this._handleOverrideChange}
                />
              </div>
              {community.network_date && (
                <button
                  className='remove-override-button'
                  onClick={this._handleRemoveOverride}
                >
                  {overrideButtonClicked ? (
                    <i className='fa fa-spinner fa-spin' aria-hidden='true' />
                  ) : (
                    'Remove current override'
                  )}
                </button>
              )}
              <div className='button-box'>
                <button
                  className='nevermind'
                  onClick={this._closeOverrideModal}
                >
                  Nevermind
                </button>
                <button
                  className='add-override-button'
                  onClick={this._handleOverrideDate}
                  disabled={overrideButtonClicked}
                >
                  {overrideButtonClicked ? (
                    <i className='fa fa-spinner fa-spin' aria-hidden='true' />
                  ) : (
                    'Save'
                  )}
                </button>
              </div>
            </div>
          )}
          <div className='row'>
            <div className='col-md-3 community-data-container'>
              <div className='d-flex align-items-center'>
                <div
                  className='community-photo'
                  style={{
                    backgroundImage: `url(${
                      community.photo ? community.photo.file_url : Placeholder
                    })`,
                  }}
                />
                <h2>{community.name}</h2>
              </div>
              {this._renderLicenseDetails()}
              {community.address && (
                <p className='community-address mt-2'>{community.address.name}</p>
              )}
              <p className='my-2'>
                Joined the network on{' '}
                {community.network_date
                  ? moment(community.network_date).format('MMM DD, YYYY')
                  : moment(community.created_at).format('MMM DD, YYYY')}{' '}
                (
                {community.network_date
                  ? moment(community.network_date).fromNow()
                  : moment(community.created_at).fromNow()}
                )
              </p>
              <div className='d-flex flex-wrap align-items-center mb-3' style={{ gap: '8px' }}>
                <SubmitButton
                  onClick={this._openOverrideModal}
                  buttonMessage='Override Network Date'
                  buttonColor='navy-button'
                  className='admin-action-button'
                  noTopMargin
                />
                <SubmitButton
                  onClick={() => this._logIntoCommunity(community)}
                  buttonMessage='Login'
                  className='admin-action-button'
                  noTopMargin
                />
              </div>
              {community.representative && (
                <p className='community-representative'>
                  Advisor:{' '}
                  <span>
                    {community.representative.first_name}{' '}
                    {community.representative.last_name}
                  </span>
                </p>
              )}
              {community_flags && community_flags.length > 0 && (
                <div className='flag-text-wrapper'>
                  <p className='community-details-text'>Flags</p>
                  <p className='resolved-flags'>
                    {community_flags.filter((flag) => flag.is_resolved).length}{' '}
                    resolved flags
                  </p>
                  <p className='unresolved-flags'>
                    {community_flags.filter((flag) => !flag.is_resolved).length}{' '}
                    unresolved flags
                  </p>
                </div>
              )}

              <p
                className='community-details-text card-toggler'
                aria-hidden='true'
                onClick={() => this._toggleCard('support')}
              >
                <i
                  className={`card-toggler-icon fa fa-${
                    this.state.supportCardOpen ? 'minus' : 'plus'
                  }`}
                  aria-hidden='true'
                />
                Support / Billing Details
              </p>
              {this.state.supportCardOpen && (
                <CommunityDetailCard
                  community={community}
                  selected_community={this.props.community}
                  flags={community_flags}
                />
              )}
              <p
                className='community-details-text card-toggler'
                aria-hidden='true'
                onClick={() => this._toggleCard('admin-tags')}
              >
                <i
                  className={`card-toggler-icon fa fa-${
                    this.state.adminTagCardOpen ? 'minus' : 'plus'
                  }`}
                  aria-hidden='true'
                />
                Admin Tags
              </p>
              {this.state.adminTagCardOpen && (
                <div className='admin-tag-card mb-2'>
                  {this._renderAdminTagCard()}
                </div>
              )}
              <p
                className='community-details-text card-toggler'
                aria-hidden='true'
                onClick={() => this._toggleCard('upsell')}
              >
                <i
                  className={`card-toggler-icon fa fa-${
                    this.state.upsellProdcutCardOpen ? 'minus' : 'plus'
                  }`}
                  aria-hidden='true'
                />
                Upsell Products
              </p>
              {this.state.upsellProdcutCardOpen && (
                <div className='upsell-products-card'>
                  {this._renderUpsellProducts()}
                </div>
              )}
              <p
                className='community-details-text card-toggler'
                aria-hidden='true'
                onClick={() => this._toggleCard('filters')}
              >
                <i
                  className={`card-toggler-icon fa fa-${
                    this.state.mapFilterCardOpen ? 'minus' : 'plus'
                  }`}
                  aria-hidden='true'
                />
                Map Filters
              </p>
              {this.state.mapFilterCardOpen && (
                <div className='map-filters'>
                  {allFilters.map((filt, index) => {
                    const hasCheck = community.map_filters.includes(filt.value)
                    return (
                      <div className='checkbox-container' key={filt.id}>
                        {index === 0 ||
                        filt.category !== allFilters[index - 1].category ? (
                            <p className='filter-category-text'>
                              {filt.category}
                            </p>
                          ) : null}
                        <div
                          className={`check-box ${hasCheck && 'box-checked'}`}
                          aria-hidden='true'
                          onClick={() => this._handleMapFilterClick(community.id, filt.value)}
                        >
                          {hasCheck && (
                            <i className='fa fa-check' aria-hidden='true' />
                          )}
                        </div>
                        <p className='checkbox-label'>{filt.display}</p>
                      </div>
                    )
                  })}
                </div>
              )}
              <p
                className='community-details-text card-toggler'
                aria-hidden='true'
                onClick={() => this._toggleCard('leads')}
              >
                <i
                  className={`card-toggler-icon fa fa-${
                    this.state.leadsPreviewCardOpen ? 'minus' : 'plus'
                  }`}
                  aria-hidden='true'
                />
                Leads Preview
              </p>
              {this.state.leadsPreviewCardOpen && (
                <div className='lead-preview-card'>
                  {this._renderLeadsPreview()}
                </div>
              )}
              <p
                className='community-details-text card-toggler'
                aria-hidden='true'
                onClick={() => this._toggleCard('features')}
              >
                <i
                  className={`card-toggler-icon fa fa-${
                    this.state.featuresCardOpen ? 'minus' : 'plus'
                  }`}
                  aria-hidden='true'
                />
                Features
              </p>
              {this.state.featuresCardOpen && (
                <div className='features-card'>
                  {community.features.map((feature) =>
                    <FeatureCard key={feature.key} community={community} feature={feature}/>
                  )}
                </div>
              )}
            </div>

            <div className='col-md-6 community-data-container'>
              {showNoteSection && (
                <RepresentativeNotesForm
                  formState={this.state}
                  handleInputChange={this.handleInputChange}
                  representatives={representatives}
                  representativeActionTypes={representative_action_types}
                  createNote={this.createNote}
                  updateNote={this.updateNote}
                  loader={noteLoader}
                  isEdit={isEdit}
                  toggleFormSection={this.toggleFormSection}
                />
              )}

              {showFlagSection && (
                <CommunityFlagsForm
                  formState={this.state}
                  handleInputChange={this.handleInputChange}
                  representatives={representatives}
                  representativeActionTypes={representative_action_types}
                  createFlag={this.createFlag}
                  loader={flagLoader}
                  isEdit={isEdit}
                  toggleFormSection={this.toggleFormSection}
                />
              )}

              <div className='row pt-1'>
                <div className='col-md-3'>
                  <CommunityStatCard
                    name={'Community Activities (Last 30 days)'}
                    number={
                      !firstCoach ? 0 : firstCoach.month_activity_count.value
                    }
                    icon={'globe'}
                  />
                </div>
                <div className='col-md-3'>
                  <CommunityStatCard
                    name={'Active Golfers'}
                    number={
                      !firstCoach ? 0 : firstCoach.active_golfer_count.value
                    }
                    icon={'bolt'}
                  />
                </div>
                <div className='col-md-3'>
                  <CommunityStatCard
                    name={'Total Golfers'}
                    number={!firstCoach ? 0 : firstCoach.golfer_count.value}
                    icon={'users'}
                  />
                </div>
                <div className='col-md-3'>
                  <CommunityStatCard
                    name={'Golfers Created'}
                    number={community.golfers_created}
                    icon={'child'}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-md-3'>
                  <CommunityStatCircle
                    name={'Plays Logged'}
                    number={
                      !firstCoach ? 0 : firstCoach.activity_play_count.value
                    }
                    color={'#33d9b2'}
                  />
                </div>
                <div className='col-md-3'>
                  <CommunityStatCircle
                    name={'Practices Logged'}
                    number={
                      !firstCoach ? 0 : firstCoach.activity_train_count.value
                    }
                    color={'#34ace0'}
                  />
                </div>
                <div className='col-md-3'>
                  <CommunityStatCircle
                    name={'Socials Logged'}
                    number={
                      !firstCoach ? 0 : firstCoach.activity_social_count.value
                    }
                    color={'#6677de'}
                  />
                </div>
                <div className='col-md-3'>
                  <CommunityStatCircle
                    name={'Goals Logged'}
                    number={
                      !firstCoach ? 0 : firstCoach.activity_goal_count.value
                    }
                    color={'#ff6633'}
                  />
                </div>
              </div>
              <div className='section-switcher'>
                <div>
                  <button
                    className={`${
                      sectionState === 'notes' && 'active-section'
                    }`}
                    onClick={() => this.toggleSection('notes')}
                  >
                    <i className='fa fa-clipboard' /> Notes
                  </button>
                  <button
                    className={`${
                      sectionState === 'flags' && 'active-section'
                    }`}
                    onClick={() => this.toggleSection('flags')}
                  >
                    <i className='fa fa-flag' /> Flags
                  </button>
                </div>

                <div>
                  <button
                    className='btn btn-primary show-form-btn'
                    onClick={() =>
                      this.toggleFormSection(
                        sectionState === 'flags' ? 'flags' : 'notes'
                      )
                    }
                  >
                    {sectionState === 'flags' ? 'Create Flag' : 'Log A Note'}
                  </button>
                </div>
              </div>
              <div className='section-container'>
                {noteSectionLoader ? (
                  <Loader message={'loading notes'} />
                ) : (
                  this.renderSection()
                )}
              </div>
            </div>

            <div
              className='col-md-3 pt-2'
              style={{ background: '#37434e', color: '#fff' }}
            >
              {keyMetricsLoader ? (
                <div className='d-flex flex-column align-items-center justify-content-center py4'>
                  <i className='fa fa-spinner fa-spin' aria-hidden='true' />
                  <p>Loading Metrics</p>
                </div>
              ) : (
                <div>
                  <div className='d-flex flex-wrap justify-content-between align-items-center'>
                    <h4>Key Metrics</h4>
                    <div
                      className='status-bubble'
                      style={{
                        background: this._grabStatusBackground(
                          key_metrics.community_status
                        ),
                      }}
                    >
                      <p className='status-text'>
                        {key_metrics.community_status}
                      </p>
                    </div>
                  </div>
                  <div className='last-login-container d-flex align-items-center'>
                    <p
                      className='last-login-number'
                      style={{
                        color: this._grabNumberColor({
                          type: 'lastLogin',
                          value: this._grabDayDiff(key_metrics.last_login_date),
                        }),
                      }}
                    >
                      {this._grabDayDiff(key_metrics.last_login_date)}
                    </p>
                    <p>Days Since Last Coach Login</p>
                  </div>
                  {this._renderMetricTitleBar('Onboarding - Level 1 & 2')}
                  {this._renderMetricRow({
                    left: {
                      number: key_metrics.total_students,
                      color: this._grabNumberColor({
                        type: 'totalStudents',
                        value: key_metrics.total_students,
                      }),
                      title: 'Total Students',
                    },
                    right: {
                      number: key_metrics.lesson_plans,
                      color: this._grabNumberColor({
                        type: 'lessonPlans',
                        value: key_metrics.lesson_plans,
                      }),
                      title: 'Lesson Plans',
                    },
                  })}
                  {this._renderMetricRow({
                    left: {
                      number: key_metrics.total_classes,
                      color: this._grabNumberColor({
                        type: 'totalClasses',
                        value: key_metrics.total_classes,
                      }),
                      title: 'Total Classes',
                    },
                    right: {
                      number: key_metrics.active_class_enrollments,
                      color: this._grabNumberColor({
                        type: 'activeClassEnrollments',
                        value: key_metrics.active_class_enrollments,
                      }),
                      title: 'Active Class Enrollments',
                    },
                  })}
                  {this._renderMetricRow({
                    left: {
                      number: key_metrics.total_events_logged,
                      color: this._grabNumberColor({
                        type: 'totalEventsLogged',
                        value: key_metrics.total_events_logged,
                      }),
                      title: 'Total Events Logged',
                    },
                    right: {
                      number: this._grabDayDiff(key_metrics.last_event_date),
                      color: this._grabNumberColor({
                        type: 'lastEventDate',
                        value: this._grabDayDiff(key_metrics.last_event_date),
                      }),
                      title: 'Days Since Last Event Logged',
                    },
                  })}
                  {this._renderMetricTitleBar('Onboarding - Level 3', true)}
                  {this._renderMetricRow({
                    left: {
                      number: key_metrics.curriculum_objectives_used,
                      color: this._grabNumberColor({
                        type: 'curriculumObjectiveMarkedComplete',
                        value: key_metrics.curriculum_objectives_used,
                      }),
                      title: 'Curriculum Objective Marked Complete',
                    },
                    right: {
                      number: key_metrics.starter_kit_purchased,
                      color: this._grabNumberColor({
                        type: 'starterKitsPurchased',
                        value: key_metrics.starter_kit_purchased,
                      }),
                      title: 'Starter Kits Purchased',
                    },
                  })}
                  {this._renderMetricRow({
                    left: {
                      number: key_metrics.golf_claps,
                      color: this._grabNumberColor({
                        type: 'golfClaps',
                        value: key_metrics.golf_claps,
                      }),
                      title: 'Golf Claps',
                    },
                    right: {
                      number: key_metrics.comments,
                      color: this._grabNumberColor({
                        type: 'comments',
                        value: key_metrics.comments,
                      }),
                      title: 'Comments',
                    },
                  })}
                  {this._renderMetricRow({
                    left: {
                      number: key_metrics.announcements,
                      color: this._grabNumberColor({
                        type: 'announcements',
                        value: key_metrics.announcements,
                      }),
                      title: 'Announcements and Emails',
                    },
                    right: {
                      number: key_metrics.total_leads,
                      color: this._grabNumberColor({
                        type: 'totalLeads',
                        value: key_metrics.total_leads,
                      }),
                      title: ['Total Leads', '(Last 60 Days)'],
                    },
                  })}
                  {this._renderMetricTitleBar('Support Contact', true)}
                </div>
              )}
              {noteSectionLoader ? (
                <div className='d-flex justify-content-center align-items-center'>
                  <i className='fa fa-spinner fa-spin animated fadeIn rating-loader-icon' />
                </div>
              ) : (
                this._renderMetricRow({
                  left: {
                    number: this._grabDayDiff(
                      last_contact_made ? last_contact_made.created_at : null
                    ),
                    color: this._grabNumberColor({
                      type: 'lastContact',
                      value: this._grabDayDiff(
                        last_contact_made ? last_contact_made.created_at : null
                      ),
                    }),
                    title: 'Days Since Last Contact',
                  },
                  right: {
                    number: this._grabDayDiff(
                      last_attempt_made ? last_attempt_made.created_at : null
                    ),
                    color: this._grabNumberColor({
                      type: 'lastAttempt',
                      value: this._grabDayDiff(
                        last_attempt_made ? last_attempt_made.created_at : null
                      ),
                    }),
                    title: 'Days Since Last Attempt',
                  },
                })
              )}
              {this._renderMetricTitleBar('Coach Details', true)}
              <div className='coach-section'>{this.renderCoaches()}</div>
            </div>

          </div>
          {tagManagerModal && (
            <BaseModal
              close={this._closeAdminTagModal}
              back={false}
              width={'50vw'}
            >
              <AdminTagIndex />
            </BaseModal>
          )}
        </div>
      )
    }
  }

  _closeAdminTagModal = () => {
    this.setState({ tagManagerModal: false })
  }

  // eslint-disable-next-line complexity
  _grabNumberColor = ({ type, value }) => {
    const green = '#33d9b2'
    const yellow = '#fcda35'
    const red = '#eb2640'
    switch (type) {
      case 'lastLogin':
        if (value === '---') { return '#bbb' }
        if (value < 31) { return green }
        if (value < 61) { return yellow }
        return red
      case 'totalStudents':
        if (value < 12) { return red }
        if (value < 36) { return yellow }
        return green
      case 'lessonPlans':
        if (value < 1) { return red }
        return green
      case 'totalClasses':
        if (value < 1) { return red }
        if (value < 4) { return yellow }
        return green
      case 'activeClassEnrollments':
        if (value < 12) { return red }
        if (value < 36) { return yellow }
        return green
      case 'totalEventsLogged':
        if (value < 1) { return red }
        if (value < 5) { return yellow }
        return green
      case 'lastEventDate':
        if (value === 'N/A') { return '#bbb' }
        if (value === '---') { return '#bbb' }
        if (value < 31) { return green }
        if (value < 61) { return yellow }
        return red
      case 'curriculumObjectiveMarkedComplete':
        if (value === 'YES') { return green }
        return red
      case 'starterKitsPurchased':
        if (value === 'YES') { return green }
        return red
      case 'golfClaps':
        if (value < 51) { return red }
        if (value < 101) { return yellow }
        return green
      case 'comments':
        if (value < 51) { return red }
        if (value < 101) { return yellow }
        return green
      case 'announcements':
        if (value < 1) { return red }
        if (value < 13) { return yellow }
        return green
      case 'totalLeads':
        if (value < 1) { return red }
        if (value < 13) { return yellow }
        return green
      case 'lastContact':
        if (value === '---') { return '#bbb' }
        if (value < 31) { return green }
        if (value < 91) { return yellow }
        return red
      case 'lastAttempt':
        if (value === '---') { return '#bbb' }
        if (value < 31) { return green }
        if (value < 91) { return yellow }
        return red
      default:
        return '#bbb'
    }
  }

  _renderMetricRow = ({ left, right }) => (
    <div className='metric-row d-flex justify-content-around'>
      {this._renderMetricBox(left)}
      {this._renderMetricBox(right)}
    </div>
  )

  _renderMetricBox = ({ number, color, title }) => (
    <div className='metric-box d-flex flex-column align-items-center'>
      <p className='metric-box-number' style={{ color }}>
        {number}
      </p>
      {Array.isArray(title) ? (
        title.map((t, i) => <p key={i}>{t}</p>)
      ) : (
        <p>{title}</p>
      )}
    </div>
  )

  _renderMetricTitleBar = (title, marginTop) => (
    <div
      className='title-bar d-flex align-items-center flex-nowrap'
      style={marginTop ? { marginTop: 9 } : {}}
    >
      <div className='title-line' />
      <p>{title}</p>
      <div className='title-line' />
    </div>
  )

  _grabDayDiff = (date) => {
    if (date === 'N/A' || !date) {
      return '---'
    }
    const now = moment()
    const past = moment(date)
    return now.diff(past, 'days')
  }

  _grabStatusBackground = (status) => {
    switch (status) {
      case 'ACTIVE':
        return '#33d9b2'
      case 'ONBOARDING':
        return '#ff6633'
      default:
        return '#BBB'
    }
  }

  _logIntoCommunity = (community) => {
    this.props.auth_actions.signInSuperUser(community).then(() => {
      this.props.navigate('/dashboard')
    })
  }

  _renderLicenseDetails = () => {
    const { license_type, application_fee_type, application_fee, currency } = this.props.reports.community
    return (
      <div className='d-flex flex-wrap align-items-center mt-2'>
        <div
          className='license-banner'
          style={{ backgroundColor: licenseColor(license_type) }}
        >
          <p className='license-text'>
            {`${licenseLabel(license_type)} License`}
          </p>
        </div>

        {checkLicensePermission(license_type, ['revenue_share']) &&
          <p className='application-fee'>
            App Fee {application_fee_type === 'fixed' ? displayWithCurrency(application_fee, currency, true) : `${application_fee}%`}
          </p>
        }
      </div>
    )
  }

  _toggleCard = (name) => {
    switch (name) {
      case 'support':
        this.setState({ supportCardOpen: !this.state.supportCardOpen })
        break
      case 'upsell':
        this.setState({
          upsellProdcutCardOpen: !this.state.upsellProdcutCardOpen,
        })
        break
      case 'filters':
        this.setState({ mapFilterCardOpen: !this.state.mapFilterCardOpen })
        break
      case 'leads':
        this.setState({
          leadsPreviewCardOpen: !this.state.leadsPreviewCardOpen,
        })
        break
      case 'admin-tags':
        this.setState({ adminTagCardOpen: !this.state.adminTagCardOpen })
        break
      case 'features':
        this.setState((prevState) => ({ featuresCardOpen: !prevState.featuresCardOpen }))
        break
      default:
        return false
    }
  }

  _handleMapFilterClick = (community_id, filter) => {
    this.setState({ mapFilterLoader: true }, () => {
      this.props.communities_actions
        .handleCommunityMapFilter(community_id, filter)
        .then(() => this._fetchCommunityReport())
    })
  }

  _renderLeadsPreview = () => {
    const {
      reports: { lead_counts },
    } = this.props
    const types = Object.entries(lead_counts)
    return (
      <table>
        <tbody>
          {types.map((type, index) => (
            <tr key={index}>
              <td>{type[0].replace('_', ' ')}: </td>
              <td className='count-pill-container'>
                <p style={{ background: this._getPillColor(type[0]) }}>
                  {type[1]}
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  _getPillColor = (status) => {
    if (status.indexOf('attempt') > -1) {
      status = 'attempt'
    }
    switch (status) {
      case 'new':
        return '#ff6633' // orange
      case 'attempt':
        return '#fdda12' // gold
      case 'contacted':
        return '#33d9b2' // green
      case 'invited':
        return '#6677de' // purple..... ish
      case 'not_interested':
        return '#ec8391' // light red
      case 'inaccurate_info':
        return '#dc3446' // darker red
      case 'added':
        return '#2b3843' // dark slate grey
      default:
        return '#fff'
    }
  }

  _renderUpsellProducts = () => {
    const {
      reports: { community },
    } = this.props
    const { purchased_upsell_products, productLoader } = this.state

    const productsIds = purchased_upsell_products.map((product) => product.id)

    if (
      community.all_upsell_products &&
      community.all_upsell_products.length > 0
    ) {
      return community.all_upsell_products.map((product) => {
        const index = productsIds.indexOf(product.id)

        return (
          <div key={product.id}>
            <input
              type='checkbox'
              name={product.name}
              value={product.id}
              onChange={this._handleProductCheck}
              defaultChecked={index > -1}
              disabled={productLoader}
            />{' '}
            {product.name}
            <br />
          </div>
        )
      })
    }
  }

  _renderAdminTagCard = () => {
    const {
      reports: { community },
      adminTags,
    } = this.props
    const { adminTagLoader } = this.state
    if (adminTagLoader) { return <i className='fa fa-spinner fa-spin' aria-hidden='true' /> }
    const categories = adminTags.reduce((acc, cur) => {
      if (!acc.includes(cur.category)) {
        acc.push(cur.category)
      }
      return acc
    }, [])
    return (
      <div className='d-flex flex-column'>
        <p
          style={{
            marginLeft: 'auto',
            marginBottom: 4,
            color: 'lightblue',
            cursor: 'pointer',
            fontSize: '0.85rem',
          }}
          aria-hidden='true'
          onClick={() => this.setState({ tagManagerModal: true })}
        >
          -Manage Tags-
        </p>
        <div
          style={{ border: '1px solid grey', borderRadius: 4, marginBottom: 6 }}
        >
          <select
            style={{ width: '100%' }}
            onChange={this._handleAdminTagChange}
            value='-1'
          >
            <option disabled value='-1'>
              Add Admin Tag
            </option>
            {categories.map((c, i) => (
              <optgroup key={i} label={c}>
                {adminTags
                  .filter((t) => t.category === c)
                  .map((t) => (
                    <option key={`inner-${t.id}`} value={t.id}>
                      {t.name}
                    </option>
                  ))}
              </optgroup>
            ))}
          </select>
        </div>
        <div className='d-flex flex-column p-1'>
          {this.props.communityAdminTags.map((t) => (
            <div
              key={t.id}
              className='p-2 d-flex justify-content-between align-items-center'
              style={{
                border: '1px solid orange',
                borderRadius: 8,
                marginBottom: 4,
                // marginRight: 6,
              }}
            >
              <div className='d-flex flex-column'>
                <p
                  style={{ margin: 0, fontSize: '0.85rem', color: '#2b3843' }}
                >
                  {t.name}
                </p>
                <p style={{ color: 'grey', fontSize: '0.75rem', margin: 0 }}>
                  {t.category}
                </p>
              </div>
              <i
                className='fa fa-times'
                aria-hidden='true'
                style={{ cursor: 'pointer', marginLeft: 6 }}
                onClick={() => this._handleRemoveAdminTag(community.id, t.id)}
              />
            </div>
          ))}
        </div>
      </div>
    )
  }

  _handleAdminTagChange = (e) => {
    const {
      reports: { community },
    } = this.props
    const newTagId = e.target.value
    if (
      this.props.communityAdminTags.find((t) => t.id === parseInt(newTagId))
    ) {
      return false
    }
    this.props.admin_tag_actions.addCommunityAdminTag(community.id, newTagId)
  }

  _handleRemoveAdminTag = (community_id, admin_tag_id) => {
    this.props.admin_tag_actions.removeCommunityAdminTag(
      community_id,
      admin_tag_id
    )
  }

  _handleProductCheck = (event) => {
    const {
      communities_actions,
      reports: { community },
    } = this.props
    const isChecked = event.target.checked
    const purchased_upsell_products = [...this.state.purchased_upsell_products]
    const parsedValue = parseInt(event.target.value, 10)
    const index = purchased_upsell_products
      .map((step) => {
        const key = _.keys(step)
        return parseInt(key[0], 10)
      })
      .indexOf(parsedValue)

    if (isChecked) {
      this.setState(
        {
          onboardingLoader: true,
        },
        () => {
          purchased_upsell_products.push(parsedValue)
          communities_actions
            .completeUpsellPurchase(community.id, parsedValue)
            .then(() => {
              this._fetchCommunityReport()
            })
        }
      )
    } else if (!isChecked) {
      this.setState(
        {
          onboardingLoader: true,
        },
        () => {
          purchased_upsell_products.splice(index, 1)
          communities_actions
            .incompleteUpsellPurchase(community.id, parsedValue)
            .then(() => {
              this._fetchCommunityReport()
            })
        }
      )
    }

    this.setState({
      purchased_upsell_products,
    })
  }

  _handleOverrideDate = () => {
    if (this.state.overrideDate) {
      this.setState({ overrideButtonClicked: true }, () => {
        const {
          reports: { community },
          reports_actions,
        } = this.props

        const data = {
          id: community.id,
          network_date: this.state.overrideDate,
        }

        this.props.communities_actions.updateCommunity(data).then(() => {
          reports_actions
            .getCommunityEngagementReport(community.id)
            .then(() => {
              this._closeOverrideModal()
            })
        })
      })
    }
  }

  _handleRemoveOverride = () => {
    this.setState({ overrideButtonClicked: true }, () => {
      const {
        reports: { community },
        reports_actions,
      } = this.props

      const data = {
        id: community.id,
        network_date: 'clear',
      }

      this.props.communities_actions.updateCommunity(data).then(() => {
        reports_actions.getCommunityEngagementReport(community.id).then(() => {
          this._closeOverrideModal()
        })
      })
    })
  }

  _handleOverrideChange = (date) => {
    this.setState({ overrideDate: date })
  }

  _closeOverrideModal = () => {
    this.setState({
      overrideDateModal: false,
      overrideButtonClicked: false,
      overrideDate:
        this.props.reports.community &&
        this.props.reports.community.network_date
          ? moment(this.props.reports.community.network_date)
          : null,
    })
  }

  _openOverrideModal = () => {
    this.setState({ overrideDateModal: true })
  }

  _fetchCommunityReport = () => {
    const {
      reports: { community },
      reports_actions,
    } = this.props

    reports_actions.getCommunityEngagementReport(community.id).then(() => {
      this.setState({
        onboardingLoader: false,
        mapFilterLoader: false,
      })
    })
  }

  renderFlagIcon = () => {
    const { community_flags } = this.props

    if (community_flags && community_flags.length > 0) {
      const unresolvedCount = community_flags.filter(
        (flag) => !flag.is_resolved
      ).length

      if (unresolvedCount > 0) {
        return <i className='fa fa-flag community-flag-icon' />
      }
      return null
    }
    return null
  }

  renderSection = () => {
    const { representative_notes } = this.props
    const { sectionState, flagLoader } = this.state
    const { community_flags } = this.props

    switch (sectionState) {
      case 'notes':
        return (
          <CommunityNotes
            toggleFormSection={this.toggleFormSection}
            notes={representative_notes}
            deleteNote={this.deleteNote}
            toggleEditNote={this.toggleEditNote}
          />
        )
      case 'flags':
        return (
          <CommunityFlags
            flags={community_flags}
            loader={flagLoader}
            toggleFormSection={this.toggleFormSection}
            deleteFlag={this.deleteFlag}
            toggleEditFlag={this.toggleEditFlag}
          />
        )
      default:
        return null
    }
  }

  setSupportRating = (support_rating) => {
    this.setState({ support_rating, ratingLoader: true }, () => {
      this.setState({ ratingLoader: false })
    })
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  fetchCommunityFlags = () => {
    const {
      reports: { community },
      community_flags_actions,
    } = this.props

    community_flags_actions.getCommunityFlags(community.id).then(() => {
      this.setState(
        {
          flagLoader: false,
          showFlagSection: false,
        },
        () => {
          this.resetForm()
        }
      )
    })
  }

  toggleSection = (sectionState) => {
    this.setState({ sectionState })
  }

  toggleFormSection = (type) => {
    if (type === 'notes') {
      this.setState({
        showNoteSection: !this.state.showNoteSection,
        showFlagSection: false,
      })
    } else if (type === 'flags') {
      this.setState({
        showFlagSection: !this.state.showFlagSection,
        showNoteSection: false,
      })
    }
  }

  changeRating = (newRating, _) => {
    this.setState(
      {
        support_rating: newRating,
      },
      () => {
        this.updateCommunityRating()
      }
    )
  }

  resetForm = () => {
    this.setState({
      showNoteSection: false,
      note: '',
      representative_id: -1,
      sales_rep_id: -1,
      status_id: -1,
      contact_made: 'unselected',
      noteLoader: false,
      isEdit: false,
      id: -1,
      flag_type: 'unselected',
      is_resolved: 'unselected',
    })
  }

  deleteNote = (id) => {
    const {
      reports: { community },
      representative_notes_actions,
    } = this.props

    this.setState(
      {
        noteLoader: true,
        noteSectionLoader: true,
      },
      () => {
        representative_notes_actions
          .deleteRepresentativeNote(id)
          .then(() => {
            this.resetForm()
            toast.success('Success. Deleted Representative Note!', {
              position: toast.POSITION.BOTTOM_LEFT,
            })
          })
          .then(() => {
            representative_notes_actions
              .getRepresentativeNotes(community.id)
              .then(() => {
                this.setState({ noteSectionLoader: false })
              })
          })
      }
    )
  }

  toggleEditNote = (repNote) => {
    const {
      note,
      representative_id,
      representative_action_type_id,
      contact_made,
      note_type,
      id,
    } = repNote

    this.setState({
      showNoteSection: true,
      showFlagSection: false,
      note,
      representative_id,
      status_id: representative_action_type_id,
      contact_made,
      note_type,
      isEdit: true,
      id,
    })
  }

  toggleEditFlag = (flag) => {
    const { note, representative_id, flag_type, is_resolved, id } = flag

    this.setState({
      showFlagSection: true,
      showNoteSection: false,
      note,
      representative_id,
      flag_type,
      is_resolved,
      isEdit: true,
      id,
    })
  }

  updateNote = () => {
    const {
      reports: { community },
      representative_notes_actions,
      reports_actions,
    } = this.props
    const { note, representative_id, contact_made, status_id, id, note_type } =
      this.state

    const repNote = {
      id,
      note,
      representative_id,
      community_id: community.id,
      contact_made,
      representative_action_type_id: status_id,
      note_type,
    }

    this.setState(
      {
        noteLoader: true,
        noteSectionLoader: true,
      },
      () => {
        representative_notes_actions
          .updateRepresentativeNote(repNote)
          .then(() => {
            this.resetForm()
            toast.success('Success. Updated Representative Note!', {
              position: toast.POSITION.BOTTOM_LEFT,
            })
          })
          .then(() => {
            reports_actions
              .getCommunityEngagementReport(community.id)
              .then(() => {
                representative_notes_actions
                  .getRepresentativeNotes(community.id)
                  .then(() => {
                    this.setState({ noteSectionLoader: false })
                  })
              })
          })
      }
    )
  }

  createNote = () => {
    const {
      reports: { community },
      representative_notes_actions,
      reports_actions,
    } = this.props
    const { note, representative_id, contact_made, status_id, note_type } =
      this.state

    const repNote = {
      note,
      representative_id,
      community_id: community.id,
      contact_made,
      representative_action_type_id: status_id,
      note_type,
    }

    this.setState(
      {
        noteLoader: true,
        noteSectionLoader: true,
      },
      () => {
        representative_notes_actions
          .createRepresentativeNote(repNote)
          .then(() => {
            this.resetForm()
            toast.success('Success. Created Representative Note!', {
              position: toast.POSITION.BOTTOM_LEFT,
            })
          })
          .then(() => {
            reports_actions
              .getCommunityEngagementReport(community.id)
              .then(() => {
                representative_notes_actions
                  .getRepresentativeNotes(community.id)
                  .then(() => {
                    this.setState({ noteSectionLoader: false })
                  })
              })
          })
      }
    )
  }

  deleteFlag = (id) => {
    const { community_flags_actions } = this.props

    this.setState(
      {
        flagLoader: true,
      },
      () => {
        community_flags_actions.deleteCommunityFlag(id).then(() => {
          this.fetchCommunityFlags()
          toast.success('Success. Deleted Community Flag!', {
            position: toast.POSITION.BOTTOM_LEFT,
          })
        })
      }
    )
  }

  createFlag = (type) => {
    // Holds both the update and create actions for community flags
    const {
      reports: { community },
      community_flags_actions,
    } = this.props
    const { note, representative_id, is_resolved, flag_type, id } = this.state

    const communityFlag = {
      note,
      representative_id,
      community_id: community.id,
      is_resolved,
      flag_type,
    }

    if (type === 'update') {
      communityFlag.id = id
    }

    this.setState(
      {
        flagLoader: true,
      },
      () => {
        if (type === 'create') {
          community_flags_actions
            .createCommunityFlag(communityFlag)
            .then(() => {
              this.fetchCommunityFlags()
              toast.success('Success. Created Community Flag!', {
                position: toast.POSITION.BOTTOM_LEFT,
              })
            })
        } else if (type === 'update') {
          community_flags_actions
            .updateCommunityFlag(communityFlag)
            .then(() => {
              this.fetchCommunityFlags()
              toast.success('Success. Updated Community Flag!', {
                position: toast.POSITION.BOTTOM_LEFT,
              })
            })
        }
      }
    )
  }

  updateCommunityRating = () => {
    const {
      reports: { community },
      reports_actions,
    } = this.props
    const { support_rating } = this.state

    const communityRating = {
      id: community.id,
      support_rating,
    }
    this.setState({ ratingLoader: true })
    this.props.communities_actions.updateCommunity(communityRating).then(() => {
      reports_actions.getCommunityEngagementReport(community.id).then(() => {
        this.setSupportRating(support_rating)
      })
      toast.success('Success. Updated Community Rating!', {
        position: toast.POSITION.BOTTOM_LEFT,
      })
    })
  }

  renderCoaches = () => {
    const {
      reports: { coaches },
    } = this.props

    return coaches.map((coach, index) => (
      <CoachRow key={index} coach={coach} makeLeadCoach={this._makeLeadCoach} />
    ))
  }

  _makeLeadCoach = (id) => {
    requestMakeLeadCoach(id)
      .then(() => this._fetchCommunityReport())
      .catch(() => toast.error('Something went wrong. Please try again.', { position: toast.POSITION.TOP_RIGHT }))
  }

  renderStarColor = () => {
    const {
      reports: { community },
    } = this.props
    const { support_rating } = this.state

    const rating =
      community.support_rating && support_rating === 0
        ? community.support_rating
        : support_rating

    switch (rating) {
      case 0:
        return '#ed213a'
      case 1:
        return '#ed213a'
      case 2:
        return '#ed213a'
      case 3:
        return '#fddb10'
      case 4:
        return '#fddb10'
      case 5:
        return '#33d9b2'
      default:
        return '#ff6633'
    }
  }
}

CommunityRepDetail.propTypes = {
  community: PropTypes.object,
  reports: PropTypes.shape({
    lead_counts: PropTypes.object,
    coaches: PropTypes.array,
    community: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      network_date: PropTypes.string,
      license_type: PropTypes.string,
      application_fee_type: PropTypes.string,
      application_fee: PropTypes.number,
      currency: PropTypes.object,
      all_upsell_products: PropTypes.array,
      support_rating: PropTypes.number,
      upsell_products: PropTypes.array,
      community_stage_id: PropTypes.number,
      created_at: PropTypes.string,
      map_filters: PropTypes.array,
      features: PropTypes.array,
      golfers_created: PropTypes.number,
      photo: PropTypes.shape({
        file_url: PropTypes.string,
      }),
      address: PropTypes.shape({
        name: PropTypes.string,
      }),
      representative: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
      }),
    }),
    last_attempt_made: PropTypes.shape({
      created_at: PropTypes.string,
    }),
    last_contact_made: PropTypes.shape({
      created_at: PropTypes.string,
    }),
  }),
  key_metrics: PropTypes.shape({
    community_status: PropTypes.string,
    last_login_date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    last_event_date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    total_students: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    total_classes: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    active_class_enrollments: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    total_events_logged: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lesson_plans: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    curriculum_objectives_used: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    starter_kit_purchased: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    golf_claps: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    comments: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    announcements: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    total_leads: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  loader: PropTypes.bool,
  community_flags: PropTypes.array,
  representative_notes: PropTypes.array,
  adminTags: PropTypes.array,
  communityAdminTags: PropTypes.array,
  representatives: PropTypes.array,
  representative_action_types: PropTypes.array,
  navigate: PropTypes.func,
  communities_actions: PropTypes.shape({
    getCommunityAdminTags: PropTypes.func,
    getAllTags: PropTypes.func,
    clearCommunityKeyMetrics: PropTypes.func,
    signInSuperUser: PropTypes.func,
    handleCommunityMapFilter: PropTypes.func,
    completeUpsellPurchase: PropTypes.func,
    incompleteUpsellPurchase: PropTypes.func,
    addCommunityAdminTag: PropTypes.func,
    removeCommunityAdminTag: PropTypes.func,
    updateCommunity: PropTypes.func,
  }),
  reports_actions: PropTypes.shape({
    getCommunityEngagementReport: PropTypes.func,
  }),
  community_flags_actions: PropTypes.shape({
    getCommunityFlags: PropTypes.func,
    createCommunityFlag: PropTypes.func,
    updateCommunityFlag: PropTypes.func,
    deleteCommunityFlag: PropTypes.func,
  }),
  representative_notes_actions: PropTypes.shape({
    getRepresentativeNotes: PropTypes.func,
    createRepresentativeNote: PropTypes.func,
    updateRepresentativeNote: PropTypes.func,
    deleteRepresentativeNote: PropTypes.func,
  }),
  admin_tag_actions: PropTypes.shape({
    getCommunityAdminTags: PropTypes.func,
    getAllTags: PropTypes.func,
    addCommunityAdminTag: PropTypes.func,
    removeCommunityAdminTag: PropTypes.func,
  }),
  auth_actions: PropTypes.shape({
    signInSuperUser: PropTypes.func,
  }),
  coach_onboarding_actions: PropTypes.shape({
    getCoachOnboardingSteps: PropTypes.func,
    updateOnboardingStatus: PropTypes.func,
  }),
  coachOnboardingSteps: PropTypes.array,
}

function mapStateToProps(state, _) {
  return {
    ui: state.ui,
    communities: state.communities.communities,
    community: state.community,
    community_flags: state.communityFlags,
    representatives: state.representatives,
    representative_action_types: state.representativeActionTypes,
    representative_notes: state.representativeNotes,
    key_metrics: state.keyMetrics,
    adminTags: state.adminTags.admin_tags,
    communityAdminTags: state.adminTags.community_admin_tags,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    communities_actions: bindActionCreators(communitiesActions, dispatch),
    community_flags_actions: bindActionCreators(
      communityFlagsActions,
      dispatch
    ),
    representatives_actions: bindActionCreators(
      representativesActions,
      dispatch
    ),
    representative_notes_actions: bindActionCreators(
      representativeNotesActions,
      dispatch
    ),
    reports_actions: bindActionCreators(reportsActions, dispatch),
    auth_actions: bindActionCreators(authActions, dispatch),
    admin_tag_actions: bindActionCreators(adminTagActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouting(CommunityRepDetail))
