import Axios from '../axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { deserialize, serialize } from '../utility/serialization'
import { handleAPIError } from '../utility/actionHelper'

// THUNKS

export const updateEnrollment = createAsyncThunk(
  'enrollment/updateEnrollment',
  async ({ enrollmentData, coachMessage }, { rejectWithValue }) => {
    try {
      const { data } = await requestUpdateEnrollment(enrollmentData, coachMessage)

      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const deleteEnrollment = createAsyncThunk(
  'enrollment/deleteEnrollment',
  async ({ enrollmentId, refund, coachMessage, notifyViaEmail }, { rejectWithValue }) => {
    try {
      const { data } = await requestDeleteEnrollment(enrollmentId, refund, coachMessage)

      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue, notifyViaEmail)
    }
  }
)

export const addEnrollmentManually = createAsyncThunk(
  'enrollment/addEnrollmentManually',
  async (enrollmentData, { rejectWithValue }) => {
    try {
      const { data } = await requestPostEnrollment(enrollmentData)

      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, (err) => {
        const rejected = rejectWithValue(err)
        rejected.payload.userId = enrollmentData.userId

        return rejected
      })
    }
  }
)

// API CALLS

const requestPostEnrollment = (enrollmentData) => {
  const requestUrl = '/api/v1/enrollments'

  return Axios.post(requestUrl, { ...serialize(enrollmentData) })
}

function requestUpdateEnrollment(enrollment, coachMessage = '') {
  const requestUrl = `/api/v1/enrollments/${enrollment.id}`

  return Axios.patch(requestUrl, { ...serialize(enrollment), coach_message: coachMessage })
}

function requestDeleteEnrollment(enrollmentId, refund, coachMessage = '', notifyViaEmail = false) {
  const requestUrl = `/api/v1/enrollments/${enrollmentId}`

  return Axios.delete(requestUrl, { params: { refund, coach_message: coachMessage, send_email: notifyViaEmail } })
}
