/* eslint-disable react/prop-types */
import moment from 'moment-timezone'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import * as scheduleSessionActions from '../../actions/scheduleSessionActions'
import { getMomentInCommunityTimezone } from '../../utility/datesHelper'
import ScheduleSessionForm from './ScheduleSessionForm'

const ScheduleSessionFormContainer = ({ selectedScheduleSession, handleCloseForm, timezone }) => {
  const dispatch = useDispatch()
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const submitScheduleSession = (e, scheduleSession) => {
    e.preventDefault()
    setLoading(true)
    dispatch(scheduleSessionActions.updateScheduleSession(scheduleSession)).unwrap()
      .then(() => {
        toast.success('Session updated successfully.', { position: toast.POSITION.TOP_RIGHT })
        setShowConfirmationModal(false)
        setErrors({})
        handleCloseForm()
      })
      .catch((err) => setErrors(err))
      .finally(() => setLoading(false))
  }

  const closeScheduleSessionModal = (scheduleSession) => {
    if (
      (scheduleSession?.title || '') !== (selectedScheduleSession?.title || '') ||
      (scheduleSession?.location || '') !== (selectedScheduleSession?.location || '') ||
      (scheduleSession?.description || '') !== (selectedScheduleSession?.description || '') ||
      (scheduleSession?.startDate?.toString() || '') !== (selectedScheduleSession?.startDate?.toString() || '') ||
      (scheduleSession?.endDate?.toString() || '') !== (selectedScheduleSession?.endDate?.toString() || '')
    ) {
      setShowConfirmationModal(true)
    } else {
      handleCloseForm()
    }
  }

  const handleSubmit = (e, scheduleSession) => {
    const errors = checkValidation(scheduleSession)

    if (Object.keys(errors).length !== 0) {
      setErrors(errors)
      return
    }
    const startDateTimezone = getMomentInCommunityTimezone(scheduleSession.startDate, timezone, 'YYYY-MM-DD HH:mm:ss')
      .toISOString()
    const endDateTimezone = getMomentInCommunityTimezone(scheduleSession.endDate, timezone, 'YYYY-MM-DD HH:mm:ss')
      .toISOString()
    const scheduleSessionTimezone = { ...scheduleSession, startDate: moment(startDateTimezone), endDate: moment(endDateTimezone) }

    submitScheduleSession(e, scheduleSessionTimezone)
  }

  const handleDelete = (scheduleSession) => {
    setLoading(true)
    dispatch(scheduleSessionActions.deleteScheduleSession(scheduleSession.id)).unwrap()
      .then(() => toast.success('Session deleted successfully.', { position: toast.POSITION.TOP_RIGHT }))
      .catch(() => toast.error('Something went wrong. Please try again.', { position: toast.POSITION.TOP_RIGHT }))
      .finally(() => setLoading(false))
    setShowDeleteConfirmation(false)
  }

  const checkValidation = (scheduleSession) => {
    const err = {}
    if (!scheduleSession.startDate) {
      err.startDate = ['Select a date']
    }

    if (!scheduleSession.startDate || !scheduleSession.endDate) {
      err.endDate = ['Select start and end time']
    } else if (scheduleSession.startDate > scheduleSession.endDate) {
      err.endDate = ['Start time must be before or the same as the end time']
    }
    return err
  }

  return (
    <ScheduleSessionForm
      selectedScheduleSession={selectedScheduleSession}
      showConfirmationModal={showConfirmationModal}
      setShowConfirmationModal={setShowConfirmationModal}
      showDeleteConfirmation={showDeleteConfirmation}
      setShowDeleteConfirmation={setShowDeleteConfirmation}
      closeScheduleSessionModal={closeScheduleSessionModal}
      handleCloseForm={handleCloseForm}
      handleSubmit={handleSubmit}
      handleDelete={handleDelete}
      errors={errors}
      loading={loading}
      timezone={timezone}
    />
  )
}

export default ScheduleSessionFormContainer
