import { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { ReactComponent as DetailsIcon } from '../../../assets/images/common/icons/programBuilder/details-icon.svg'
import { ReactComponent as ScheduleIcon } from '../../../assets/images/common/icons/programBuilder/schedule-icon.svg'
import { ReactComponent as MarketingIcon } from '../../../assets/images/common/icons/programBuilder/marketing-icon.svg'
import { ReactComponent as PricingIcon } from '../../../assets/images/common/icons/programBuilder/pricing-icon.svg'

import * as commonActions from '../../../actions/commonActions'
import { getEditProgram, createProgram, updateProgram, getCoachPrograms } from '../../../actions/programActions'
import { getProgramSchedule, createProgramSchedule, updateProgramSchedule } from '../../../actions/scheduleActions'
import { getLessonPlans } from '../../../actions/lessonPlanActions'
import { getCommunityCategories } from '../../../actions/programCategoryActions.js'
import { getSearchTags } from '../../../actions/searchTagActions'
import { getCommunityPackages } from '../../../actions/packagesActions'
import { getCommunityMaterials } from '../../../actions/materialsActions'
import { getCommunityCoaches } from '../../../actions/coachesActions'
import { selectInitialFilters, perPage } from '../../../reducers/groupProgramsReducer'

import * as Constants from '../../../misc/Constants.js'
import { checkLicensePermission } from '../../../utility/communityLicense'
import { isFeatureEnabled } from '../../../utility/featureEnablement'
import { resetBuilder, setField, programFormData, scheduleDates } from '../../../reducers/programBuilderReducer'

import BuilderHeader from './BuilderHeader'
import FlowNavigation from '../../common/FlowNavigation/FlowNavigation'
import Details from './Details/Details'
import Schedule from './Schedule/Schedule'
import Marketing from './Marketing/Marketing'
import Pricing from './Pricing/Pricing'
import BuilderFooter from './BuilderFooter'
import Loader from '../../common/Op36Loader-web'
import SaveModal from './Modals/SaveModal'
import './ProgramBuilder.scss'

const customErrorMessages = {
  name: 'Please enter the name.',
  subtitle: 'Please enter the subtitle.',
  startDate: 'Please select a start date.',
  endDate: 'Please select an end date.',
  spotsAvailable: 'Please enter the number of spots available or disable the program capacity.',
  registrationStartsAt: 'Please select an open date or disable the registration dates.',
  registrationEndsAt: 'Please select a close date or disable the registration dates.',
  scheduleSessions: 'Please add a date to all your copied schedule sessions',
  packages: 'Please attach at least one package.',
  paymentMethod: 'Please select at least one payment method.',
}

// eslint-disable-next-line complexity, max-lines-per-function
const ProgramBuilder = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const [searchParams] = useSearchParams()

  const user = useSelector(state => state.user.current_user)
  const licenseType = user.communities[0].license_type

  const { id, step, isDraft, errors } = useSelector(state => state.programBuilder)
  const { scheduleStartDate, scheduleEndDate } = useSelector(scheduleDates)
  const initialFilters = useSelector(selectInitialFilters)
  const programFields = useSelector(state => state.programBuilder)
  const formData = useSelector(programFormData)

  const [loader, setLoader] = useState(false)
  const [showSaveModal, setShowSaveModal] = useState(false)
  const [courseLoading, setCourseLoading] = useState(!!params.id)
  const [lessonPlansLoading, setLessonPlansLoading] = useState(true)
  const [communityCategoriesLoading, setCommunityCategoriesLoading] = useState(true)
  const [searchTagsLoading, setSearchTagsLoading] = useState(true)
  const [packagesLoading, setPackagesLoading] = useState(true)
  const isMaterialsFeatureEnabled = isFeatureEnabled(user.communities[0], 'materials')
  const [materialsLoading, setMaterialsLoading] = useState(isMaterialsFeatureEnabled)
  const [coachesLoading, setCoachesLoading] = useState(true)
  const dataLoaded = courseLoading || lessonPlansLoading || communityCategoriesLoading ||
                     searchTagsLoading || packagesLoading || materialsLoading || coachesLoading

  useLayoutEffect(() => {
    dispatch(commonActions.showSideBar(false))
    dispatch(commonActions.showNavBar(false))

    return () => {
      dispatch(commonActions.showSideBar(true))
      dispatch(commonActions.showNavBar(true))
    }
  }, [dispatch])

  useEffect(() => () => dispatch(resetBuilder()), [dispatch])

  useEffect(() => {
    const paramsStep = searchParams.get('step')
    if (paramsStep && ['1', '2', '3', '4'].includes(paramsStep)) {
      dispatch(setField({ field: 'step', value: parseInt(paramsStep) }))
    }
  }, [dispatch, searchParams])

  useEffect(() => {
    if (params.id) {
      dispatch(getEditProgram(params.id)).unwrap().then((response) => {
        dispatch(getProgramSchedule(response.course.id)).unwrap().then(() => setCourseLoading(false))
      })
    }

    dispatch(getLessonPlans(user.id)).unwrap().then(() => setLessonPlansLoading(false))
    dispatch(getCommunityCategories()).unwrap().then(() => setCommunityCategoriesLoading(false))
    dispatch(getSearchTags()).unwrap().then(() => setSearchTagsLoading(false))
    dispatch(getCommunityPackages()).unwrap().then(() => setPackagesLoading(false))
    if (isFeatureEnabled(user.communities[0], 'materials')) {
      dispatch(getCommunityMaterials()).unwrap().then(() => setMaterialsLoading(false))
    }
    dispatch(getCommunityCoaches({ communityId: user.communities[0].id })).unwrap().then(() => setCoachesLoading(false))
  }, [dispatch, params.id, user.id, user.communities])

  const steps = [
    { index: 1, title: 'Details', icon: DetailsIcon },
    { index: 2, title: 'Schedule', icon: ScheduleIcon },
    { index: 3, title: 'Marketing', icon: MarketingIcon },
    { index: 4, title: 'Pricing', icon: PricingIcon },
  ]

  const invalidFields = {
    name: programFields.name === '',
    subtitle: programFields.subtitle === '',
    startDate: programFields.startDate === '' && scheduleStartDate === '',
    endDate: programFields.endDate === '' && scheduleEndDate === '',
    spotsAvailable: programFields.enableProgramCapacity && programFields.spotsAvailable === '',
    registrationStartsAt: programFields.enableRegistrationDates && programFields.registrationStartsAt === '',
    registrationEndsAt: programFields.enableRegistrationDates && programFields.registrationEndsAt === '',
    scheduleSessions: programFields.schedule.scheduleSessions.filter((s) => s.isInvalid && !s.deleted).length > 0,
    packages: checkLicensePermission(licenseType, [Constants.LICENSES.REVENUE_SHARE]) && programFields.packages.length === 0,
    paymentMethod: checkLicensePermission(licenseType, [Constants.LICENSES.STANDARD, Constants.LICENSES.STANDARD_LEGACY]) &&
                   programFields.packages.length > 0 &&
                   !programFields.paymentMethod['card'] &&
                   !programFields.paymentMethod['cash'],
  }

  const validateProgram = () => {
    const errors = {};

    ['name', 'subtitle', 'startDate', 'endDate', 'spotsAvailable', 'registrationStartsAt', 'registrationEndsAt'].forEach((type) => {
      if (invalidFields[type]) {
        errors[type] = customErrorMessages[type]
        errors.step1 = true
      }
    })

    if (invalidFields.scheduleSessions) {
      errors.scheduleSessions = customErrorMessages.scheduleSessions
      errors.step2 = true
    }

    ['packages', 'paymentMethod'].forEach((type) => {
      if (invalidFields[type]) {
        errors[type] = customErrorMessages[type]
        errors.step4 = true
      }
    })

    dispatch(setField({ field: 'errors', value: errors }))

    return Object.keys(errors).length === 0
  }

  const handleCreateProgram = (programData) => {
    setLoader(true)

    dispatch(createProgram(programData)).unwrap()
      .then(response => {
        handleProgramSaved(response.course)
        if (programFields.schedule.scheduleSessions.length > 0) {
          dispatch(createProgramSchedule({ courseId: response.course.id, ...programFields.schedule })).unwrap()
            .then(() => navigate(`/programs/${response.course.id}`, { state: { showLiveModal: true } }))
            .catch(() => {
              setLoader(false)
              toast.error('Something went wrong. Please try again.', { position: toast.POSITION.TOP_RIGHT })
            })
        } else {
          navigate(`/programs/${response.course.id}`, { state: { showLiveModal: true } })
        }
      })
      .catch(() => {
        setLoader(false)
        toast.error('Something went wrong. Please check the steps where errors occurred and fix them.', { position: toast.POSITION.TOP_RIGHT })
        dispatch(getLessonPlans(user.id))
        dispatch(getCommunityCategories())
        dispatch(getCommunityPackages())
        dispatch(getCommunityCategories())
        if (isFeatureEnabled(user.communities[0], 'materials')) {
          dispatch(getCommunityMaterials())
        }
        dispatch(getCommunityCoaches({ communityId: user.communities[0].id }))
      })
  }

  const handleUpdateProgram = (programData) => {
    setLoader(true)
    const navigateParams = isDraft === programData.isDraft ? null : { showLiveModal: true, ...programData }

    dispatch(updateProgram(programData)).unwrap()
      .then(response => {
        handleProgramUpdated()
        let action = null
        if (programFields.schedule.id) {
          action = updateProgramSchedule
        } else if (programFields.schedule.scheduleSessions.length > 0) {
          action = createProgramSchedule
        }

        if (action) {
          dispatch(action({ courseId: response.course.id, ...programFields.schedule })).unwrap()
            .then(() => navigate(`/programs/${response.course.id}`, { state: navigateParams }))
            .catch(() => {
              setLoader(false)
              toast.error('Something went wrong. Please try again.', { position: toast.POSITION.TOP_RIGHT })
            })
        } else {
          navigate(`/programs/${response.course.id}`, { state: navigateParams })
        }
      })
      .catch(() => {
        setLoader(false)
        toast.error('Something went wrong. Please check the steps where errors occurred and fix them.', { position: toast.POSITION.TOP_RIGHT })
        dispatch(getLessonPlans(user.id))
        dispatch(getCommunityCategories())
        dispatch(getCommunityPackages())
        dispatch(getCommunityCategories())
        if (isFeatureEnabled(user.communities[0], 'materials')) {
          dispatch(getCommunityMaterials())
        }
        dispatch(getCommunityCoaches({ communityId: user.communities[0].id }))
      })
  }

  const handleProgramSaved = (program) => {
    const currentDate = new Date()
    if (program.isDraft) {
      dispatch(getCoachPrograms({ filters: { draft: 'true' }, page: 1, perPage: perPage }))
    } else if (program.registrationStartsAt && new Date(program.registrationStartsAt) > currentDate){
      dispatch(getCoachPrograms({ filters: { ...initialFilters, future: 'true' }, page: 1, perPage: perPage, withEnrollments: 'true' }))
    } else if (program.endDate && new Date(program.endDate) > currentDate) {
      dispatch(getCoachPrograms({ filters: { ...initialFilters, active: 'true' }, page: 1, perPage: perPage, withEnrollments: 'true' }))
    }
  }

  const handleProgramUpdated = () => {
    dispatch(getCoachPrograms({ filters: { draft: 'true' }, page: 1, perPage: perPage }))
    dispatch(getCoachPrograms({ filters: { ...initialFilters, future: 'true' }, page: 1, perPage: perPage, withEnrollments: 'true' }))
    dispatch(getCoachPrograms({ filters: { ...initialFilters, active: 'true' }, page: 1, perPage: perPage, withEnrollments: 'true' }))
    dispatch(getCoachPrograms({ filters: { ...initialFilters, completed: 'true' }, page: 1, perPage: perPage, withEnrollments: 'true' }))
  }

  const handleSaveProgram = (saveAsDraft) => {
    if (saveAsDraft || validateProgram()) {
      const programData = { ...formData, isDraft: saveAsDraft }
      if (id) {
        programData.id = id
        programData.lessonPlanId ||= '0'
        handleUpdateProgram(programData)
      } else {
        handleCreateProgram(programData)
      }
    } else {
      toast.error(
        'Oops, it seems you are missing some required fields. Please check the steps where errors occurred and fix them.',
        { position: toast.POSITION.TOP_RIGHT }
      )
    }
  }

  return (
    <div id='ProgramBuilder'>
      {dataLoaded
        ? <Loader message='Loading program' />
        : <>
          <div className='sticky-top'>
            <BuilderHeader
              onSaveProgram={handleSaveProgram}
              setShowSaveModal={setShowSaveModal}
              loader={loader}
            />
            <FlowNavigation
              currentStep={step}
              steps={steps}
              errors={errors}
            />
          </div>
          {
            step === 1 && <Details /> ||
            step === 2 && <Schedule /> ||
            step === 3 && <Marketing /> ||
            step === 4 && <Pricing />
          }
        </>
      }
      <BuilderFooter
        onSaveProgram={handleSaveProgram}
        setShowSaveModal={setShowSaveModal}
        loader={loader}
        dataLoaded={dataLoaded}
      />

      <SaveModal
        showModal={showSaveModal}
        closeModal={() => setShowSaveModal(false)}
        publishProgram={() => { handleSaveProgram(false); setShowSaveModal(false) }}
        draftProgram={() => { handleSaveProgram(true); setShowSaveModal(false) }}
      />
    </div>
  )
}

export default ProgramBuilder
