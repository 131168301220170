// Application Constants
export const WEB_APP_URL = 'https://operation36golf.com'
export const PER_PAGE_DEFAULT = 25
export const START_PAGE_DEFAULT = 1
export const GENDER_SELECT_OPTIONS = [
  {
    value: 1,
    label: 'Male',
  },
  {
    value: 2,
    label: 'Female',
  },
  {
    value: 0,
    label: 'Other',
  },
]

export const DEFAULT_IMAGE_URL_REGEX = /(^(.)*missing.png$)/

export const DIVISION_SELECT_OPTIONS = [
  {
    value: 1,
    label: 'Level 1, 225 Yards',
  },
  {
    value: 2,
    label: 'Level 2, 450 Yards',
  },
  {
    value: 3,
    label: 'Level 3, 900 Yards',
  },
  {
    value: 4,
    label: 'Level 4, 1350 Yards',
  },
  {
    value: 5,
    label: 'Level 5, 1800 Yards',
  },
  {
    value: 6,
    label: 'Level 6-10(Full Tee Box)',
  },
]

export const SWITCHER_OPTIONS = [
  {
    type: 'programs',
    text: 'Select Programs',
  },
  {
    type: 'students',
    text: 'Select Students',
  },
]

// Currencies Constants
export const USD_CURRENCY = {
  name: 'USA Dollar',
  code: 'usd',
  symbol: '$',
}

// Packages Constants
export const PRICE_REGEX = '^[1-9]([0-9]{0,9})$'
export const FORM_ACTIONS = {
  ADD: 'add',
  EDIT: 'edit',
}

// Class Builder Constants
export const CLASS_CAPACITY_REGEX = '^[1-9][0-9]{0,8}$'

// Program Registration Constants
export const ENROLLMENT_STATUS = {
  'applied': 'Request sent',
  'active': 'Registered',
}

export const PROGRAM_REGISTRATION_STEPS = {
  simple: ['Program Details', 'Checkout', 'Get the App'],
  withMaterials: ['Program Details', 'Program Materials', 'Checkout'],
}

export const PLAY_TYPE = {
  CASUAL_ID: 1,
  OP36_ID: 2,
  TOURNAMENT_ID: 3,
}

// Coach Dashboard
export const DOCUMENT_BODY_REFERENCE = document.querySelector('body')

// Community Form
export const FIXED_FEE_VALUE_REGEX = /^(([1-9][0-9]{0,9})|0)$/
export const PERCENTAGE_FEE_VALUE_REGEX = /^(([1-9][0-9]?)|0|100)$/
export const RGB_COLOR_REGEX = /#([0-9a-fA-F]{3}){1,2}/

// Community Map
export const ZIP_CODE_REGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/

// Community Licenses
export const LICENSES = {
  STANDARD: 'standard',
  STANDARD_LEGACY: 'standard_legacy',
  REVENUE_SHARE: 'revenue_share',
}

export const EVENTS_PLACEHOLDER =
'The 9 Hole Events Manager makes it easy for you to log the scores of your Op 36 Events for your students. Simply:\
\n   1. Add students to event roster\
\n   2. Log the scores of your students\
\n   3. Submit the scores!\
\n\nAll scores are posted to your students profiles and a Leaderboard of the event will be posted to the feed. \
The app will congratulate golfers who passed their level or shot a record score.\
\n\nSince this logs the scores as a formal event, anyone who passed their level will instantly level up in the app, \
and be sent a congratulations email.'

export const NINE_HOLE_EVENTS_PLACEHOLDER =
'The 9 Hole Events Manager streamlines event setup, golfer RSVPs,\
\n master roster management, and score logging - all in one place.'
// Navigation

export const NAVIGATION = {
  COLLAPSED: 'collapsed',
  EXPANDED: 'expanded',
}

export const sideBarItemsCoach = ( community_id ) => [
  { icon: 'home', title: 'Dashboard', links: [{ to: '/', tab: 'Dashboard' }] },
  { icon: 'family', title: 'Students & Families', links: [{ to: '/students-families', tab: 'Students-Families' }] },
  { icon: 'programs', title: 'Manage Programs',
    links: [{ title: 'Group Programs', to: '/programs', tab: 'Programs' }, { title: '9-Hole Events', to: '/events', tab: 'Events' }] },
  {
    icon: 'marketing', title: 'Marketing & Communications',
    links: [{ title: 'Community Landing Page', to: `/landing/${community_id}`, target: '_blank' },
      { title: 'Community Leads', to: '/leads', tab: 'Leads' },
      { title: 'Send an Email', to: '/announcements', tab: 'Announcements' }, { title: 'Academy Display', to: '/pro-shop', tab: 'ProShopDisplay' }],
  },
  {
    icon: 'op36', title: 'Op 36 Coaching Tools',
    links: [{ title: 'Curriculum', to: '/ranks', tab: 'Curriculums' },
      { title: 'Lesson Plans', to: '/lesson-plan', tab: 'lesson-plans' },
      { title: 'Drills & Activities', to: '/drills', tab: 'Drills' }, { title: 'Private Goals', to: '/goals', tab: 'Goals' }],
  },
  { icon: 'reports', title: 'Reports', links: [{ to: '/reports', tab: 'Reports' }] },
  {
    icon: 'academy', title: 'Education Resources',
    links: [{ title: 'Resource Center', to: '/resource-sections', tab: 'Resources' },
      { title: 'Coach Community', external: 'https://operation-36-network-coaches.mn.co/feed', target: '_blank' },
      { title: 'Online Education Courses', external: 'https://operation-36-network-coaches.mn.co/collections/21698', target: '_blank' },
    ],
  },
  { icon: 'cart', title: 'Store', links: [{ external: 'https://op36shop.com/pages/op-36-marketing-store', target: '_blank' }] },
]

export const sideBarItemsLeadCoach = [{ icon: 'settings', title: 'Settings', links: [{ to: '/community-settings', tab: 'Community-Settings' }] }]

export const sideBarItemsAdmin = [
  { icon: 'globe', title: 'Communities', links: [{ to: '/communities', tab: 'Communities' }] },
  { icon: 'users', title: 'Users', links: [{ to: '/users', tab: 'Users' }] },
  { icon: 'family', title: 'Coaches', links: [{ to: '/coaches', tab: 'Coaches' }] },
  { icon: 'lesson', title: 'Lesson Plans', links: [{ to: '/lesson-plan', tab: 'lesson-plans' }] },
  { icon: 'programs', title: 'Objectives', links: [{ to: '/ranks', tab: 'Curriculums' }] },
  { icon: 'trophy', title: 'Social Challenges', links: [{ to: '/challenges', tab: 'Challenges' }] },
  { icon: 'levelup', title: 'Drills & Activities', links: [{ to: '/drills', tab: 'Drills' }] },
  { icon: 'sales', title: 'Sales Leads', links: [{ to: '/sales-leads', tab: 'sales-leads' }] },
  { icon: 'filter', title: 'Community Leads', links: [{ to: '/leads', tab: 'Leads' }] },
  { icon: 'reports', title: 'Reports', links: [{ to: '/reports', tab: 'Reports' }] },
  { icon: 'academy', title: 'Coach Academy', links: [{ to: '/education', tab: 'Education' }] },
  { icon: 'resources', title: 'Resource Center', links: [{ to: '/resource-sections', tab: 'Resources' }] },
]

export const SERIES_SAVE_MESSAGE =
'When this event series is published, everyone who is in the roster will see their schedule in the mobile app.\
  Connected Programs will show this schedule on your sign-up pages.\
  As events approach, they will start receiving RSVP email notifications based on your settings.\
  Alternatively, you can save your series as a draft until you are ready for the series to be acive'
export const SAVE_SCORES_TOOLTIP =
'If you want to save your progress or navigate away from this page, simply hit “Save Scores” and you can come back to publish the event later.\
 As a reminder, golfers will not see these scores or a leaderboard until you publish them.'

export const leaderboardTabs = [
  null,
  ['ActivityPlay', 'ActivityChallenge'],
  'ActivityPractice',
  'ActivityObjective',
]

export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
