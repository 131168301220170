import { useSelector } from 'react-redux'
import { useState } from 'react'

import LessonReviewPublish from '../../lessonPlans/builder/builderViews/LessonReviewPublish'
import CurriculumDetailModal from '../../lessonPlans/modals/CurriculumDetailModal'
import DrillDetailModal from '../../drills/modals/DrillDetailModal'

import classes from './ProgramDashboardManager.module.scss'

const LessonPlanTab = () => {
  const { program } = useSelector(state => state.programDashboard)
  const currentUser = useSelector(state => state.user.current_user)

  const [selectedCurriculumSubject, setSelectedCurriculumSubject] = useState(null)
  const [selectedDrillId, setSelectedDrillId] = useState(null)

  const handleOpenCurriculumDetailModal = (id) => {
    const selectedSubject = program.lessonPlan.tasks.find((sub) => (sub.id === id && sub.name))

    setSelectedCurriculumSubject(selectedSubject)
  }

  const handleOpenDrillDetailModal = (id) => setSelectedDrillId(id)

  return (
    <>
      <div
        style={{
          border: '1px solid black',
          borderRadius: '5px',
          marginTop: '2rem',
          marginBottom: '2rem',
          height: '90%',
        }}
        className={classes.tabPanelContentWidth}
      >
        <LessonReviewPublish
          fromProgramPage={true}
          isBuilder={false}
          sessions={JSON.parse(program.lessonPlan.draftSessions)}
          triggerCurriculumDetailModal={handleOpenCurriculumDetailModal}
          triggerDrillDetailModal={handleOpenDrillDetailModal}
          lessonPlanName={program.lessonPlan.name}
          lessonPlanNotes={program.lessonPlan.notes}
          lessonPlanTags={program.lessonPlan.tags}
          lessonPlanPhoto={
            program.lessonPlan.photo
              ? program.lessonPlan.photo.fileUrl
              : program.lessonPlan.templatePhotoUrl || ''
          }
          drills={program.lessonPlan.tasks.filter((t) => !!t.title)}
          subjects={program.lessonPlan.tasks.filter((t) => !!t.name)}
        />
      </div>
      {!!selectedCurriculumSubject && (
        <CurriculumDetailModal
          hasSideNav={true}
          subject={selectedCurriculumSubject}
          close={() => setSelectedCurriculumSubject(null)}
        />
      )}
      {!!selectedDrillId && (
        <DrillDetailModal
          drillId={selectedDrillId}
          currentUser={currentUser}
          close={() => setSelectedDrillId(null)}
          drillsPage={false}
          handlePin={() => false}
          handleFavorite={() => false}
          currentPage={'ProgramDashboardManagerPage'}
        />
      )}
    </>
  )
}

export default LessonPlanTab
