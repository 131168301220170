/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import moment, { Moment } from 'moment-timezone'
import { useState } from 'react'

import { DATETIME_FORMAT, getMomentInCommunityTimezoneReversed } from '../../utility/datesHelper'
import Input from '../common/form/Input'
import ConfirmationModal2 from '../common/ConfirmationModal2'
import SubmitButton from '../common/buttons/SubmitButton'
import CancelButton from '../common/buttons/CancelButton'
import FieldError from '../common/FieldError'

import './ScheduleSessionForm.scss'

const ScheduleSessionForm = ({
  selectedScheduleSession,
  showConfirmationModal,
  setShowConfirmationModal,
  showDeleteConfirmation,
  setShowDeleteConfirmation,
  closeScheduleSessionModal,
  handleCloseForm,
  handleSubmit,
  handleDelete,
  errors,
  loading,
  timezone,
}) => {
  const startDateInTimezone = getMomentInCommunityTimezoneReversed(moment(selectedScheduleSession?.startDate), timezone, DATETIME_FORMAT)
  const endDateInTimezone = getMomentInCommunityTimezoneReversed(moment(selectedScheduleSession?.endDate), timezone, DATETIME_FORMAT)

  const [scheduleSession, setScheduleSession] = useState(
    selectedScheduleSession ? {
      ...selectedScheduleSession,
      startDate: startDateInTimezone,
      endDate: endDateInTimezone,
    } : {
      title: '',
      startDate: null,
      endDate: null,
      location: '',
      description: '',
    }
  )

  const handleInputChange = e => {
    if (e.target.name === 'title') {
      setScheduleSession({ ...scheduleSession, title: e.target.value })
    } else if (e.target.name === 'location') {
      setScheduleSession({ ...scheduleSession, location: e.target.value })
    } else if (e.target.name === 'description') {
      setScheduleSession({ ...scheduleSession, description: e.target.value })
    }
  }

  const handleDateTimeChange = ({ dateTime, type }) => {
    if (!dateTime) {
      return
    }

    if (type === 'date') {
      let startTime = scheduleSession.startDate || moment(new Date().setMinutes(0))
      startTime = moment(dateTime.format('YYYY-MM-DD') + ' ' + startTime.format('HH:mm'))

      let endTime = scheduleSession.endDate || moment(startTime)
        .add(1, 'hours')
      endTime = moment(dateTime.format('YYYY-MM-DD') + ' ' + endTime.format('HH:mm'))

      setScheduleSession({ ...scheduleSession, startDate: startTime, endDate: endTime })
    } else if (type === 'startTime') {
      const startDate = scheduleSession.startDate || moment(new Date())
      const startTime = moment(startDate.format('YYYY-MM-DD') + ' ' + dateTime.format('HH:mm'))
      setScheduleSession({
        ...scheduleSession,
        startDate: startTime,
        endDate: moment(startTime)
          .add(1, 'hours'),
      })
    } else if (type === 'endTime') {
      const endDate = scheduleSession.endDate || moment(new Date())
      setScheduleSession({
        ...scheduleSession,
        endDate: moment(endDate.format('YYYY-MM-DD') + ' ' + dateTime.format('HH:mm')),
      })
    }
  }

  return (
    <div id='ScheduleSessionForm'>
      <div className='container-fluid p-0'>
        <div className='row'>
          <div className='col-12 mb-2 d-flex justify-content-between'>
            <h1 className='form-title'>Edit Session</h1>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span className='delete-button' onClick={() => setShowDeleteConfirmation(true)}>
              <i className='fa fa fa-trash' />
              {' Delete session'}
            </span>
          </div>
          <div className='col-12 mb-2'>
            {errors?.general && <FieldError errorMessage={errors.general} style={{ paddingBottom: '5px' }} generalError />}
            <label htmlFor='titleInput' className='form-label'>Title</label>
            <div className='form-field'>
              <Input
                id={`titleInput-${scheduleSession.index ? scheduleSession.index : '0'}`}
                name='title'
                type='text'
                onChange={handleInputChange}
                placeholder='Enter session title (optional)'
                value={scheduleSession.title}
                error={errors?.title}
              />
            </div>
          </div>
          <div className='col-12'>
            <div className='col-12 p-0 col-md-4 mb-2 date-time'>
              <label htmlFor='dateInput' className='form-label'>
                Date <span className='required-text'>*</span>
              </label>
              <div className='form-field'>
                <Input
                  id={`dateInput-${scheduleSession.index ? scheduleSession.index : '0'}`}
                  name='date'
                  type='date'
                  onChange={dateTime => handleDateTimeChange({ dateTime, type: 'date' })}
                  placeholder='MM/DD/YYYY'
                  value={scheduleSession.startDate}
                  error={errors?.startDate}
                  symbol={<i className='fa fa-calendar' />}
                />
              </div>
            </div>
          </div>
          <div className='col-12'>
            <div className='col-12 p-0 col-md-4 mb-2 date-time'>
              <label htmlFor='startTimeInput' className='form-label'>
                Time <span className='required-text'>*</span>
              </label>
              <div className='form-field'>
                <Input
                  id={`startTimeInput-${scheduleSession.index ? scheduleSession.index : '0'}`}
                  name='startTime'
                  type='time'
                  classNames={{ inputContainer: 'br-0' }}
                  onChange={dateTime => handleDateTimeChange({ dateTime, type: 'startTime' })}
                  placeholder='10:00 AM'
                  value={scheduleSession.startDate}
                  symbol={<i className='fa fa-clock-o' />}
                />
                <Input
                  id={`endTimeInput-${scheduleSession.index ? scheduleSession.index : '0'}`}
                  name='endTime'
                  type='time'
                  classNames={{ inputContainer: 'bl-0' }}
                  onChange={dateTime => handleDateTimeChange({ dateTime, type: 'endTime' })}
                  placeholder='10:00 AM'
                  value={scheduleSession.endDate}
                  symbol={<i className='fa fa-minus' />}
                />
              </div>
              {errors?.endDate && <FieldError message={errors?.endDate} />}
            </div>
          </div>
          <div className='col-12 mb-2'>
            <label htmlFor='locationInput' className='form-label'>Location</label>
            <div className='form-field'>
              <Input
                id={`locationInput-${scheduleSession.index ? scheduleSession.index : '0'}`}
                name='location'
                type='text'
                onChange={handleInputChange}
                placeholder='Add location (optional)'
                value={scheduleSession.location}
                error={errors?.location}
                symbol={<i className='fa fa-map-marker' />}
              />
            </div>
          </div>
          <div className='col-12 mb-2'>
            <label htmlFor='decriptionInput' className='form-label'>Description</label>
            <div className='form-field'>
              <Input
                id={`descriptionInput-${scheduleSession.index ? scheduleSession.index : '0'}`}
                name='description'
                type='textarea'
                onChange={handleInputChange}
                placeholder='Add description (optional)'
                value={scheduleSession.description}
                error={errors?.description}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <CancelButton
              onClick={() => closeScheduleSessionModal(scheduleSession)}
              buttonMessage='Cancel'
              className='mt-3'
            />
          </div>
          <div className='col-6'>
            <SubmitButton
              onClick={e => handleSubmit(e, scheduleSession)}
              buttonMessage={loading ? 'Saving' : 'Save'}
              loading={loading}
            />
          </div>
        </div>
      </div>
      <ConfirmationModal2
        showModal={showConfirmationModal}
        closeModal={() => setShowConfirmationModal(false)}
        onDeny={() => setShowConfirmationModal(false)}
        denyMessage='Cancel'
        onProceed={handleCloseForm}
        proceedMessage='Discard'
        icon={<i className='fa fa-question-circle' style={{ fontSize: '72px' }} />}
        title='Discard changes to this session?'
        message=''
      />
      <ConfirmationModal2
        showModal={showDeleteConfirmation}
        closeModal={() => setShowDeleteConfirmation(false)}
        onDeny={() => setShowDeleteConfirmation(false)}
        denyMessage='Cancel'
        onProceed={() => handleDelete(scheduleSession)}
        proceedMessage='Delete'
        icon={<i className='fa fa-trash' style={{ fontSize: '72px' }} />}
        title='Delete session?'
        message='This will remove the session from your schedule'
        isLoading={loading}
      />
    </div>
  )
}

ScheduleSessionForm.propTypes = {
  selectedScheduleSession: PropTypes.shape({
    startDate: PropTypes.instanceOf(Moment),
    endDate: PropTypes.instanceOf(Moment),
  }),
}

export default ScheduleSessionForm
