import classNames from 'classnames'
import PageHeader from '../../common/PageHeader/PageHeader'
import { useSelector } from 'react-redux'
import styles from './CoachDashboardNew.module.scss'
import DashboardHeaderNotifications from './DashboardHeaderNotifications'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import CoachDashboardHome from './CoachDashboardHome'
import CoachDashboardOp36 from './CoachDashboardOp36'
import EventInProgressAlert from '../../events/EventInProgressAlert'
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { selectIsOnboardingEnabled } from '../../../reducers/coachOnboardingReducer'
import CoachOnboardingDismissModal from './coachOnboarding/CoachOnboardingDismissModal'

const CoachDashboardNew = () => {
  const community = useSelector(state => state.user.current_user.communities[0])
  const isCoachOnboardingEnabled = useSelector(selectIsOnboardingEnabled)
  const title = `${community.name} Dashboard`
  const [tabIndex, setTabIndex] = useState(0)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (location.pathname.includes('/op36')) {
      setTabIndex(1)
    } else {
      setTabIndex(0)
    }
  }, [location])

  const tabNavigation = (index) => {
    index === 0 ? navigate('/dashboard') : navigate('/dashboard/op36')
  }

  return (
    <div className={styles.coachDashboardNew}>
      <PageHeader
        title={title}
        iconUrl={community.photo?.file_url}
        titleUrl={`/landing/${community.id}`}
        actionComponent={<DashboardHeaderNotifications />}
      />
      <Tabs
        className={styles.tabs}
        selectedIndex={tabIndex}
        onSelect={(index) => tabNavigation(index)}
        forceRenderTabPanel={true}
      >
        {community.ongoing_events_count > 0 && <EventInProgressAlert scoresPassedDue={true} onClick={() => navigate('/events')}/>}
        <TabList className={classNames(styles.tabList, { [styles.hideTabList]: isCoachOnboardingEnabled })}>
          <Tab className={`${styles.tab} react-tabs__tab`} selectedClassName={styles.activeTab}>Home</Tab>
          <Tab className={`${styles.tab} react-tabs__tab`} selectedClassName={styles.activeTab}>Op 36 Dashboard</Tab>
        </TabList>
        <TabPanel className={styles.tabPanel}>
          <CoachDashboardHome />
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          <CoachDashboardOp36/>
        </TabPanel>
      </Tabs>
      {isCoachOnboardingEnabled && <CoachOnboardingDismissModal />}
    </div>
  )
}

export default CoachDashboardNew
