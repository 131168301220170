import classnames from 'classnames'
import ButtonLink from '../../common/buttons/ButtonLink'

import classes from './ReportsTab.module.scss'
import { ReactComponent as FlameIcon } from '../../../assets/images/common/icons/flame-icon.svg'
import ReportsPlaceholder from '../../../assets/images/mock/reports-placeholder.webp'

const ReportsTab = () => (
  <div className={classnames('p-5 d-flex', classes.reportsTab)}>
    <div className={classnames('d-flex flex-column', classes.leftPanel)}>
      <h2 className={classnames('mb-4 d-flex align-items-center', classes.title)}>
        <FlameIcon />
        <span className='ml-3'>Coming Soon...</span>
      </h2>
      <h3 className={classnames('mb-4', classes.subtitle)}>Program Reports</h3>
      <p className={classnames('mb-3', classes.firstText)}>Get key insights instantly with downloadable CSV reports.</p>
      <p className={classes.secondText}>Have ideas for report features? Let us know below!</p>
      <ButtonLink
        navigateTo='https://form.asana.com/?k=5r5aZmuhGUauVpPq43U6qA&d=280429732382352'
        target='_blank'
        isExternal
      >
        Share Your Ideas
      </ButtonLink>
    </div>
    <div className={classnames('ml-3 d-flex justify-content-center', classes.rightPanel)}>
      <img
        src={ReportsPlaceholder}
        alt='Reports placeholder'
        className={classes.image}
      />
    </div>
  </div>
)

export default ReportsTab
