import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Wizard, Steps, Step } from 'react-albus'
import * as courseActions from '../../../actions/courseActions'
import { getCoachPrograms } from '../../../actions/programActions'
import { selectInitialFilters, perPage } from '../../../reducers/groupProgramsReducer'

import PropTypes from 'prop-types'
import ProgramMarketplaceFlow from './ProgramMarketplaceFlow'
import ProgramDetailPage from '../ProgramBuilderOld/ProgramDetailPage'

export const ProgramStates = {
  PUBLISH: 'publish',
  DRAFT: 'draft',
}

const CopyProgramDialog = ({ closeModal, user }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const initialFilters = useSelector(selectInitialFilters)

  const [selectedProgram, setSelectedProgram] = useState(false)
  const [builderLoader, setBuilderLoader] = useState(false)

  const submitCopyProgram = (buttonType) => {
    const draftProgram = buttonType === 'draft'
    setBuilderLoader(true)
    dispatch(courseActions.createCourseFromProgram(selectedProgram.id, { is_draft: draftProgram }))
      .then((response) => {
        const program = response.course
        const currentDate = new Date()
        if (draftProgram){
          dispatch(getCoachPrograms({ filters: { draft: 'true' }, page: 1, perPage: perPage }))
        } else if (program.registration_starts_at && new Date(program.registration_starts_at) > currentDate){
          dispatch(getCoachPrograms({ filters: { ...initialFilters, future: 'true' }, page: 1, perPage: perPage, withEnrollments: 'true' }))
        } else if (program.end_date && new Date(program.end_date) > currentDate) {
          dispatch(getCoachPrograms({ filters: { ...initialFilters, active: 'true' }, page: 1, perPage: perPage, withEnrollments: 'true' }))
        }
        setBuilderLoader(false)
        navigate(`/programs/${response.course.id}`)
      })
  }

  return (
    <div id='CopyProgramDialog'>
      <div className='modal-header row'>
        <div className='col-10'>
          <h5 className='modal-title display-4'>
            Copy Program
          </h5>
        </div>
        <div className='col-2'>
          <button
            type='button'
            className='close pull-right'
            data-dismiss='modal'
            aria-label='Close'
            onClick={closeModal}
          >
            <span aria-hidden='true'>✕</span>
          </button>
        </div>
      </div>
      <Wizard>
        <Steps>
          <Step id='step1' render={({ next }) => (
            <div className='row'>
              <div className='col-md-12 mx-auto'>
                <ProgramMarketplaceFlow
                  onSelectedProgram={(program) => { setSelectedProgram(program); next() }}
                  user={user}
                />
              </div>
            </div>
          )}/>
          <Step id='step2' render={({ previous }) => (
            <div>
              <button
                className='previous-step-button'
                onClick={previous}
              >
                <i className='fa fa-long-arrow-left' />
              </button>
              <ProgramDetailPage
                builderLoader={builderLoader}
                selectedProgram={selectedProgram}
                submitProgram={() => submitCopyProgram(ProgramStates.PUBLISH)}
                draftProgram={() => submitCopyProgram(ProgramStates.DRAFT)}
                user={user}
              />
            </div>
          )}/>
        </Steps>
      </Wizard>
    </div>
  )
}

CopyProgramDialog.propTypes = {
  closeModal: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
}

export default CopyProgramDialog
