import classes from './CoachOnboardingDismissModal.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../../common/Modal'
import classNames from 'classnames'
import { setIsModalVisible } from '../../../../reducers/coachOnboardingReducer'
import { ReactComponent as FlagIcon } from '../../../../assets/images/common/icons/Flag.svg'
import { updateOnboardingStatus } from '../../../../actions/coachOnboardingActions'


const CoachOnboardingDismissModal = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector(state => state.user.current_user)
  const isOpen = useSelector(state => state.coachOnboarding.isModalVisible)

  const handleClose = () => dispatch(setIsModalVisible(false))
  const dismissOnboarding = () => {
    handleClose()
    dispatch(updateOnboardingStatus({ userId: currentUser.id, actionType: 'dismiss' }))
  }

  return (
    <Modal
      isOpen={isOpen}
      contentLabel='CoachOnboardingDismissModal'
      className={classes.modalWrapper}
      onClose={handleClose}
    >
      <div className={classNames(classes.modalContent, 'd-flex flex-column justify-content-center align-items-center w-full')}>
        <div className={classNames(classes.flagContainer, 'd-flex justify-content-center align-items-center')}>
          <FlagIcon width={44} height={44} color='white' />
        </div>
        <h4 className={classes.title}>Dismiss Training</h4>
        <p className={classNames(classes.message, 'text-center')}>
          This checklist and videos contain our most up to date training with new app tutorials and coach education.
          We recommend that all watch, but if you would like to dismiss the course to unlock your community feed,
          you can do this by clicking below.
        </p>
        <button
          className={classNames(classes.dismissButton, 'btn btn-primary')}
          onClick={dismissOnboarding}
        >
          Dismiss Training
        </button>
        <button
          className={classNames(classes.cancelButton, 'btn btn-secondary')}
          onClick={handleClose}
        >
          Cancel
        </button>
      </div>
    </Modal>
  )
}

export default CoachOnboardingDismissModal
