import { useSelector } from 'react-redux'
import SeriesPresentationList from '../../events/SeriesPresentationList'

import classes from './ProgramDashboardManager.module.scss'
import classnames from 'classnames'

const SeriesScheduleTab = () => {
  const { program } = useSelector((state) => state.programDashboard)
  const currentUser = useSelector((state) => state.user.current_user)

  return (
    <SeriesPresentationList
      series={program.series}
      timezone={currentUser.communities[0].timezone}
      className={classnames('pb-1', classes.tabPanelContentWidth)}
      isEditable
    />
  )
}

export default SeriesScheduleTab
