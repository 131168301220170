import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './StudentRow.module.scss'
import StockProfilePic from '../../../assets/images/mock/default-profile-icon.png'
import { ReactComponent as StarIcon } from '../../../assets/images/common/communityLeaderboard/favorite-icon.svg'

const rankClass = {
  1: styles.firstPlace,
  2: styles.secondPlace,
  3: styles.thirdPlace,
}
const rankOrder = ['st', 'nd', 'rd']

const StudentRow = ({ rank, profile, name, points }) => (
  <div className={classnames(styles.communityLeaderboardStudentRow, { [rankClass[rank]]: rank <= 3 }, { [styles.topRank]: rank <= 3 })}>
    <div className={styles.studentInfo}>
      <div className={styles.rank}>{rank}{rankOrder[rank - 1]}</div>
      <div
        className={styles.profileImg}
        style={{
          backgroundImage: profile ? `url(${profile})` : `url(${StockProfilePic})`,
        }}
      />
      <p className={styles.name}>{name}</p>
    </div>
    <div className={styles.medal}/>
    <div className={styles.studentInfo}>
      <StarIcon/>
      <div>{points}pts</div>
    </div>
  </div>
)

StudentRow.propTypes = {
  rank: PropTypes.number,
  profile: PropTypes.string,
  name: PropTypes.string,
  points: PropTypes.number,
}

export default StudentRow
