import React from 'react'
import PropTypes from 'prop-types'

// REDUX BITS
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as userActions from '../../actions/userActions'
import * as commonActions from '../../actions/commonActions'
import * as playTypeActions from '../../actions/playTypeActions'
import * as objectiveActions from '../../actions/objectiveActions'
import * as activityActions from '../../actions/activityActions'
import * as rankActions from '../../actions/rankActions'
import * as subjectActions from '../../actions/subjectActions'
import * as goalActions from '../../actions/goalActions'
import * as practiceTypeActions from '../../actions/practiceTypeActions'
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs'

// STYLES
import '../../assets/styles/components/activity/ActivityFeedPage.scss'
import './ProfilePage.scss'

import * as fadeAnimation from '../common/fadeAnimation'
import _ from 'lodash'
import moment from 'moment'

// CHILDREN
import Placeholder from '../common/Placeholder'
import ConfirmationModal from '../common/ConfirmationModal'
import ValidationModal from '../common/ValidationModal'
import LogActionsModal from './LogActionsModal'
import PlayerProgressModal from './PlayerProgressModal'
import ProfileHeader from './ProfileHeader-web'
import ProfileStickyHeader from './ProfileStickyHeader'
// import ProfileTrophies from "./Badges/ProfileTrophies-web";
import ProfileStats from './Stats/ProfileStats-web'
import PlayStats from './Stats/PlayStats-web'
import TrainStats from './Stats/TrainStats-web'
import ProfileActivities from './ProfileActivities-web'
import Activity from '../activity/Activity'
import GoalFlow from './Goals/GoalFlow'
import MilestonePage from './MilestonePage'

import Loader from '../common/Op36Loader-web'
import StudentRoundsModal from '../reports/StudentRoundsModal'
import { withRouting } from '../common/hooks'
import EditGolferModal from './EditGolferModal'

class ProfilePage extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      user: null,
      loading: true,
      rankId: -1,
      // incompleteObjectives: [],
      // issueTrophyFormObjectiveId: -1,
      issueTrophyFormUserId: -1,
      currentEnrollment: {
        user: this.props.user ? this.props.user : null,
      },
      ranks: [],
      objectives: [],
      objective: {},
      showCompleteModal: false,
      showLogActionsModal: false,
      incompleteObjectives: [],
      issueTrophyFormObjectiveId: -1,
      selectedObjectiveRank: -1,
      isRankCompleted: false,
      leveledUpUser: false,
      activityLoader: true,
      statsLoader: true,
      userLoader: true,
      playLoader: true,
      practiceLoader: true,
      levelUpLoader: false,
      isLevelUp: false,
      trainLoader: true,
      trainActivitiesPages: 1,
      trainCurrentPage: 1,
      perPage: 10,
      goalActivityLoader: true,
      ranksLoader: true,
      objectiveLoader: false,
      reviewLoader: false,
      divisionLoader: true,
      fullName: this.props.user
        ? `${this.props.user.first_name} ${this.props.user.last_name}`
        : '',
      userGoalLoader: true,
      gotObjectives: false,
      gotSubjects: false,
      gotInitialGoals: false,
      gotGoals: false,
      currentRank: null,
      percentage: 0,
      completedCount: 0,
      userId:
        this.props.location &&
        this.props.location.state
          ? this.props.location.state.userId
          : (this.props.params.userId || null),
      goals: [],
      page: 1,
      currentObjective: {},
      goal: null,
      goalLoader: false,
      rowLoader: true,
      collapse: false,
      className: '',
      stickyClassName: '',
      scrolled: false,
      confirmationMessage: '',
      showValidationModal: false,
      validationSuccess: true,
      validationMessage: '',
      showCreateGoal: false,
      progressLoader: false,
      showPlayerProgressModal: false,
      showJourneyModal: false,
      playTypeSelected: 'all', // keeps track of switching play types in player progress modal,
      removeObjective: false, // added to check against for completing and removing objectives
      scrolledToActivity: true,
      activityAdded: false,
      activityId: -1,
      showGolferModal: false,
    }

    this.displayProfile = this.displayProfile.bind(this)
    this.handleRankObjectiveSelectChanged =
      this.handleRankObjectiveSelectChanged.bind(this)
  }

  componentDidMount() {
    const { common_actions } = this.props
    common_actions.setCurrentTab('Profile')
    common_actions.showSideBar(true)
    common_actions.showNavBar(true)
    window.addEventListener('scroll', this.handleScroll, true)
    this.fetchProfileWithProps(this.props)
    const activityId = this.props.location.state?.activityId
    || parseInt(new URLSearchParams(this.props.location.search).get('activity_id'))
    document.getElementById('profilePage').scrollIntoView()
    this.applySmartToggle()
    if (activityId) {
      this.setState({ scrolledToActivity: false, activityId: activityId })
    }
  }

  componentDidUpdate(prevProps) {
    const oldUserId = prevProps.location?.state?.userId
    const newUserId = this.props.location?.state?.userId

    let { scrolledToActivity } = this.state
    const oldActivityId = prevProps.location?.state?.activityId
    const newActivityId = this.props.location?.state?.activityId

    if (newActivityId && newActivityId !== oldActivityId) {
      scrolledToActivity = false
    }

    if (oldUserId !== newUserId) {
      this.fetchProfileWithProps(this.props)
    }

    // open the corresponded modal even if the page remains the same and only user id or smarttoggle(action) is changed
    this.applySmartToggle()

    if (!scrolledToActivity && this.activityRef) {
      this.setState({ scrolledToActivity: true }, () => {
        this.activityRef.scrollIntoView({
          block: 'center',
        })
      })
    }
  }

  applySmartToggle = () => {
    const { location } = this.props
    if (
      !_.isEmpty(location.state) &&
      location.state.smartToggle === 'studentAction'
    ) {
      this.setState({
        showLogActionsModal: true,
      })
      // reset smarttoggle to prevent infinite rerender
      this.props.navigate(location.pathname, { state: { userId: location.state.userId } })
    }

    if (
      !_.isEmpty(location.state) &&
      location.state.smartToggle === 'studentGoal'
    ){
      this.setState({
        showLogActionsModal: true,
        showCreateGoal: true,
      })
      // reset smarttoggle to prevent infinite rerender
      this.props.navigate(location.pathname, { state: { userId: location.state.userId } })
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (!this.props.user || !nextProps.user) {
      return null
    }

    if (nextProps.user.id !== this.props.user.id) {
      this.setState({ userLoader: true })
      this.fetchProfileWithProps(nextProps)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true)
  }

  setTrainActivitiesPages = (page) => {
    this.setState({
      trainActivitiesPages: page,
    })
  }

  setActivityAdded = (newState) => {
    this.setState({
      activityAdded: newState,
    })
  }

  handleScroll = (event) => {
    if (event.target.scrollTop > 50 && event.target.id === 'appWebBody') {
      this.setState({
        className: 'animated fadeOutUp',
        stickyClassName: 'animated fadeInDown',
        scrolled: true,
      })
    } else if (
      event.target.scrollTop < 50 &&
      event.target.id === 'appWebBody'
    ) {
      this.setState({
        className: 'animated fadeInDown',
        stickyClassName: 'animated fadeOutUp',
        scrolled: false,
      })
    }
  }

  getRanksOnly = (user) => {
    if (user.user_curriculum) {
      // let op36CurriculumId = user.user_curriculums[0].program_curriculum_id;
      // if (op36CurriculumId) {
      this.props.rank_actions.getCurriculumRanks(user.id).then((_res) => {
        this.setState(
          {
            ranks: this.props.ranks,
          },
          () => {
            this.getCurrentRank()
            this.setState({
              ranksLoader: false,
            })
          }
        )
      })
      // }
    } else {
      return null
    }
  }
  /*******
Goal Logic
*******/

  deleteItem = (item, isGoal, renderGoalPhotos) => {
    this.setState({
      reviewLoader: true,
    })

    if (isGoal) {
      this.props.goal_actions.deleteGoal(item).then((_res) => {
        this.getUserGoals(this.props.user, renderGoalPhotos)
        this.toggleValidationModal(
          true,
          `You have successfully deleted '${item.name}'`
        )
        this.startPage()
      })
    } else {
      this.props.goal_actions.deleteGoalReview(item).then((_res) => {
        this.getUserGoals(this.props.user, renderGoalPhotos)
      })
    }
  }

  updateGoal = (state, index, renderGoalPhotos) => {
    this.setState({
      reviewLoader: true,
    })
    const goalTasks = [...state.goalTasks]
    const deletedTasks = [...state.deletedTasks]
    const deletedPictures = [...state.deletedPictures]
    if (state.pictures.length > 0) {
      this.setState({
        uploadingPhoto: true,
      })
    }
    this.setState(
      {
        goalTasks: goalTasks,
      },
      () => {
        this.props.goal_actions
          .updateGoal(state, goalTasks, deletedTasks, deletedPictures)
          .then((_res) => {
            this.getUserGoals(this.props.user, renderGoalPhotos)
            this.setState({
              uploadingPhoto: false,
            })
          })
      }
    )
  }

  reviewTask = (state, id, renderGoalPhotos) => {
    this.setState(
      {
        reviewLoader: true,
      },
      () => {
        this.props.goal_actions.addGoalReview(state).then((_res) => {
          this.getUserGoals(this.props.user, renderGoalPhotos)
          this.fetchProfileWithProps(this.props)
        })
      }
    )
  }

  getUserGoals = (user, renderGoalPhotos) => {
    this.props.actions.getUser(user.id).then((_res) => {
      this.props.actions
        .getUserGoals(user.id)
        .then((_res) => {
          if (this.state.goal) {
            this.getCustomGoalPage(this.state.goal)
          }
          if (this.state.showLogActionsModal && this.state.goal) {
            this.setState(
              {
                page: 1,
              },
              () => {
                this.closeLogActionsModal()
              }
            )
          }
        })
        .then((_res) => {
          this.setState(
            {
              goals: this.props.user.goals,
              gotGoals: true,
            },
            () => {
              if (this.state.reviewLoader) {
                this.setState(
                  {
                    reviewLoader: false,
                  },
                  () => {
                    renderGoalPhotos()
                  }
                )
              }
            }
          )
        })
    })
  }

  getObjectivesFromRank = (rankId, userId) => {
    this.setState({
      objectiveLoader: true,
    })
    this.props.objective_actions
      .getRankObjectives(rankId, userId)
      .then((_res) => {
        this.setState({
          rankId,
          objectives: this.props.objectives[rankId],
          objectiveLoader: false,
        })
      })
  }

  getObjectivesFromRankRow = (rankId, userId) => {
    this.props.objective_actions
      .getRankObjectives(rankId, userId)
      .then((_res) => {
        this.setState({
          rankId,
          rowLoader: false,
          objectives: this.props.objectives[rankId],
          gotInitialGoals: true,
        })
      })
  }

  getUserRanks = (user, selectedRankId) => {
    if (user.user_curriculum) {
      const current_rank_id = selectedRankId || this.props.user_curriculum.rank_id
      this.props.rank_actions.getCurriculumRanks(user.id).then((_response) => {
        this.props.objective_actions
          .getRankObjectives(current_rank_id, user.id)
          .then(() => {
            this.setState({ rankId: current_rank_id })
            if (this.props.objectives[current_rank_id].length > 0) {
              const incompleteObjectives = []
              this.props.objectives[current_rank_id].forEach((objective) => {
                if (objective.is_completed === false) {
                  incompleteObjectives.push(objective)
                }
              })
              if (incompleteObjectives.length > 0) {
                this.setState({
                  incompleteObjectives: incompleteObjectives,
                  issueTrophyFormObjectiveId: incompleteObjectives[0].id,
                })
              }
            }
          })
          .then(() => {
            this.props.actions.getUser(this.props.user.id).then(() => {
              this.setState(
                {
                  ranks: this.props.ranks,
                },
                () => {
                  this.getCurrentRank()
                  this.setState({
                    ranksLoader: false,
                    rowLoader: false,
                    objectiveLoader: false,
                    levelUpLoader: false,
                  })
                  if (this.state.showCompleteModal && !this.state.isLevelUp) {
                    this.closeCompleteModal()
                    this.toggleValidationModal(
                      true,
                      'You have successfully completed the objective'
                    )
                  } else if (
                    this.state.showCompleteModal &&
                    this.state.isLevelUp
                  ) {
                    this.closeCompleteModal()
                    this.toggleValidationModal(
                      true,
                      'You have successfully leveled up the golfer'
                    )
                  }
                }
              )
            })
            this.checkIsRankCompleted()
          })
      })
      // }
    } else {
      return null
    }
  }

  toggleEnterState = () => {
    setTimeout(
      () => {
        this.setState({ in: true })
      },
      fadeAnimation.FADE_DURATION
    )
  }

  stopLoading = () => {
    setTimeout(
      () => {
        this.setState({ loading: false })
      },
      fadeAnimation.FADE_DURATION
    )
  }

  fetchProfileWithProps(props) {
    const userId = props.location.state?.userId || this.state.userId || this.props.params.userId
    const perPage = this.state.perPage

    props.actions.getUser(userId).then((res) => {
      if (res) {
        this.setState({
          userLoader: false,
        })
        if (this.props.user.type !== 'Admin') {
          props.actions
            .getUserStats(userId)
            .then((res) => {
              if (res) {
                this.setState({
                  statsLoader: false,
                })
              }
            })
        }
        props.actions
          .getUserDivision(userId, 'formal')
          .then((res) => {
            if (res) {
              this.setState({ divisionLoader: false })
            }
          })

        props.actions
          .getUserActivities(userId)
          .then((res) => {
            if (res) {
              this.setState({
                activityLoader: false,
              })
            }
          })
        props.actions
          .getUserTrainActivities(userId, 1, perPage)
          .then((res) => {
            if (res) {
              this.setState({
                trainLoader: false,
                trainActivitiesPages: res.pagination?.total_pages,
              })
            }
          })
        props.actions
          .getUserPlayActivities(userId)
          .then((res) => {
            if (res) {
              this.setState({
                playLoader: false,
              })
            }
          })
        props.actions
          .getUserGoalActivities(userId)
          .then((res) => {
            if (res) {
              this.setState({
                goalActivityLoader: false,
              })
            }
          })
        props.actions
          .getUserObjectiveActivities(userId)
          .then((res) => {
            if (res) {
              this.setState({
                practiceLoader: false,
              })
            }
          })
      }
    })
    if (props.practice_types.length === 0) {
      props.practice_type_actions.getPracticeTypes()
    }
    if (props.play_types.length === 0) {
      props.play_type_actions.getPlayTypes()
    }
    // if (props.user.user_curriculums[0] && props.user.id == props.routeParams.userId) {
    //   this.getUserRanks(props.user);
    // }

    // this.stopLoading();
  }

  abbrNum(number, decPlaces) {
    decPlaces = Math.pow(10, decPlaces)

    const abbrev = ['k', 'm', 'b', 't']

    for (let i = abbrev.length - 1; i >= 0; i--) {
      const size = Math.pow(10, (i + 1) * 3)

      if (size <= number) {
        number = Math.round((number * decPlaces) / size) / decPlaces

        if (number === 1000 && i < abbrev.length - 1) {
          number = 1
          i++
        }

        number += abbrev[i]
        break
      }
    }

    return number
  }

  reloadStats = (activityType = 'all') => {
    const userId = this.state.userId
    const perPage = this.state.perPage

    this.setState({
      statsLoader: true,
      trainLoader: true,
      playLoader: true,
      divisionLoader: true,
    })
    this.props.actions
      .getUserStats(userId)
      .then((res) => {
        if (res) {
          this.setState({
            statsLoader: false,
          })
        }
      })
    if (activityType === 'all' || activityType === 'train') {
      this.props.actions
        .getUserTrainActivities(userId, 1, perPage)
        .then((res) => {
          if (res) {
            this.setState({
              trainLoader: false,
              trainActivitiesPages: res.pagination?.total_pages,
            })
          }
        })
    }
    if (activityType === 'all' || activityType === 'play') {
      this.props.actions
        .getUserPlayActivities(userId)
        .then((res) => {
          if (res) {
            this.setState({
              playLoader: false,
            })
          }
        })
    }
    this.props.actions.getUserDivision(userId).then(() => {
      this.setState({ divisionLoader: false })
    })
  }

  reloadTrainActivities = (page) => {
    const userId = this.state.userId
    const perPage = this.state.perPage
    this.props.actions
      .getUserTrainActivities(userId, page, perPage)
      .then((res) => {
        if (res) {
          this.setState({
            trainLoader: false,
            trainActivitiesPages: res.pagination?.total_pages,
          })
        }
      })
  }

  handlePaginateClick = (button) => {
    const page = this.state.trainCurrentPage
    if (button === 'next') {
      this.setState({
        trainCurrentPage: page + 1,
      }, () => this.reloadTrainActivities(this.state.trainCurrentPage))
    } else if (button === 'previous') {
      this.setState({
        trainCurrentPage: page - 1,
      }, () => this.reloadTrainActivities(this.state.trainCurrentPage))
    }
  }

  pageActivities = () => {
    const activityId = this.props.location.state?.activityId || this.state.activityId
    if (this.props.activities.length > 0) {
      return this.props.activities.map((activity) => {
        const isFromNotifications = (activity.id === activityId)

        return (
          <Activity
            key={activity.id}
            displayComments={false}
            activity={activity}
            isStudentJourney={true}
            triggerReload={this.reloadStats}
            isFromNotifications={isFromNotifications}
            innerRef={isFromNotifications ? (ref) => this.activityRef = ref : null}
          />
        )
      })
    } else if (this.props.activities.length === 0) {
      return (
        <Placeholder
          title={`${this.props.user.first_name}'s Journey Began on ${moment(
            this.props.user.created_at
          ).format('MM-DD-YYYY')}`}
          icon={'fa-flag'}
          subtext1={'Welcome to your Golf Journey timeline'}
          subtext2={'Improving at golf is a life-long Journey'}
          subtext3={
            'Your golf actions, goals, and achievements will show up here'
          }
        />
      )
    } else {
      return null
    }
  }

  checkIsRankCompleted = () => {
    const { rankId } = this.state
    if (this.props.objectives[rankId].length > 0) {
      let rankCompleted = true
      // eslint-disable-next-line
      this.props.objectives[rankId].map((objective, index) => {
        if (objective.is_completed === false) {
          //this.setState({isRankCompleted: false})
          rankCompleted = false
          return; // eslint-disable-line
        }
      })
      this.setState({
        isRankCompleted: rankCompleted,
        leveledUpUser: false,
        userGoalLoader: false,
        gotObjectives: true,
      })
    }
  }

  progressRankForUser = () => {
    const userCurriculum = this.props.user.user_curriculums[0]
    this.setState({ loading: true, levelUpLoader: true })
    this.props.actions
      .progressRank(this.props.user.id, this.props.user.current_course_id)
      .then((_res) => {
        this.props.activity_actions
          .postActivityRank(this.state, userCurriculum)
          .then((_res) => {
            this.props.actions
              .getUserObjectiveActivities(this.props.user.id)
              .then(() => {
                this.props.actions.getUserStats(this.props.user.id)
                this.props.actions
                  .getUserActivities(this.props.user.id)
                  .then(() => {
                    this.getUserRanks(this.props.user)
                    this.setState(
                      {
                        leveledUpUser: true,
                        isRankCompleted: false,
                        loading: false,
                      },
                      () => {
                        this.closeLogActionsModal()
                      }
                    )
                  })
              })
          })
      })
  }

  getSubjects = () => {
    this.props.subject_actions.getAllSubjects().then((_res) => {
      this.setState({
        gotSubjects: true,
      })
    })
  }

  toggleRowLoader = (type) => {
    this.setState({
      rowLoader: type,
    })
  }

  toggleLogActionsModal = (type) => {
    this.setState(
      (prevState) => ({ showLogActionsModal: !prevState.showLogActionsModal }),
      () => {
        if (type === 'goals') {
          this.setState({
            showCreateGoal: true,
          })
        }
      }
    )
  }

  togglePlayerProgressModal = () => {
    // always begin the modal in "all" mode and reset back to 'all' when closed so the on-course journey report doesn't use filtered data
    if (this.state.playTypeSelected !== 'all') {
      this.setState(
        {
          showPlayerProgressModal: !this.state.showPlayerProgressModal,
          divisionLoader: true,
        },
        () => {
          this.props.actions.getUserDivision(this.state.userId).then((res) => {
            if (res) {
              this.setState({ playTypeSelected: 'all', divisionLoader: false })
            }
          })
        }
      )
    } else {
      this.setState({
        showPlayerProgressModal: !this.state.showPlayerProgressModal,
      })
    }
  }

  toggleJourneyModal = () => {
    this.setState({ showJourneyModal: !this.state.showJourneyModal })
  }

  toggleValidationModal = (success, message) => {
    this.setState({
      showValidationModal: true,
      validationMessage: message,
      validationSuccess: success,
    })
  }

  toggleCompleteModal = (objective, user) => {
    this.setState({
      showCompleteModal: true,
      confirmationMessage: `Do you want to complete the ${objective.name} objective for ${user.first_name} ${user.last_name}?`,
      objective: objective,
      issueTrophyFormUserId: user.id,
      issueTrophyFormObjectiveId: objective.id,
      selectedObjectiveRank: objective.level,
      removeObjective: false,
    })
  }

  toggleIncompleteModal = (objective, user) => {
    this.setState({
      showCompleteModal: true,
      confirmationMessage: `Are you sure you want to mark the ${objective.name} objective for ${user.first_name} ${user.last_name} incomplete?`,
      objective: objective,
      issueTrophyFormUserId: user.id,
      issueTrophyFormObjectiveId: objective.id,
      selectedObjectiveRank: objective.level,
      removeObjective: true,
    })
  }

  deleteActivity = (activity) => {
    // decrement page if there are no more items on it
    const minus = this.props.train_activities.length === 1 ? 1 : 0

    this.props.activity_actions.deleteActivity(activity).then(() => {
      this.props.actions.getUserStats(this.props.user.id)
      this.setState({
        trainCurrentPage: this.state.trainCurrentPage - minus,
      }, () => this.reloadTrainActivities(this.state.trainCurrentPage))
    })
  }


  closeValidationModal = () => {
    this.setState({
      showValidationModal: false,
    })
  }

  closeLogActionsModal = () => {
    this.setState(
      {
        showLogActionsModal: false,
        showCreateGoal: false,
      },
      () => this.forceUpdate()
    )
  }

  closeLogActionsModalandUpdate = () => {
    this.setState(
      {
        showLogActionsModal: false,
        showCreateGoal: false,
        divisionLoader: true,
      },
      () => {
        this.props.actions
          .getUserDivision(this.state.userId, 'formal')
          .then((res) => {
            if (res) {
              this.setState({ divisionLoader: false })
            }
          })
      }
    )
  }

  closeCompleteModal = () => {
    this.setState({
      showCompleteModal: false,
      isLevelUp: false,
    })
  }

  toggleDropdown = (rank, type) => {
    if (rank) {
      if (
        this.props.objectives[rank.order].length === 0 &&
        rank.progress_percentage !== 100
      ) {
        this.setState(
          { rowLoader: true, collapse: !this.state.collapse },
          () => {
            this.getObjectivesFromRankRow(rank.order, this.props.user.id)
          }
        )
      } else {
        this.setState({ collapse: !this.state.collapse })
      }

      if (rank.progress_percentage === 100 && type === 'complete') {
        this.setState(
          { rowLoader: true, collapse: !this.state.collapse },
          () => {
            this.getObjectivesFromRankRow(rank.order, this.props.user.id)
          }
        )
      }

      if (rank.progress_percentage === 100 && type === 'current') {
        this.setState({ collapse: !this.state.collapse, rowLoader: false })
      }
    } else {
      this.setState({ collapse: !this.state.collapse, rowLoader: false })
    }
  }

  handleRankObjectiveSelectChanged(changeEvent) {
    this.setState({
      issueTrophyFormObjectiveId: changeEvent.target.value,
    })
  }

  loadTabs(id) {
    if (id === 'four') {
      this.setState({ page: 1, goal: null })
    }
    if (!this.props.user.goals) {
      this.getUserGoals(this.props.user)
    }
    this.getUserGoals(this.props.user)
    if (id === 'four' && !this.state.gotObjectives) {
      this.getUserGoals(this.props.user)
      this.getRanksOnly(this.props.user)
    } else {
      this.forceUpdate()
    }
  }

  renderDashboardTabs(user) {
    const { current_user } = this.props
    return (
      <div className='dashboard-tab-container'>
        <Tabs
          onChange={(tabId) => this.loadTabs(tabId)}
          className='dashboard-tabs'
        >
          <TabList
            className={`dashboard-tab-list ${this.state.className} hidden-sm-down`}
          >
            <Tab tabFor='one' className='dashboard-tab'>
              <p>Dashboard</p>
            </Tab>
            <Tab tabFor='two' className='dashboard-tab'>
              <p>Play</p>
            </Tab>
            <Tab tabFor='three' className='dashboard-tab'>
              <p>Train</p>
            </Tab>
            {/* {(user.type !== "Coach" || */}
            {/* current_user.user_settings.coach_permission >= 2) && ( */}
            {this._shouldDisplayGoals(user, current_user) && (
              <Tab tabFor='four' className='dashboard-tab'>
                <p>Goals</p>
              </Tab>
            )}
            <Tab tabFor='five' className='dashboard-tab'>
              <p>Milestones</p>
            </Tab>
            {/* {this._shouldDisplayGoals(user, current_user) && (
              <Tab tabFor='six' className='dashboard-tab'>
                <p>Curriculum</p>
              </Tab>
            )} */}
          </TabList>
          <TabList
            className={`dashboard-tab-list ${this.state.className} hidden-sm-up`}
          >
            <Tab tabFor='one' className='dashboard-tab'>
              <p>Dashboard</p>
            </Tab>
            {current_user.user_settings?.coach_permission >= 2 && (
              <Tab tabFor='four' className='dashboard-tab'>
                <p>Goals</p>
              </Tab>
            )}
          </TabList>
          <TabPanel tabId='one'>
            <div className='row mt-3 mr-0'>
              <div className='col-md-5 pr-1 pr-md-0'>
                {/* <div className="card mb-2"> */}
                {/* <h5 className="p-2 ml-3 mt-2">
                    {this.state.userLoader ? "" : `${user.first_name}'s `}Journey
                  </h5> */}
                <div className='card-block'>
                  <div className='row'>
                    <div className='col-12'>
                      {this.state.activityLoader ? (
                        <div
                          className={`loading-content ${
                            this.state.activityLoader ? '' : ''
                          }`}
                        >
                          <Loader message='loading golfer activities' />
                        </div>
                      ) : (
                        this.pageActivities()
                      )}
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <div className='col-md-7 community-stats col-md-push-8 d-none d-md-block'>
                {this.displayStats(user)}
              </div>
            </div>
          </TabPanel>
          <TabPanel tabId='two'>
            <div className='row mt-3 mr-0'>
              <div className='col-md-12 pr-1 pr-md-0'>
                {this.state.playLoader ? (
                  <div
                    className={`loading-content ranks-loading ${
                      this.state.playLoader ? '' : ''
                    }`}
                  >
                    <Loader message='loading golfer play stats' />
                  </div>
                ) : (
                  this.displayPlayStats(user)
                )}
              </div>
            </div>
          </TabPanel>
          <TabPanel tabId='three'>
            <div className='row mt-3 mr-0'>
              <div className='col-md-12 pr-1 pr-md-0'>
                {this.state.trainLoader ? (
                  <div
                    className={`loading-content ranks-loading ${
                      this.state.trainLoader ? '' : ''
                    }`}
                  >
                    <Loader message='loading golfer train stats' />
                  </div>
                ) : (
                  this.displayTrainStats(user)
                )}
              </div>
            </div>
          </TabPanel>
          {user.type !== 'Coach' && (
            <TabPanel tabId='four'>
              <div className='row mt-3 mr-0'>
                <div className='col-md-12 pr-1 pr-md-0 goal-row-tab'>
                  {this.displayGoals()}
                </div>
              </div>
            </TabPanel>
          )}
          <TabPanel tabId='five'>
            <div className='row mt-3 mr-0'>
              <div className='col-md-12 pr-1 pr-md-0'>
                {this.state.divisionLoader ? (
                  <div className='col-6'>
                    <Loader message='loading milestones' />
                  </div>
                ) : (
                  <MilestonePage
                    user={this.props.user}
                    division={this.props.user_division}
                    divisionLoader={this.state.divisionLoader}
                  />
                )}
              </div>
            </div>
          </TabPanel>
          {/* <TabPanel tabId='six'>
            <div className='row mt-3 mr-0'>
              <div className='col-md-12 pr-1 pr-md-0'>
                <p>Curriculum stuff</p>
              </div>
            </div>
          </TabPanel> */}
        </Tabs>
      </div>
    )
  }

  _shouldDisplayGoals(viewUser, currentUser) {
    if (viewUser.type === 'Coach') {
      return false
    }
    if (
      currentUser.type === 'Coach' &&
      currentUser.user_settings?.coach_permission < 2
    ) {
      return false
    }
    return true
  }

  displayActivities(user) {
    return (
      <ProfileActivities user={user} allActivities={this.props.activities} />
    )
  }

  // displayTrophies(user) { // TODO: Doesn't look used
  //   return (
  //     <div className='card mt-15px'>
  //       <h5 className='p-2 text-white primary-background'>
  //         Op 36 Goal Tracking
  //       </h5>
  //       <div className='card-block p-2'>
  //         <ProfileTrophies user={user} />
  //       </div>
  //     </div>
  //   );
  // }

  completeObjective = () => {
    this.setState(
      {
        objectiveLoader: true,
      },
      () => {
        const { issueTrophyFormObjectiveId, issueTrophyFormUserId, selectedObjectiveRank } =
          this.state
        const { objective_actions } = this.props

        const data = {
          objectiveId: issueTrophyFormObjectiveId,
          userId: issueTrophyFormUserId,
        }

        objective_actions.completeObjective(data).then(() => {
          const user = this.props.user
          this.props.actions.getUserCurriculum(user.id).then(() => {
            this.setState(
              {
                rowLoader: true,
              },
              () => {
                this.getUserRanks(user, selectedObjectiveRank)
              }
            )
          })

          this.setState(
            {
              objectiveLoader: false,
            },
            () => {
              this.props.actions.getUserStats(this.props.user.id)
              this.props.actions.getUserActivities(this.props.user.id)
              this.closeCompleteModal()
              this.toggleValidationModal(
                true,
                'You have successfully completed the objective'
              )
              if (this.state.showLogActionsModal) {
                this.closeLogActionsModal()
              }
            }
          )
        })
      }
    )
  }

  incompleteObjective = () => {
    this.setState(
      {
        objectiveLoader: true,
      },
      () => {
        const { issueTrophyFormObjectiveId, issueTrophyFormUserId, selectedObjectiveRank } = this.state
        const { objective_actions } = this.props

        const data = {
          objectiveId: issueTrophyFormObjectiveId,
          userId: issueTrophyFormUserId,
        }

        objective_actions.removeObjective(data).then(() => {
          const user = this.props.user
          this.props.actions.getUserCurriculum(user.id).then(() => {
            this.setState(
              {
                rowLoader: true,
              },
              () => {
                this.getUserRanks(user, selectedObjectiveRank)
              }
            )
          })

          this.setState(
            {
              objectiveLoader: false,
            },
            () => {
              this.props.actions.getUserStats(this.props.user.id)
              this.props.actions.getUserActivities(this.props.user.id)
              this.closeCompleteModal()
              this.toggleValidationModal(
                true,
                'You have successfully removed the objective'
              )
              if (this.state.showLogActionsModal) {
                this.closeLogActionsModal()
              }
            }
          )
        })
      }
    )
  }

  getCurrentRank = () => {
    this.props.ranks.map((rank) => {
      if (rank.id === this.props.user.user_curriculum.rank_id) {
        this.setState({
          currentRank: rank,
          percentage: rank.progress_percentage,
          completedCount:
            rank.objectives.length - this.state.incompleteObjectives.length,
        })
      } else {
        return null
      }
    })
  }

  startPage = () => {
    this.setState({
      page: 1,
    })
  }

  getCustomGoalPage = (goal) => {
    this.props.user.goals.forEach((newGoal) => {
      if (goal.id === newGoal.id) {
        this.setState({
          page: 4,
          goal: newGoal,
        })
      }
    })
  }

  incrementPage = () => {
    this.setState({
      page: this.state.page + 1,
    })
  }

  decrementPage = () => {
    this.setState({
      page: this.state.page - 1,
    })
  }

  getCurrentObjective = (objective) => {
    this.incrementPage()
    this.setState({
      currentObjective: objective,
    })
  }

  displayGoals(_user) {
    return (
      <GoalFlow
        user={this.props.user}
        startPage={this.startPage}
        goal={this.state.goal}
        page={this.state.page}
        getCustomGoalPage={this.getCustomGoalPage}
        incrementPage={this.incrementPage}
        decrementPage={this.decrementPage}
        toggleCompleteModal={this.toggleCompleteModal}
        toggleIncompleteModal={this.toggleIncompleteModal}
        toggleRowLoader={this.toggleRowLoader}
        toggleDropdown={this.toggleDropdown}
        collapse={this.state.collapse}
        currentObjective={this.state.currentObjective}
        getCurrentObjective={this.getCurrentObjective}
        current_user={this.props.current_user}
        ranks={this.state.ranks}
        rankUp={this.props.progressRankForUser}
        levelUpLoader={this.state.levelUpLoader}
        rowLoader={this.state.rowLoader}
        loader={this.state.objectiveLoader}
        objectives={this.state.objectives}
        gotInitialGoals={this.state.gotInitialGoals}
        getObjectivesFromRank={this.getObjectivesFromRank}
        getObjectivesFromRankRow={this.getObjectivesFromRankRow}
        percentage={this.state.percentage}
        completedCount={this.state.completedCount}
        currentRank={this.state.currentRank}
        gotGoals={this.state.gotGoals}
        goals={this.state.goals}
        subjects={this.props.subjects}
        updateGoal={this.updateGoal}
        reviewTask={this.reviewTask}
        getCurrentRank={this.getCurrentRank}
        reviewLoader={this.state.reviewLoader}
        userActions={this.props.actions}
        incompleteObjectives={this.state.incompleteObjectives}
        objectiveActivities={this.props.objective_activities}
        toggleModal={this.toggleLogActionsModal}
        deleteItem={this.deleteItem}
        uploadingPhoto={this.state.uploadingPhoto}
        ranksLoader={this.state.ranksLoader}
      />
    )
  }

  displayStats(user) {
    return (
      <div className='dashboard-stats'>
        <ProfileStats
          user={user}
          loading={this.state.statsLoader}
          routeId={this.state.userId}
          statsLoader={this.state.statsLoader}
          allActivities={this.props.activities}
          playActivities={this.props.play_activities}
          trainActivities={this.props.train_activities}
          goalActivities={this.props.goal_activities}
          stats={this.props.user_stats}
          ranks={this.props.ranks}
          objectiveActivities={this.props.objective_activities}
        />
      </div>
    )
  }

  displayTrainStats(user) {
    return (
      <TrainStats
        user={user}
        current_user={this.props.current_user.type}
        allActivities={this.props.activities}
        trainActivities={this.props.train_activities}
        stats={this.props.user_stats}
        practiceTypes={this.props.practice_types}
        userActions={this.props.actions}
        activityActions={this.props.activity_actions}
        trainLoader={this.state.trainLoader}
        deleteActivity={this.deleteActivity}
        reloadTrainActivities={this.reloadTrainActivities}
        trainActivitiesPages={this.state.trainActivitiesPages}
        trainCurrentPage={this.state.trainCurrentPage}
        handlePaginateClick={this.handlePaginateClick}
      />
    )
  }

  displayPlayStats(user) {
    return (
      <PlayStats
        user={user}
        current_user={this.props.current_user.type}
        allActivities={this.props.activities}
        playActivities={this.props.play_activities}
        activityActions={this.props.activity_actions}
        userActions={this.props.actions}
        scrolled={this.state.scrolled}
        stats={this.props.user_stats}
        playTypes={this.props.play_types}
        playLoader={this.state.playLoader}
        activityAdded={this.state.activityAdded}
        reloadStats={this.reloadStats}
      />
    )
  }

  displayProfile(user) {
    if (user) {
      return (
        // eslint-disable-next-line react/no-string-refs
        <div ref='profilePage' className='animated fadeIn' id='profile-body'>
          <div className={this.state.className}>
            <ProfileHeader
              loading={this.state.userGoalLoader}
              userLoader={this.state.userLoader}
              statsLoader={this.state.statsLoader}
              divisionLoader={this.state.divisionLoader}
              id='profilePage'
              getUserRanks={this.getUserRanks}
              current_user={this.props.current_user}
              currentEnrollment={this.state.currentEnrollment}
              wrapperClass={'profileHeader'}
              // showTopLinks={ // Doesn't have this property
              //   this.props.params.userId ===
              //   this.props.location.state.userId
              // }
              user={user}
              stats={this.props.user_stats}
              userActivities={this.props.activities}
              objectives={this.props.objectives[this.state.rankId]}
              objectiveActions={this.props.objective_actions}
              handleRankObjectiveSelectChanged={
                this.handleRankObjectiveSelectChanged
              }
              playTypes={this.props.play_types}
              practiceTypes={this.props.practice_types}
              incompleteObjectives={this.state.incompleteObjectives}
              isRankCompleted={this.state.isRankCompleted}
              toggleLogActionsModal={this.toggleLogActionsModal}
              showLogActionsModal={this.state.showLogActionsModal}
              closeLogActionsModal={this.closeLogActionsModal}
              userActions={this.props.actions}
              courses={this.props.courses}
              togglePlayerProgressModal={this.togglePlayerProgressModal}
              toggleJourneyModal={this.toggleJourneyModal}
              division={this.props.user_division}
            />
          </div>
          <div
            className={`profile-sticky-header-container ${this.state.stickyClassName}`}
            style={{
              display: this.state.stickyClassName === '' ? 'none' : 'block',
            }}
          >
            <ProfileStickyHeader
              userLoader={this.state.userLoader}
              divisionLoader={this.state.divisionLoader}
              division={this.props.user_division}
              togglePlayerProgressModal={this.togglePlayerProgressModal}
              user={user}
              current_user={this.props.current_user}
              toggleLogActionsModal={this.toggleLogActionsModal}
              showLogActionsModal={this.state.showLogActionsModal}
              closeLogActionsModal={this.closeLogActionsModal}
              userActions={this.props.actions}
              courses={this.props.courses}
              previousLocations={this.props.location}
            />
          </div>

          {this.renderDashboardTabs(user)}
        </div>
      )
    } else if (
      !user &&
      this.props.activities === undefined &&
      this.props.user_stats === undefined
    ) {
      return (
        <div className='row'>
          <div className='col-12'>
            <div className='loading-content goal-loading '>
              <Loader message='loading golfer profile' />
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className='row'>
          <div className='col-12'>
            <div className='loading-content goal-loading '>
              <Loader message='loading golfer profile' />
            </div>
          </div>
        </div>
      )
    }
  }

  handlePlayTypeChange = (type) => {
    // handles switching play type for player progress modal - not the stats
    if (type !== this.state.playTypeSelected) {
      this.setState({ divisionLoader: true, playTypeSelected: type }, () => {
        this.props.actions.getUserDivision(this.state.userId, type).then(() => {
          this.setState({ divisionLoader: false })
        })
      })
    }
  }

  _handleGoalChange = (id, track) => {
    this.props.actions.updateGoalTrack(id, track)
  }

  closeGolferModal = () => {
    this.setState({ showGolferModal: false })
  }

  render() {
    const propsCopy = { ...this.props }
    return (
      <div id='profilePage'>
        {this.displayProfile(propsCopy.user)}
        {propsCopy.user && (
          <LogActionsModal
            loading={this.state.userGoalLoader}
            toggleValidationModal={this.toggleValidationModal}
            toggleLogActionsModal={this.toggleLogActionsModal}
            closeLogActionsModal={this.closeLogActionsModal}
            closeLogActionsModalandUpdate={this.closeLogActionsModalandUpdate}
            showLogActionsModal={this.state.showLogActionsModal}
            user={this.props.user}
            current_user={this.props.current_user}
            issueTrophyFormUserId={this.state.userId}
            currentEnrollment={this.state.currentEnrollment}
            isRankCompleted={this.state.isRankCompleted}
            fullName={this.state.fullName}
            playTypes={this.props.play_types}
            practiceTypes={this.props.practice_types}
            getUserRanks={this.getUserRanks}
            objectives={this.props.objectives[this.state.rankId]}
            gotObjectives={this.state.gotObjectives}
            subjects={this.props.subjects}
            getUserGoals={this.getUserGoals}
            getSubjects={this.getSubjects}
            gotSubjects={this.state.gotSubjects}
            objectiveActions={this.props.objective_actions}
            incompleteObjectives={this.state.incompleteObjectives}
            issueTrophyFormObjectiveId={this.state.issueTrophyFormObjectiveId}
            handleRankObjectiveSelectChanged={
              this.handleRankObjectiveSelectChanged
            }
            progressRankForUser={this.progressRankForUser}
            checkIsRankCompleted={this.checkIsRankCompleted}
            submitIssueTrophyForm={this.submitIssueTrophyForm}
            completeObjective={this.completeObjective}
            userLoader={this.state.userLoader}
            showCreateGoal={this.state.showCreateGoal}
            trainCurrentPage={this.state.trainCurrentPage}
            perPage={this.state.perPage}
            setTrainActivitiesPages={this.setTrainActivitiesPages}
            setActivityAdded={this.setActivityAdded}
          />
        )}

        {propsCopy.user && (
          <ConfirmationModal
            showConfirmationModal={this.state.showCompleteModal}
            closeConfirmationModal={this.closeCompleteModal}
            confirmationMessage={this.state.confirmationMessage}
            confirmationDenied={this.closeCompleteModal}
            confirmationProceed={
              this.state.removeObjective
                ? this.incompleteObjective
                : this.completeObjective
            }
            loader={this.state.objectiveLoader}
          />
        )}
        {propsCopy.user && (
          <ValidationModal
            validationSuccess={this.state.validationSuccess}
            showValidationModal={this.state.showValidationModal}
            closeValidationModal={this.closeValidationModal}
            validationMessage={this.state.validationMessage}
          />
        )}
        {propsCopy.user && (
          <PlayerProgressModal
            showPlayerProgressModal={this.state.showPlayerProgressModal}
            closePlayerProgressModal={this.togglePlayerProgressModal}
            user={this.props.user}
            title={'Junior Development Model (Beta)'}
            studentSelected={this.props.user.id}
            division={this.props.user_division}
            loader={this.state.divisionLoader}
            handlePlayTypeChange={this.handlePlayTypeChange}
            playType={this.state.playTypeSelected}
            fromProfile={true}
            handleGoalChange={this._handleGoalChange}
          />
        )}
        {propsCopy.user && (
          <StudentRoundsModal
            showReportModal={this.state.showJourneyModal}
            closeReportModal={this.toggleJourneyModal}
            user={this.props.user}
            division={this.props.user_division}
          />
        )}
        {propsCopy.user && (
          <EditGolferModal />
        )}
      </div>
    )
  }
}

ProfilePage.propTypes = {
  objectives: PropTypes.object,
  user: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    goals: PropTypes.array,
    created_at: PropTypes.string,
    user_curriculums: PropTypes.array,
    user_curriculum: PropTypes.shape({
      rank_id: PropTypes.number,
    }),
    current_course_id: PropTypes.number,
  }),
  user_curriculum: PropTypes.shape({
    rank_id: PropTypes.number,
  }),
  actions: PropTypes.shape({
    getUserActivities: PropTypes.func,
    getUserTrainActivities: PropTypes.func,
    getUserPlayActivities: PropTypes.func,
    getUserDivision: PropTypes.func,
    progressRank: PropTypes.func,
    getUserCurriculum: PropTypes.func,
    updateGoalTrack: PropTypes.func,
    getUser: PropTypes.func,
    getUserGoals: PropTypes.func,
    getUserStats: PropTypes.func,
    getUserObjectiveActivities: PropTypes.func,
  }),
  common_actions: PropTypes.shape({
    setCurrentTab: PropTypes.func,
    showSideBar: PropTypes.func,
    showNavBar: PropTypes.func,
  }),
  activities: PropTypes.array,
  location: PropTypes.object,
  current_user: PropTypes.shape({
    user_settings: PropTypes.shape({
      coach_permission: PropTypes.number,
    }),
    type: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    id: PropTypes.number,
  }),
  params: PropTypes.shape({
    userId: PropTypes.string,
  }),
  user_division: PropTypes.shape({
    current_division: PropTypes.any,
  }),
  courses: PropTypes.array,
  objective_actions: PropTypes.shape({
    completeObjective: PropTypes.func,
    removeObjective: PropTypes.func,
    getRankObjectives: PropTypes.func,
  }),
  activity_actions: PropTypes.shape({
    deleteActivity: PropTypes.func,
    postActivityRank: PropTypes.func,
  }),
  goal_actions: PropTypes.shape({
    deleteGoal: PropTypes.func,
    deleteGoalReview: PropTypes.func,
    addGoalReview: PropTypes.func,
    updateGoal: PropTypes.func,
  }),
  subject_actions: PropTypes.shape({
    getAllSubjects: PropTypes.func,
  }),
  train_activities: PropTypes.array,
  play_types: PropTypes.array,
  play_activities: PropTypes.array,
  goal_activities: PropTypes.array,
  objective_activities: PropTypes.array,
  practice_types: PropTypes.array,
  subjects: PropTypes.array,
  ranks: PropTypes.array,
  user_stats: PropTypes.shape({}),
  navigate: PropTypes.func,
  rank_actions: PropTypes.shape({
    getCurriculumRanks: PropTypes.func,
  }),
  progressRankForUser: PropTypes.bool,
}


function mapStateToProps(state, _ownProps) {
  return {
    ui: state.ui,
    user: state.user.user,
    user_stats: state.user.user_stats,
    current_user: state.user.current_user,
    current_enrollment: state.current_enrollment,
    courses: state.courses.courses,
    activities: state.user.user_activities,
    goals: state.user.user_goals,
    subjects: state.subjects,
    all_activities: state.activities,
    play_activities: state.user.user_play_activities,
    goal_activities: state.user.user_goal_activities,
    train_activities: state.user.user_train_activities,
    objective_activities: state.user.user_objective_activities,
    objectives: state.objectives,
    ranks: state.ranks,
    play_types: state.playTypes,
    practice_types: state.practiceTypes,
    user_division: state.userDivision.user_division,
    division_data: state.userDivision.division_data,
    user_curriculum: state.user.user_curriculum,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(userActions, dispatch),
    common_actions: bindActionCreators(commonActions, dispatch),
    activity_actions: bindActionCreators(activityActions, dispatch),
    play_type_actions: bindActionCreators(playTypeActions, dispatch),
    practice_type_actions: bindActionCreators(practiceTypeActions, dispatch),
    objective_actions: bindActionCreators(objectiveActions, dispatch),
    rank_actions: bindActionCreators(rankActions, dispatch),
    goal_actions: bindActionCreators(goalActions, dispatch),
    subject_actions: bindActionCreators(subjectActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouting(ProfilePage))
