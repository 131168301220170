import React from 'react';
import PropTypes from 'prop-types'

import './CurriculumPreviewCard.scss';

class CurriculumPreviewCard extends React.Component {
  state = {
    hovering: false
  };
  render() {
    const {
      subject,
      triggerDetailModal,
      blockIndex,
      taskIndex,
      moveTask
    } = this.props;
    return (
      <div
        id='CurriculumPreviewCard'
        onMouseEnter={this._startHover}
        onMouseOver={this.state.hovering ? (() => {}) : this._startHover}
        onMouseLeave={this._endHover}
        onClick={() => triggerDetailModal(subject.id)}
      >
        {this.props.active && this.state.hovering ? (
          <div className='grey-out'>
            <div className='task-nav-buttons-container'>
              <i
                className='fa fa-sort-asc'
                aria-hidden='true'
                onClick={e => moveTask(e, -1, taskIndex, blockIndex)}
              />
              <i
                className='fa fa-sort-desc'
                aria-hidden='true'
                onClick={e => moveTask(e, 1, taskIndex, blockIndex)}
              />
            </div>
            <div className='task-play-button-container'>
              <i className='fa fa-play' aria-hidden='true' />
            </div>
            <div className='task-delete-container'>
              <i
                className='fa fa-trash-o'
                aria-hidden='true'
                onClick={e =>
                  this.props.removeActive(
                    e,
                    this.props.blockIndex,
                    this.props.taskIndex
                  )
                }
              />
            </div>
          </div>
        ) : null}
        <div
          className='curriculum-preview-photo-area'
          style={{ backgroundImage: `url(${subject.photo.file_url || subject.photo.fileUrl})` }}
        >
          <div
            className='curriculum-preview-icon'
            style={{ backgroundImage: `url(${subject.icon.file_url || subject.icon.fileUrl})` }}
          />
        </div>
        <div className='curriculum-preview-titlebar'>
          <p className='curriculum-preview-title'>{subject.name}</p>
          <p className='curriculum-preview-label'>Curriculum</p>
        </div>
        <div className='curriculum-preview-footer'>
          <p>{`Operation 36 Curriculum ${subject.name} - all 6 Levels`}</p>
        </div>
      </div>
    );
  }

  _startHover = () => {
    this.setState({ hovering: true });
  };

  _endHover = () => {
    this.setState({ hovering: false });
  };
}

CurriculumPreviewCard.propTypes = {
  subject: PropTypes.shape({
    name: PropTypes.string,
  }),
}

export default CurriculumPreviewCard;
