import moment from 'moment/moment'
import classnames from 'classnames'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'

import { deserialize } from '../../../../utility/serialization'
import { requestGetProgramEnrollmentsSummary } from '../../../../actions/programActions'
import LeaderboardWrapper from '../../../common/leaderboard/LeaderboardWrapper'

import classes from './StatsPanel.module.scss'
import { ReactComponent as GolferHalfIcon } from '../../../../assets/images/common/icons/golfer-half-icon.svg'
import BusinessSummaryWidget from './BusinessSummaryWidget'
import StudentInformationWidget from './StudentInformationWidget'

const StatsPanel = () => {
  const { program } = useSelector(state => state.programDashboard)

  const [enrollmentsSummary, setEnrollmentsSummary] = useState(null)
  const [isLoadingSummary, setIsLoadingSummary] = useState(true)

  const isFutureStartDate = moment(program?.startDate)
    .isAfter(moment(), 'day')

  const filters = useMemo(() => ({
    startDate: isFutureStartDate ? null : program?.startDate,
    endDate: isFutureStartDate ? new Date() : program?.endDate,
    courseId: program?.id,
  }), [isFutureStartDate, program?.endDate, program?.id, program?.startDate])

  useEffect(() => {
    requestGetProgramEnrollmentsSummary(program?.id)
      .then(response => {
        setEnrollmentsSummary(deserialize(response.data).enrollmentsSummary)
        setIsLoadingSummary(false)
      })
      .catch(() => toast.error('Something went wrong. Please try again.', { position: toast.POSITION.TOP_RIGHT }))
  }, [program?.id])

  return (
    <div className={classnames('h-100 p-3', classes.statsPanel)}>
      <div className='h-100 d-flex mx-auto w-fit-content'>
        <div className={classnames('h-100 p-3 d-flex flex-column', classes.leaderboardWidget)}>
          <div className={classnames('d-flex justify-content-between align-items-center', classes.header)}>
            <h3 className={classes.title}>{isFutureStartDate ? 'Students Enrolled' : 'Program Activity Leaderboard'}</h3>
            <span className={classes.timePeriodDetails}>
              {isFutureStartDate
                ? '(All Time Stats)'
                : `${moment(program?.startDate)
                  .format('MM/DD/YY')} - ${moment(program?.endDate)
                  .format('MM/DD/YY')}`}
            </span>
          </div>
          {program?.enrollments.length === 0 ? (
            <div className={classnames('d-flex flex-column align-items-center', classes.placeholder, classes.body)}>
              <GolferHalfIcon className={classes.icon} />
              <h2 className={classes.title}>No Enrollments Yet</h2>
              <p className={classes.text}>Share & Market your Program to your community!</p>
            </div>
          ) : (
            <LeaderboardWrapper
              withDetailedLeaderboard={!isFutureStartDate}
              filters={filters}
              className={classnames('mt-3', classes.body)}
            />
          )}
        </div>
        <div className={classnames('ml-4 mb-3 d-flex flex-column', classes.summarySection)}>
          <BusinessSummaryWidget
            isLoading={isLoadingSummary}
            businessSummary={enrollmentsSummary?.businessSummary}
            packages={enrollmentsSummary?.packages}
          />
          <StudentInformationWidget
            isLoading={isLoadingSummary}
            studentsInfo={enrollmentsSummary?.studentsInfoSummary}
          />
        </div>
      </div>
    </div>
  )
}

export default StatsPanel
