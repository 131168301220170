import LoadingSpinner from '../../../common/LoadingSpinner'
import classnames from 'classnames'

import classes from './StatsPanel.module.scss'
import globals from '../../../../assets/styles/globals.scss'
import { Pie } from 'react-chartjs-2'
import { numbersToPercentage } from '../../../../utility/numbersHelper'
import PropTypes from 'prop-types'

const StudentInformationWidget = ({ isLoading, studentsInfo }) => {
  const withStudents = studentsInfo?.totalStudents > 0

  if (isLoading) {
    return (
      <div className={classnames('mt-3', classes.businessSummary)}>
        <LoadingSpinner
          text='Loading Business Summary...'
          classNames={{
            wrapper: 'h-100 pt-0',
            spinner: 'fa-3x',
          }}
        />
      </div>
    )
  }

  return (
    <div className={classnames('my-3 p-3 d-flex flex-column', classes.businessSummary)}>
      <h3 className={classnames('mb-3', classes.title)}>Student Information</h3>
      <span className={classes.subtitle}>Age Range</span>
      <span className={classes.title}>
        {withStudents ? `${studentsInfo?.minimumAge} to {studentsInfo?.maximumAge} year olds` : '----'}
      </span>
      <div className={classnames('mt-3 d-flex w-100')}>
        {withStudents ? (
          <Pie
            className={classes.chart}
            options={{
              animation: { animateRotate: false },
              plugins: {
                tooltip: { enabled: false },
                legend: { display: false },
              },
              maintainAspectRatio: true,
            }}
            data={{
              datasets: [
                {
                  data: [
                    studentsInfo?.maleStudents,
                    studentsInfo?.femaleStudents,
                    studentsInfo?.otherStudents,
                  ],
                  backgroundColor: [
                    globals.darkBlue,
                    globals.primaryOrange,
                    globals.darkGray,
                  ],
                },
              ],
            }}
          />
        ) : (
          <div className={classnames(classes.chart, classes.placeholder)} />
        )}
        <div className='ml-3 d-flex flex-column justify-content-around flex-grow-1'>
          <div className={classnames('d-flex justify-content-between', classes.statsItem)}>
            <div className='d-flex align-items-center'>
              <div className={classnames('mr-3', classes.dot, classes.blue)} />
              Male
            </div>
            <span>{withStudents ? numbersToPercentage(studentsInfo?.maleStudents, studentsInfo?.totalStudents) : '0%'}</span>
          </div>
          <div className={classnames('d-flex justify-content-between', classes.statsItem)}>
            <div className='d-flex align-items-center'>
              <div className={classnames('mr-3', classes.dot, classes.orange)} />
              Female
            </div>
            <span>{withStudents > 0 ? numbersToPercentage(studentsInfo?.femaleStudents, studentsInfo?.totalStudents) : '0%'}</span>
          </div>
          <div className={classnames('d-flex justify-content-between', classes.statsItem)}>
            <div className='d-flex align-items-center'>
              <div className={classnames('mr-3', classes.dot, classes.gray)} />
              Other
            </div>
            <span>{withStudents > 0 ? numbersToPercentage(studentsInfo?.otherStudents, studentsInfo?.totalStudents) : '0%'}</span>
          </div>
        </div>
      </div>
      <div className={classnames('my-3 d-flex justify-content-between', classes.statsItem)}>
        <span>Average Age</span>
        <span>
          {withStudents ? studentsInfo?.averageAge : '---'}
        </span>
      </div>
      <div className={classnames('mb-3 d-flex justify-content-between', classes.statsItem)}>
        <span>Skill Level Range</span>
        <span>
          {withStudents ? `L${studentsInfo?.minimumDivisionLevel} - L${studentsInfo?.maximumDivisionLevel}` : '---'}
        </span>
      </div>
    </div>
  )
}

StudentInformationWidget.propTypes = {
  isLoading: PropTypes.bool,
  studentsInfo: PropTypes.object.isRequired,
}

export default StudentInformationWidget
