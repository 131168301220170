import { useSelector } from 'react-redux'
import SessionsList from '../../../../schedules/SessionsList'

const ProgramSchedule = () => {
  const course = useSelector(state => state.programPresentation.course)

  return (
    <SessionsList
      schedule={course.schedule}
      timezone={course.community.timezone}
      showPastSessions
      className='pb-1 px-lg-5'
    />
  )
}

export default ProgramSchedule
