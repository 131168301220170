import * as types from "../actions/actionTypes";
import update from "immutability-helper";
import { saveState } from '../store/localStorage';

// eslint-disable-next-line complexity
const coursesReducer = (courses = [], action) => {
  var currentCourseIndex = null;
  var currentCourse = null;
  var currentEnrollmentIndex = null;
  var enrollments = null;
  let new_courses = [courses.courses];
  var sessions = [];

  switch (action.type) {
    case types.ADD_COURSES:
      return { ...courses, courses: action.courses };

    case types.APPROVE_ENROLLMENT:
      // currentCourseIndex = courses.map(function(e) {return e.id}).indexOf(action.enrollment.course_id);
      // currentCourse = courses[currentCourseIndex];
      // currentEnrollmentIndex = currentCourse.enrollments.map(function(e) {return e.id}).indexOf(action.enrollment.id);

      // enrollments = courses[currentCourseIndex].enrollments.slice(0); // Create a copy of the courses array
      // enrollments[currentEnrollmentIndex] = action.enrollment;

      // return update(courses, {
      //   [currentCourseIndex]: {
      //     enrollments: {$set: enrollments}
      //   }
      // });
      return Object.assign({}, courses, { course: action.course });

    case types.DENY_ENROLLMENT:
      currentCourseIndex = courses
        .map(function(e) {
          return e.id;
        })
        .indexOf(action.courseId);
      currentCourse = courses.courses[currentCourseIndex];
      currentEnrollmentIndex = currentCourse.enrollments
        .map(function(e) {
          return e.id;
        })
        .indexOf(action.enrollmentId);

      enrollments = courses[currentCourseIndex].enrollments.slice(0); // Create a copy of the courses array
      enrollments.splice(currentEnrollmentIndex, 1);

      return update(courses, {
        [currentCourseIndex]: {
          enrollments: { $set: enrollments }
        }
      });

    case types.UPDATE_COURSE:
      // currentCourseIndex =  new_courses[0].findIndex(course => course.id == action.course.id);
      currentCourse = courses.course;
      let newCourse = action.course;
      newCourse.enrollments = currentCourse.enrollments;
      return update(courses.course, {
        [currentCourse.id]: { $set: newCourse }
      });
    //  currentCourseIndex = new_courses.map(function(e) {return e.id}).indexOf(action.course.id);
    // return update(new_courses, {
    //   [currentCourseIndex]: {$set: action.course}
    // });

    case types.UPDATE_COURSE_ATTRIBUTES:
      return {
        ...courses,
        course: {...action.course, schedule: { ...courses.course?.schedule }}
      }

    case types.GET_COURSE:
      return Object.assign({}, courses, { course: action.course });
    case types.REMOVE_STUDENTS:
      return Object.assign({}, courses, { course: action.course });

    case types.GET_COURSE_STUDENTS:
      return Object.assign({}, courses, {
        students: action.students,
        sessions: courses.sessions
      });
    case types.GET_COURSE_SESSIONS:
      return Object.assign({}, courses, { sessions: action.sessions });
    case types.CREATE_COURSE_SESSION:
      sessions = courses.sessions;
      sessions = sessions.filter(session => session.id !== action.session.id);
      return {
        ...courses,
        sessions: [...courses.sessions, Object.assign({}, action.session)]
      };
    case types.DELETE_COURSE_SESSION:
      return {
        ...courses,
        sessions: courses.sessions.filter(
          session => Object.assign({}, session).id !== action.session.id
        )
      };
    case types.UPDATE_COURSE_SESSION:
      return {
        ...courses,
        sessions: courses.sessions.map(oldSession => {
          if (oldSession.id !== action.session.id) {
            return oldSession;
          }
          return { ...oldSession, ...action.session };
        })
      };
    case types.CREATE_COURSE:
      return {
        ...courses,
        courses: [...courses.courses, Object.assign({}, action.course)]
      };

    case types.ADD_USER_COURSES:
      if (action.pagination?.page > 1) {
        return {
          ...courses,
          courses: [...courses.courses, ...action.courses],
          pagination: action.pagination,
        }
      } else {
        return {
          ...courses,
          courses: action.courses,
          pagination: action.pagination,
        }
      }
    case types.ADD_USER_COMPLETED_COURSES:
      return { ...courses, completed_courses: action.courses };
    case types.ADD_USER_ARCHIVED_COURSES:
      return { ...courses, archived_courses: action.courses };
    case types.ADD_USER_TEMPLATE_COURSES:
      return { ...courses, template_courses: action.courses };
    case types.ADD_USER_FEATURED_COURSES:
      return { ...courses, featured_courses: action.courses };

    case types.CREATE_COURSE_SESSION_TASK:
      return {
        ...courses,
        sessions: courses.sessions.map(oldSession => {
          if (oldSession.id !== action.sessionTask.session_id) {
            return oldSession;
          }
          return {
            ...oldSession,
            sessions_tasks: Object.assign({}, action.sessionTask)
          };
        })
      };

    case types.UPDATE_COURSE_SESSION_TASK:
      return {
        ...courses,
        sessions: courses.sessions.map(oldSession => {
          if (oldSession.id !== action.session_task.session_id) {
            return oldSession;
          }
          return {
            ...oldSession,
            sessions_tasks: oldSession.session_tasks.map(oldSessionTask => {
              if (oldSessionTask.id !== action.session_task.id) {
                return oldSessionTask;
              }
              return Object.assign({}, oldSessionTask, action.session_task);
            })
          };
        })
      };

    default:
      return courses;
  }
}

export default coursesReducer
