import moment from 'moment-timezone'
import classnames from 'classnames'
import { forwardRef, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetCurrentDetailTab, setCurrentDetailTab } from '../../../../reducers/programPresentationReducer'

import ButtonLink from '../../../common/buttons/ButtonLink'
import ProgramPresentationHeader from './ProgramPresentationHeader'
import ProgramDescription from './PresentationTabs/ProgramDescription'
import ProgramSchedule from './PresentationTabs/ProgramSchedule'
import ProgramPricing from './PresentationTabs/ProgramPricing'
import SeriesSchedule from './PresentationTabs/SeriesSchedule'
import ProgramActionsCard from './ProgramActionsCard'

import styles from './ProgramPresentationPage.module.scss'
import { ReactComponent as InfoCircleIcon } from '../../../../assets/images/common/icons/info-circle-icon.svg'
import { ReactComponent as EditIcon } from '../../../../assets/images/common/icons/edit-icon.svg'

export const RegisterButton = () => {
  const course = useSelector(state => state.programPresentation.course)

  const registrationClosedMessage = useMemo(
    () => {
      switch (course.registrationClosedReason) {
        case 'limit_reached':
          return 'The golfer limit has been reached for this class.'
        case 'registration_closed':
          return 'Registration is open between ' +
            `${moment(course?.registrationStartsAt)
              .tz(course?.community?.timezone)
              .format('L LT')} and ` +
            `${moment(course?.registrationEndsAt)
              .tz(course?.community?.timezone)
              .format('L LT')}`
        default:
          return ''
      }
    }, [course]
  )

  return (
    course?.registrationClosedReason ? (
      <div className={classnames('w-100 d-flex justify-content-center align-items-center', styles['registration-closed-placeholder'])}>
        <InfoCircleIcon className={styles['info-circle-icon']} />
        <p className={styles['registration-closed-message']}>
          {registrationClosedMessage}
        </p>
      </div>
    ) : (
      <ButtonLink navigateTo='register' noTopMargin aria-hidden='true' onClick={e => course.previewMode && e.preventDefault()}>
        REGISTER NOW
      </ButtonLink>
    )
  )
}

const ProgramSectionSelector = forwardRef((_, ref) => {
  const currentDetailTab = useSelector(state => state.programPresentation.currentDetailTab)

  return (
    <div ref={ref} className={styles['program-presentation-section']}>
      {currentDetailTab === 'Description' && <ProgramDescription />
        || currentDetailTab === 'Schedule' && <ProgramSchedule />
        || currentDetailTab === '9 Hole Events' && <SeriesSchedule />
        || currentDetailTab === 'Pricing' && <ProgramPricing />}
    </div>
  )
})

ProgramSectionSelector.displayName = 'ProgramSectionSelector'

const ProgramPresentationPage = () => {
  const dispatch = useDispatch()

  const user = useSelector(state => state.user.current_user)
  const { course, detailsTabs, currentDetailTab } = useSelector(state => state.programPresentation)

  const ref = useRef(null)

  const editVisible = user && user.type === 'Coach' && user.communities[0].id === course.community.id && !course.previewMode

  useEffect(() => () => {
    dispatch(resetCurrentDetailTab())
  }, [dispatch])

  const handleTabButtonClick = (newTab) => {
    if (newTab !== currentDetailTab) {
      dispatch(setCurrentDetailTab(newTab))
      ref.current.scrollTo(0, 0)
    }
  }

  return (
    <div className={
      classnames(
        'd-flex flex-grow-1 flex-column',
        styles['program-presentation-page'],
        { [styles['preview-mode']]: course.previewMode }
      )
    }>
      <ProgramPresentationHeader className='d-none d-lg-flex' />
      <div className={styles['details-tab-bar-wrapper']}>
        <ul className={classnames('h-100 p-0 d-flex', styles['details-tab-bar'])}>
          {detailsTabs.map(detailTab => (
            <li key={detailTab} className={styles['detail-tab']}>
              <button
                className={classnames(styles['detail-tab-button'], { [styles['current-tab']]: currentDetailTab === detailTab })}
                onClick={() => handleTabButtonClick(detailTab)}
              >
                {detailTab}
              </button>
            </li>
          ))
          }
        </ul>
      </div>
      <ProgramSectionSelector ref={ref} />

      <div className={classnames('d-none d-lg-flex justify-content-center', styles['actions-card-wrapper'], { [styles.coachLayout]: editVisible })}>
        <div className={classnames('d-flex flex-column align-items-end', styles.actionsWrapper)}>
          {editVisible && (
            <ButtonLink navigateTo={`/programs/${course.id}/edit`} className={classnames('mb-2 px-3', styles.editLink)}>
              <div className='d-flex align-items-center'>
                <span className='mr-2 font-weight-bold'>Edit Program</span>
                <EditIcon aria-hidden='true' className={styles.editIcon} />
              </div>
            </ButtonLink>
          )}

          <ProgramActionsCard className={classnames('d-flex', styles['actions-card'])} />
        </div>
      </div>
      <div className={classnames('d-flex flex-column d-lg-none justify-content-center', styles['program-presentation-footer'])}>
        <RegisterButton />
        <ButtonLink navigateTo={`/landing/${course.community.id}`} linkColor='dark-navy'>
          Go Back
        </ButtonLink>
      </div>
    </div>
  )
}

export default ProgramPresentationPage
