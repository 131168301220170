import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { setEditingUserError, setEditingUserField, editingUserFormData } from '../../../../reducers/signUpReducer'
import { isValidEmail, isValidUsername } from '../../../../utility/helperFunctions'
import StepButton from './Helpers/StepButton'
import AvatarInput from './Helpers/AvatarInput'
import FormInput from './Helpers/FormInput'

import classes from './NewMemberLogInData.module.scss'

const customErrorMessages = {
  email: 'Your email address is not valid.',
  username: 'Your username is not valid.',
  password: 'Password must have at least 6 characters',
  passwordConfirmation: 'Passwords do not match',
}

// eslint-disable-next-line max-lines-per-function
const NewMemberLogInData = ({ onCreateUser }) => {
  const dispatch = useDispatch()

  const formData = useSelector(editingUserFormData)
  const {
    errors,
    familyAdmin,
    age,
    email,
    username,
    password,
    passwordConfirmation,
    avatarUrl,
  } = useSelector(state => state.signUp.editingUser)

  const formInvalid = useMemo(() => {
    const anyEmptyFields = !password || !passwordConfirmation
    const anyEmailErrors = familyAdmin && !isValidEmail(email)
    const anyUsernameErrors = !familyAdmin && !isValidUsername(username)
    const anyPasswordErrors = password.length < 6 || password !== passwordConfirmation

    return anyEmptyFields || anyEmailErrors || anyPasswordErrors || anyUsernameErrors
  }, [familyAdmin, email, username, password, passwordConfirmation])

  const handleInputChange = e => {
    const field = e.target.name
    const value = e.target.value

    dispatch(setEditingUserField({ field, value }))
  }

  const handleAvatarChange = e => {
    if (e.target.value) {
      const file = e.target.files[0]

      dispatch(setEditingUserField({ field: 'avatar', value: file }))
      dispatch(setEditingUserField({ field: 'avatarUrl', value: URL.createObjectURL(file) }))
    }
  }

  const handleAvatarRemove = () => {
    dispatch(setEditingUserField({ field: 'avatar', value: null }))
    dispatch(setEditingUserField({ field: 'avatarUrl', value: '' }))
  }

  const validateEmail = e => {
    const value = e.target.value

    dispatch(setEditingUserError({ field: 'username', value: null }))
    if (email && !isValidEmail(value)) {
      dispatch(setEditingUserError({ field: 'email', value: customErrorMessages.email }))
    } else {
      dispatch(setEditingUserError({ field: 'email', value: null }))
    }
  }

  const validateUsername = e => {
    const value = e.target.value

    dispatch(setEditingUserError({ field: 'email', value: null }))
    if (username && !isValidUsername(value)) {
      dispatch(setEditingUserError({ field: 'username', value: customErrorMessages.username }))
    } else {
      dispatch(setEditingUserError({ field: 'username', value: null }))
    }
  }

  const validatePassword = () => {
    if (password && password.length < 6) {
      dispatch(setEditingUserError({ field: 'password', value: customErrorMessages.password }))
    } else {
      dispatch(setEditingUserError({ field: 'password', value: null }))
    }
  }

  const validatePasswordConfirmation = () => {
    if (password && passwordConfirmation && password !== passwordConfirmation) {
      dispatch(setEditingUserError({ field: 'passwordConfirmation', value: customErrorMessages.passwordConfirmation }))
    } else {
      dispatch(setEditingUserError({ field: 'passwordConfirmation', value: null }))
    }
  }

  return (
    <div className={classNames(classes.newMemberLogInData, 'd-flex flex-column')}>
      <form className={classNames(classes.mainContent, 'd-flex flex-column align-items-center justify-content-start')}>
        <div className='w-100'>
          <h1 className={classes.titleText}>Add Family Member</h1>
        </div>

        <div className='w-100'>
          <section className='row mb-2'>
            <div className='col-12'>
              <h3 className={classes.inputSection}>Log In Info</h3>
            </div>

            <div className='col-12 col-lg-6'>
              <label htmlFor='email' className={classes.inputLabel}>
                {familyAdmin ? 'Email' : 'Username'}<span className='required-text'>*</span>
              </label>

              {familyAdmin ? (
                <FormInput
                  name='email'
                  type='text'
                  onChange={handleInputChange}
                  onBlur={validateEmail}
                  value={email}
                  error={errors.email}
                  symbol={<i className={classNames(classes.inputSymbol, 'fa fa-envelope')}/>}
                />
              ) : (
                <FormInput
                  name='username'
                  type='text'
                  onChange={handleInputChange}
                  onBlur={validateUsername}
                  value={username}
                  placeholder={'Insert Username -  Ex: JohnSmith1234'}
                  error={errors.username}
                  symbol={<i className={classNames(classes.inputSymbol, 'fa fa-id-card')}/>}
                />
              )}

              <label htmlFor='password' className={classes.inputLabel}>
                Password <span className='required-text'>*</span>
              </label>

              <FormInput
                name='password'
                type='password'
                onChange={handleInputChange}
                onBlur={() => { validatePassword(); validatePasswordConfirmation() }}
                value={password}
                error={errors.password}
                symbol={<i className={classNames(classes.inputSymbol, 'fa fa-lock')}/>}
                autoComplete='new-password'
              />

              <label htmlFor='passwordConfirmation' className={classes.inputLabel}>
                Confirm Password <span className='required-text'>*</span>
              </label>

              <FormInput
                name='passwordConfirmation'
                type='password'
                onChange={handleInputChange}
                onBlur={validatePasswordConfirmation}
                value={passwordConfirmation}
                error={errors.passwordConfirmation}
                symbol={<i className={classNames(classes.inputSymbol, 'fa fa-lock')}/>}
                autoComplete='new-password'
              />
            </div>

            <div className='col-12 col-lg-6'>
              <div className={classes.avatarWrapper}>
                <AvatarInput
                  avatarUrl={avatarUrl}
                  onAvatarChange={handleAvatarChange}
                  onRemoveAvatar={handleAvatarRemove}
                  error={errors.photo}
                  imageSize='small'
                />
              </div>
            </div>
          </section>
        </div>

        {age < 13
          ? <StepButton color='orange' message='Continue' newStep={7} disabled={formInvalid}/>
          : <StepButton color='orange' message='Add Member' onStepChange={() => onCreateUser(formData)} disabled={formInvalid} displayLoader/>
        }
        <StepButton color='navy' message='Go Back' newStep={5}/>
      </form>
    </div>
  )
}

NewMemberLogInData.propTypes = {
  onCreateUser: PropTypes.func,
}

export default NewMemberLogInData
