import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import classnames from 'classnames'
import Modal from 'react-modal'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PageHeader from '../../common/PageHeader/PageHeader'
import CopyProgramDialog from '../CopyProgram/CopyProgramDialog'
import { setCurrentTab, showNavBar, showSideBar } from '../../../actions/commonActions'
import { getCommunityCategories } from '../../../actions/programCategoryActions'
import CoachProgramsActiveTab from './CoachProgramsActiveTab'
import CoachProgramsFutureTab from './CoachProgramsFutureTab'
import CoachProgramsDraftTab from './CoachProgramsDraftTab'
import CoachProgramsCompletedTab from './CoachProgramsCompletedTab'
import { selectInitialFilters, perPage } from '../../../reducers/groupProgramsReducer'
import { getCoachPrograms } from '../../../actions/programActions'
import DefaultMultiActionsPopover from '../../common/PageHeader/DefaultMultiActionsPopover'

import styles from './CoachProgramsPage.module.scss'

const CoachProgramsPage = () => {
  const [showCopyProgramModal, setShowCopyProgramModal] = useState(false)
  const dispatch = useDispatch()
  const initialFilters = useSelector(selectInitialFilters)
  const user = useSelector(state => state.user.current_user)
  const { isLoadingCategories, active, future, draft, completed } = useSelector(state => state.groupPrograms)

  useEffect(() => {
    dispatch(setCurrentTab('Programs'))
    dispatch(showSideBar(true))
    dispatch(showNavBar(true))
    if (isLoadingCategories){
      dispatch(getCommunityCategories())
    } else {
      if (active.programs.length === 0 && active.hasMore === true) {
        dispatch(getCoachPrograms({ filters: { ...initialFilters, active: 'true' }, page: 1, perPage: perPage, withEnrollments: 'true' }))
      }
      if (future.programs.length === 0 && future.hasMore === true) {
        dispatch(getCoachPrograms({ filters: { ...initialFilters, future: 'true' }, page: 1, perPage: perPage, withEnrollments: 'true' }))
      }
      if (draft.programs.length === 0 && draft.hasMore === true) {
        dispatch(getCoachPrograms({ filters: { draft: 'true' }, page: 1, perPage: perPage }))
      }
      if (completed.programs.length === 0 && completed.hasMore === true) {
        dispatch(getCoachPrograms({ filters: { ...initialFilters, completed: 'true' }, page: 1, perPage: perPage, withEnrollments: 'true' }))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isLoadingCategories])

  const fetchPrograms = (state, tab) => {
    dispatch(getCoachPrograms({
      filters: { ...state.filters, [tab]: 'true', search: state.search },
      page: state.page + 1,
      perPage: perPage,
      withEnrollments: 'true',
    }))
  }

  return (
    <div className={styles.coachProgramsPage}>
      <PageHeader
        title='Group Programs'
        actionComponent={(
          <DefaultMultiActionsPopover
            title='Build a Program'
            isModalOpen={showCopyProgramModal}
            actions={[
              { label: 'Build a Program', link: true, to: '/programs/new' },
              { label: 'Copy Existing Program', onClick: () => setShowCopyProgramModal(true) },
            ]}
          />
        )}
      />

      <Tabs>
        <TabList className={styles.tabList}>
          <Tab className={classnames('react-tabs__tab', styles.tab)} selectedClassName={styles.activeTab}>
            Active {active.count !== null && `(${active.count})`}
          </Tab>
          <Tab className={classnames('react-tabs__tab', styles.tab)} selectedClassName={styles.activeTab}>
            Future {future.count !== null && `(${future.count})`}
          </Tab>
          <Tab className={classnames('react-tabs__tab', styles.tab)} selectedClassName={styles.activeTab}>
            Drafts {draft.count !== null && `(${draft.count})`}
          </Tab>
          <Tab className={classnames('react-tabs__tab', styles.tab)} selectedClassName={styles.activeTab}>
            Completed {completed.count !== null && `(${completed.count})`}
          </Tab>
        </TabList>

        <TabPanel className={styles.tabPanel}>
          <CoachProgramsActiveTab
            fetchPrograms={fetchPrograms}
          />
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          <CoachProgramsFutureTab
            fetchPrograms={fetchPrograms}
          />
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          <CoachProgramsDraftTab/>
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          <CoachProgramsCompletedTab
            fetchPrograms={fetchPrograms}
          />
        </TabPanel>
      </Tabs>

      <Modal
        isOpen={showCopyProgramModal}
        onRequestClose={() => setShowCopyProgramModal(false)}
        contentLabel='Copy Program Modal'
      >
        <CopyProgramDialog
          closeModal={() => setShowCopyProgramModal(false)}
          user={user}
        />
      </Modal>
    </div>
  )
}

export default CoachProgramsPage
