import PropTypes from 'prop-types'
import moment from 'moment'
import { Link } from 'react-router-dom'

import { ReactComponent as EditIcon } from '../../../assets/images/common/icons/edit-icon.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/images/common/icons/programBuilder/delete-icon.svg'
import { ReactComponent as GolferIcon } from '../../../assets/images/common/icons/golfer-half-icon.svg'
import { ReactComponent as OutsideLinkIcon } from '../../../assets/images/common/icons/outside-link-icon.svg'

import styles from './EventRow.module.scss'
import classNames from 'classnames'
import colors from '../../../assets/styles/globals.scss'

const EventRow = ({ event, handleEditEvent, handleDeleteEvent, timezone, index, withExternalLink }) => (
  <div className={classNames('d-flex justify-content-center', styles['schedule-event-row'])} key={event.index}>
    <div className={styles['date-info']}>
      <span className={styles['month-name']}>{ moment(event.eventDate).tz(timezone).format('MMM') }</span>
      <span className={styles['day-number']}>{ moment(event.eventDate).tz(timezone).format('DD') }</span>
      <span>{moment(event.eventDate).tz(timezone).format('ddd') }</span>
    </div>

    <div className={styles['event-info']}>
      <div className='d-flex justify-content-between w-100 mb-2'>
        <p className={styles['title']}>
          Event #{index + 1}{event.name && ` - ${event.name}`}
        </p>
        <div>
          {handleEditEvent && (
            <EditIcon fill={colors.primaryOrange} aria-hidden='true' className={styles['edit-icon']} onClick={() => handleEditEvent(event)} />
          )}
          {handleDeleteEvent && (
            <DeleteIcon aria-hidden='true' className={classNames(styles['delete-icon'], 'ml-3')} onClick={() => handleDeleteEvent(event)} />
          )}
          {withExternalLink && (
            <Link to={`/events/${event.id}`} target='_blank'>
              <OutsideLinkIcon />
            </Link>
          )}
        </div>
      </div>

      <div className={classNames('d-flex align-items-center mb-2 w-100', styles['format'])}>
        <GolferIcon fill={colors.primaryOrange} className='mr-2'/>
        <span>{event.format}</span>
      </div>

      <div className={classNames('d-flex align-items-center mb-2 w-100', styles['time-range'])}>
        <span className={styles['icon-wrapper']}><i className={classNames('fa fa-clock-o', styles['icon'])}/></span>
        <p>{`${moment(event.eventDate).tz(timezone).format('hh:mm a')}`}</p>
      </div>

      {event?.location && (
        <div className='d-flex align-items-center mb-2 w-100'>
          <span className={styles['icon-wrapper']}><i className={classNames('fa fa-map-marker', styles['icon'])}/></span>
          <p>{event.location}</p>
        </div>
      )}

      {event?.description && (
        <div className='w-100'>
          <p className={classNames('description', styles['w-100'])}>
            {event.description}
          </p>
        </div>
      )}
    </div>
  </div>
)

EventRow.propTypes = {
  event: PropTypes.object,
  handleEditEvent: PropTypes.func,
  handleDeleteEvent: PropTypes.func,
  timezone: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  withExternalLink: PropTypes.bool,
}

export default EventRow
