import Modal from 'react-modal'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SearchInput from 'react-search-input'
import { toast } from 'react-toastify'
import { debounce } from 'lodash'

import { addEnrollmentManually } from '../../../../actions/enrollmentActions'
import AddStudents from './AddStudents'
import LoadingSpinner from '../../../common/LoadingSpinner'
import ConfirmationModal2 from '../../../common/ConfirmationModal2'

const AddStudentsManuallyModal = ({ isModalShown, closeModal, fetchAllCommunityStudents }) => {
  const dispatch = useDispatch()

  const { program, isLoadingAllStudents } = useSelector(state => state.programDashboard)

  const [showIncreaseGolferLimitConfirmationModal, setShowIncreaseGolferLimitConfirmationModal] = useState(false)
  const [isLoadingAddStudents, setIsLoadingAddStudents] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [activeMembersIds, setActiveMembersIds] = useState([])

  const addEnrollments = async () => {
    setIsLoadingAddStudents(true)

    const allActions = activeMembersIds.map(id => dispatch(addEnrollmentManually({ courseId: program.id, userId: id })).unwrap())
    try {
      await Promise.all(allActions)

      toast.success('Enrollments added successfully.', { position: toast.POSITION.TOP_RIGHT })
      handleCloseModal()
    } catch (err) {

      toast.error('Unable to create some enrollments. Please retry for the affected users.')
    } finally {
      setActiveMembersIds([])
      setIsLoadingAddStudents(false)
    }
  }

  const handleSubmitButton = () => {
    if (program.golferLimit && activeMembersIds.length + program.enrollments.length > program.golferLimit) {
      setShowIncreaseGolferLimitConfirmationModal(true)
    } else {
      addEnrollments()
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchStudents = useCallback(
    debounce(term => fetchAllCommunityStudents(term), 400),
    []
  )

  const handleSearchChange = (term) => {
    setSearchTerm(term)
    debouncedSearchStudents(term)
  }

  const handleCloseModal = () => {
    setSearchTerm('')
    setActiveMembersIds([])
    closeModal()
  }

  return (
    <Modal
      isOpen={isModalShown}
      onRequestClose={handleCloseModal}
      contentLabel='Add Students'
      style={{
        content: { display: 'flex', flexDirection: 'column' },
      }}
    >
      <div className='modal-header row'>
        <div className='col-11'>
          <h5 className='modal-title display-4'>Add Students</h5>
        </div>
        <div className='col-1'>
          <button
            type='button'
            className='close pull-right font-200'
            data-dismiss='modal'
            aria-label='Close'
            onClick={handleCloseModal}
          >
            <span aria-hidden='true'>✕</span>
          </button>
        </div>
      </div>
      <div className='flex-grow-1'>
        <div className='modal-content col-md-6 mx-auto pb-5'>
          <div className='modal-body' id='input-border'>
            <div className='search-container'>
              <i className='fa fa-search search-icon' />
              <SearchInput
                className='search-input'
                value={searchTerm}
                onChange={term => handleSearchChange(term)}
                style={{
                  border: '1px solid #2b3843',
                  borderRadius: '25px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  width: '250px',
                }}
                placeholder='Search Students'
              />
            </div>
            <div className='row'>
              <div className='col'>
                <div className='form-group'>
                  {isLoadingAllStudents ? (
                    <LoadingSpinner
                      text='Loading Golfers...'
                      classNames={{ wrapper: 'h-100', spinner: 'fa-4x' }}
                    />
                  ) : (
                    <AddStudents activeMembersIds={activeMembersIds} setActiveMembersIds={setActiveMembersIds} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='modal-footer fixed-bottom w-100 bg-white'>
          <button
            type='button'
            className='btn btn-primary btn-block btn-op36'
            disabled={isLoadingAddStudents || activeMembersIds.length === 0}
            onClick={handleSubmitButton}
          >
            {isLoadingAddStudents ? 'Adding Students...' : 'Add Students'}
          </button>
        </div>
      </div>
      <ConfirmationModal2
        showModal={showIncreaseGolferLimitConfirmationModal}
        closeModal={() => setShowIncreaseGolferLimitConfirmationModal(false)}
        onDeny={() => setShowIncreaseGolferLimitConfirmationModal(false)}
        denyMessage='Cancel'
        onProceed={() => addEnrollments()}
        proceedMessage='Add students'
        title='Your class is already full!'
        message={`Are you sure you want to add more students? It will increase your maximum capacity from
                    ${program.golferLimit} to ${program.enrollments.length + activeMembersIds.length}.`}
      />
    </Modal>
  )
}

AddStudentsManuallyModal.propTypes = {
  isModalShown: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  fetchAllCommunityStudents: PropTypes.func.isRequired,
}

export default AddStudentsManuallyModal
