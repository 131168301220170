import React from 'react'
import * as Constants from '../misc/Constants'
import { mapValues } from 'lodash'

export function showAlert(message, callback, title, buttonLabels) {
  if (window.navigator.notification) {
    window.navigator.notification.alert(
      message, // message
      callback, // callback to invoke
      title, // title
      buttonLabels // buttonLabels
    )
  } else {
    console.error('window.navigator.notification missing')
  }
}

export const pluralize = (string, count) => `${string}${count !== 1 ? 's' : ''}`

export const abbrNum = (number, decPlaces) => {
  decPlaces = Math.pow(10, decPlaces)

  const abbrev = ['k', 'm', 'b', 't']

  for (let i = abbrev.length - 1; i >= 0; i--) {
    const size = Math.pow(10, (i + 1) * 3)

    if (size <= number) {
      number = Math.round((number * decPlaces) / size) / decPlaces

      if (number === 1000 && i < abbrev.length - 1) {
        number = 1
        i++
      }

      number += abbrev[i]
      break
    }
  }

  return number
}

export const isValidUsername = username => {
  const reg = /^[a-zA-Z0-9_.]+$/
  return reg.test(String(username).toLowerCase())
}

export const isValidEmail = (email) => {
  const re =
    // eslint-disable-next-line max-len, no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const isValidPhone = (phone) => {
  const re =
    // eslint-disable-next-line max-len, no-useless-escape
    /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i

  return re.test(String(phone))
}

export const isZipCode = zipCode => Constants.ZIP_CODE_REGEX.test(zipCode)

export const isMissingImageUrl = url => Constants.DEFAULT_IMAGE_URL_REGEX.test(url)

/**
  Twilio requires numbers to be in E.164 format (up to 15 digits in length
  starting with a ‘+’).
  See https://www.twilio.com/docs/glossary/what-e164.
*/
export const validatePhoneForTwilio = (phone) => {
  const re = /^\+[1-9]\d{1,14}$/

  return re.test(String(phone))
}

export const isValidRgbColor = color => Constants.RGB_COLOR_REGEX.test(color)

export const displayYearOptions = () => {
  const rows = []
  const currentYear = new Date().getFullYear()
  const maxSpread = 100
  for (let i = 0; i < maxSpread; i++) {
    const year = currentYear - i
    rows.push(<option key={year}>{year}</option>)
  }
  return rows
}

export const validateApplicationFeeFixed = (app_fee) => Constants.FIXED_FEE_VALUE_REGEX.test(app_fee)

export const validateApplicationFeePercentage = (app_fee) => Constants.PERCENTAGE_FEE_VALUE_REGEX.test(app_fee)

export const toggleNavigation = (navigation) => (
  navigation === Constants.NAVIGATION.COLLAPSED
    ? Constants.NAVIGATION.EXPANDED
    : Constants.NAVIGATION.COLLAPSED
)

export const flatServerFormErrors = (serverErrorObj) =>
  mapValues(serverErrorObj || {}, error => error.join('; '))

/**
 * Compares two users alphabetically by their first name and then by their last name.
 */
export const defaultUserCompareFunction = (u1, u2) => u1.firstName.localeCompare(u2.firstName) || u1.lastName.localeCompare(u2.lastName)

/**
 * Compares two enrollments by their users. The users are compared alphabetically by their first name and then by their last name.
 */
export const defaultUserEnrollmentCompareFunction = (e1, e2) => defaultUserCompareFunction(e1.user, e2.user)

/**
 * Compares two events by their event date.
 */
export const defaultEventCompareFunction = (e1, e2) => new Date(e1.eventDate) - new Date(e2.eventDate)

/**
 * Compares two schedule sessions by their start date, then by their end date
 */
export const defaultScheduleSessionCompareFunction = (s1, s2) =>
  (new Date(s1.startDate) - new Date(s2.startDate)) || (new Date(s1.endDate) - new Date(s2.endDate))
