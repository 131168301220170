import { useSelector } from 'react-redux'
import SeriesPresentationList from '../../../../events/SeriesPresentationList'

const SeriesSchedule = () => {
  const course = useSelector(state => state.programPresentation.course)
  const timezone = course.community.timezone

  return (
    <SeriesPresentationList
      className='pb-1 px-lg-5'
      series={course.series}
      timezone={timezone}
    />
  )
}

export default SeriesSchedule
