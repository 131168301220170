/* eslint-disable no-unused-vars */
import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import { getCommunityCategories } from '../actions/programCategoryActions'
import _ from 'lodash'
import { deleteProgram, getCoachPrograms } from '../actions/programActions'
import { addStudentEnrollment } from '../actions/coachActions'
import { deserialize } from '../utility/serialization'

const categoriesAdapter = createEntityAdapter()
export const perPage = 10

const initialState = {
  categories: categoriesAdapter.getInitialState(),
  isLoadingCategories: true,
  active: {
    filters: null,
    programs: [],
    hasMore: true,
    page: 0,
    search: undefined,
    count: null,
  },
  future: {
    filters: null,
    programs: [],
    hasMore: true,
    page: 0,
    search: undefined,
    count: null,
  },
  draft: {
    programs: [],
    hasMore: true,
    page: 0,
    search: undefined,
    count: null,
  },
  completed: {
    filters: null,
    programs: [],
    hasMore: true,
    page: 0,
    search: undefined,
    count: null,
  },
}

const groupProgramsSlice = createSlice({
  name: 'groupPrograms',
  initialState,
  extraReducers: {
    [getCommunityCategories.fulfilled]: (state, action) => {
      const categories = action.payload.courseCategories
      categoriesAdapter.setMany(state.categories, categories)
      if (!state.active.filters){
        state.active.filters = {
          ...state.active.filters,
          categoryId: categories.map(c => c.id),
        }
      }
      if (!state.future.filters){
        state.future.filters = {
          ...state.future.filters,
          categoryId: categories.map(c => c.id),
        }
      }
      if (!state.completed.filters){
        state.completed.filters = {
          ...state.completed.filters,
          categoryId: categories.map(c => c.id),
        }
      }
      state.isLoadingCategories = false
    },
    [getCoachPrograms.fulfilled]: (state, action) => {
      if (action.payload.filters.active){
        updateActiveState(state, action.payload)
      } else if (action.payload.filters.future){
        updateFutureState(state, action.payload)
      } else if (action.payload.filters.draft){
        updateDraftState(state, action.payload)
      } else if (action.payload.filters.completed){
        updateCompletedState(state, action.payload)
      }
    },
    [deleteProgram.fulfilled]: (state, action) => {
      const programId = action.payload.programId
      if (state.active.programs.find(program => program.id === programId)) {
        state.active.programs = state.active.programs.filter(program => program.id !== programId)
        state.active.count -= 1
      } else if (state.future.programs.find(program => program.id === programId)) {
        state.future.programs = state.future.programs.filter(program => program.id !== programId)
        state.future.count -= 1
      } else if (state.draft.programs.find(program => program.id === programId)) {
        state.draft.programs = state.draft.programs.filter(program => program.id !== programId)
        state.draft.count -= 1
      } else if (state.completed.programs.find(program => program.id === programId)) {
        state.completed.programs = state.completed.programs.filter(program => program.id !== programId)
        state.completed.count -= 1
      }
    },
    //TODO: remove after merging feature/program-management-dashboard-redesign
    [addStudentEnrollment.fulfilled]: (state, action) => {
      updateProgramEnrollments(state, action.payload)
    },
  },
})

const updateActiveState = (state, payload) => {
  const { search, active, ...filters } = payload.filters

  if (state.active.search === search &&
    _.isEqual(filters, state.active.filters) &&
    payload.meta.pagination.page > 1
  ) {
    state.active.programs = [...state.active.programs, ...payload.courses]
  } else {
    state.active.programs = payload.courses
  }
  state.active.search = search
  state.active.filters = filters

  if (state.active.programs.length === 0){
    state.active.page = 1
    state.active.hasMore = false
  } else {
    state.active.page = payload.meta.pagination.page
    state.active.hasMore = !payload.meta.pagination.isLastPage
  }
  state.active.count = payload.meta.pagination.totalCount || 0
}

const updateFutureState = (state, payload) => {
  const { search, future, ...filters } = payload.filters

  if (state.future.search === search &&
    _.isEqual(filters, state.future.filters) &&
    payload.meta.pagination.page > 1
  ) {
    state.future.programs = [...state.future.programs, ...payload.courses]
  } else {
    state.future.programs = payload.courses
  }
  state.future.search = search
  state.future.filters = filters

  if (state.future.programs.length === 0){
    state.future.page = 1
    state.future.hasMore = false
  } else {
    state.future.page = payload.meta.pagination.page
    state.future.hasMore = !payload.meta.pagination.isLastPage
  }
  state.future.count = payload.meta.pagination.totalCount || 0
}

const updateDraftState = (state, payload) => {
  if (state.draft.search !== payload.filters.search || payload.meta.pagination.page === 1) {
    state.draft.programs = payload.courses
  } else {
    state.draft.programs = [...state.draft.programs, ...payload.courses]
  }
  state.draft.search = payload.filters.search

  if (state.draft.programs.length === 0){
    state.draft.page = 1
    state.draft.hasMore = false
  } else {
    state.draft.page = payload.meta.pagination.page
    state.draft.hasMore = !payload.meta.pagination.isLastPage
  }
  state.draft.count = payload.meta.pagination.totalCount || 0
}

const updateCompletedState = (state, payload) => {
  const { search, completed, ...filters } = payload.filters

  if (state.completed.search === search &&
    _.isEqual(filters, state.completed.filters) &&
    payload.meta.pagination.page > 1
  ) {
    state.completed.programs = [...state.completed.programs, ...payload.courses]
  } else {
    state.completed.programs = payload.courses
  }
  state.completed.search = search
  state.completed.filters = filters

  if (state.completed.programs.length === 0){
    state.completed.page = 1
    state.completed.hasMore = false
  } else {
    state.completed.page = payload.meta.pagination.page
    state.completed.hasMore = !payload.meta.pagination.isLastPage
  }
  state.completed.count = payload.meta.pagination.totalCount || 0
}

const updateProgramEnrollments = (state, payload) => {
  const program = state.active.programs.find(program => program.id === payload.enrollment.course_id) ||
    state.future.programs.find(program => program.id === payload.enrollment.course_id) ||
    state.draft.programs.find(program => program.id === payload.enrollment.course_id) ||
    state.completed.programs.find(program => program.id === payload.enrollment.course_id)

  if (program) {
    program.enrollments.push(deserialize(payload.enrollment))
    program.students.push(deserialize(payload.enrollment.user))
  }
}

const { selectById } = categoriesAdapter.getSelectors()

export const {
  selectAll: selectAllCategories,
  selectIds: selectAllCategoriesIds,
} = categoriesAdapter.getSelectors(state => state.groupPrograms.categories)

export const selectInitialFilters = createSelector(
  selectAllCategoriesIds,
  categoriesIds => ({
    categoryId: categoriesIds,
    paymentMethod: ['card', 'cash'],
    visibility: ['public', 'unlisted', 'private'],
    startDate: null,
    endDate: null,
  })
)

export const selectFilteredCategory = createSelector(
  state => state.groupPrograms.categories,
  (_, categoryId) => categoryId,
  selectAllCategoriesIds,
  (state, id, allIds) => {
    if (_.isEqual(id, allIds)) {
      return { name: 'All' }
    } else {
      const categoryId = id ? id[0] : id
      return { name: selectById(state, categoryId)?.name, color: selectById(state, categoryId)?.color }
    }
  }
)

export const selectFiltersNumber = createSelector(
  (_, filters) => filters,
  selectInitialFilters,
  (filters, initialFilters) => {
    if (!filters) {
      return 0
    }

    return Object.keys(filters).reduce((count, filter) =>
      _.isEqual(filters[filter], initialFilters[filter]) ? count : count + 1, 0)
  }
)

export const selectAllCategoryOptions = createSelector(
  selectAllCategoriesIds,
  selectAllCategories,
  (categoriesIds, categories) => [
    { value: categoriesIds, label: 'All' },
    ...categories.map(c => ({ value: [c.id], label: c.name, color: c.color })),
  ]
)

export const { programDeleted } = groupProgramsSlice.actions

export default groupProgramsSlice.reducer
