import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import _ from 'lodash'

import EventHeader from './Header/EventHeader'
import PageFooter from '../../common/PageFooter/PageFooter'
import EventForm from '../Form/EventForm'
import Loader from '../../common/Op36Loader-web'
import EventInfo from './Leaderboard/EventInfo'
import Leaderboard from './Leaderboard/Leaderboard'
import GuestList from './GuestList'
import ConfirmationModal2 from '../../common/ConfirmationModal2'
import EventInProgressAlert from '../EventInProgressAlert'
import { ReactComponent as EditIcon } from '../../../assets/images/common/icons/edit-icon.svg'

import * as eventActions from '../../../actions/eventActions'
import * as commonActions from '../../../actions/commonActions'
import colors from '../../../assets/styles/globals.scss'

import styles from './EventDetail.module.scss'
import { setField } from '../../../reducers/nineHoleEventReducer'
import { setField as setFieldSeries } from '../../../reducers/seriesReducer'
import Placeholder from './Placeholder'

const EditIconComponent = (
  <div className={styles.editIconWrapper}>
    <EditIcon fill='#fff' width='40px' height='40px'/>
  </div>
)

const EventDetail = ({ eventId }) => { // eslint-disable-line max-lines-per-function
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const event = useSelector(state => state.nineHoleEvent)

  const [showForm, setShowForm] = useState(false)
  const [eventLoader, setEventLoader] = useState(true)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showDeleteEnrollmentModal, setShowDeleteEnrollmentModal] = useState(false)
  const [showLoadScoresModal, setShowLoadScoresModal] = useState(false)
  const [selectedEnrollmentId, setSelectedEnrollmentId] = useState(null)
  const [tabIndex, setTabIndex] = useState(0)

  const completed = event.status === 'completed'
  const scoresPassedDue = event.status === 'ongoing'
  const localScores = JSON.parse(localStorage.getItem('draftScores'))
  const eventScores = localScores && localScores[event.id] && localScores[event.id]['scores']
  const savedScoresCount = eventScores ? Object.values(eventScores).filter(value => value.score > 0).length : 0

  useEffect(() => {
    dispatch(commonActions.setCurrentTab('EventDetail'))
    dispatch(commonActions.showSideBar(!showForm))
    dispatch(commonActions.showNavBar(!showForm))
  }, [dispatch, showForm])

  useEffect(() => {
    dispatch(commonActions.setCurrentTab('EventDetail'))
    dispatch(commonActions.showSideBar(!showForm))
    dispatch(commonActions.showNavBar(!showForm))
  }, [dispatch, showForm])

  const onClickBack = () => {
    navigate('/events')
  }

  const openEditModal = ({ action }) => {
    if (action) { dispatch(setField({ field: 'action', value: action })) }
    setShowForm(!showForm)
  }

  const closeEditModal = () => {
    fetchEvent()
    setShowForm(false)
  }

  const fetchEvent = useCallback(() => {
    setEventLoader(true)
    dispatch(eventActions.getEventDetail(params.eventId || eventId)).unwrap().then(() => {
      setEventLoader(false)
    })
  }, [dispatch, params.eventId, eventId])

  const handleDeleteEnrollment = () => {
    dispatch(eventActions.deleteEnrollment(selectedEnrollmentId)).unwrap()
      .catch(() => {
        toast.error('Something went wrong.', { position: toast.POSITION.TOP_RIGHT })
      })
      .finally(() => {
        setShowDeleteEnrollmentModal(false)
      })
  }

  const updateEvent = () => {
    setEventLoader(true)
    dispatch(eventActions.updateEvent(event)).then(() => {
      toast.success(`Success! Updated ${event.name}`, {
        position: toast.POSITION.BOTTOM_LEFT,
      })
      removeDraftScores()
      _.delay(handleEventSubmittedClick, 5000)
      setEventLoader(false)
    }).catch(() => setEventLoader(false))
  }

  const updateEnrollments = () => {
    setEventLoader(true)
    dispatch(eventActions.updateEnrollments({ event })).then(() => {
      toast.success(`Success! Updated ${event.name}`, {
        position: toast.POSITION.BOTTOM_LEFT,
      })
      setEventLoader(false)
      handleEventSubmittedClick()
    }).catch(() => setEventLoader(false))
  }

  const handleEventSubmittedClick = () => {
    if (!showForm) {
      return
    }

    fetchEvent()
    // triggers fetching series
    dispatch(setFieldSeries({ field: 'upcomingSeries', value: null }))
    dispatch(setFieldSeries({ field: 'completedSeries', value: null }))
    setShowForm(false)
  }

  const deleteEvent = () => {
    dispatch(eventActions.deleteEvent(event.id)).then(() => {
      toast.success(`Successfully deleted ${event.name}`, {
        position: toast.POSITION.BOTTOM_LEFT,
      })
      navigate('/events')
    })
  }

  const removeStudent = (id) => {
    setSelectedEnrollmentId(id)
    setShowDeleteEnrollmentModal(true)
  }

  const sendEmailButton = {
    text: 'Send an Email',
    icon: 'fa fa-envelope',
    onClick: () => navigate('/announcements/new-campaign', { state: { smartToggle: 'email', students: event.students } }),
  }

  const logScoresButton = {
    text: 'Log Scores',
    onClick: () => handleLogScoresClick(),
  }

  const handleLogScoresClick = () => {
    const scores = JSON.parse(localStorage.getItem('draftScores'))
    if (scores && scores[event.id]) {
      setShowLoadScoresModal(true)
    } else {
      openEditModal({ action: 'Log Scores' })
    }
  }

  const loadScores = () => {
    setShowLoadScoresModal(false)
    if (eventScores) {
      const updatedStudents = event.students.map((std) => {
        const studentData = eventScores[std.user_id]
        if (studentData) {
          return {
            ...std,
            score: studentData['score'],
            greens: studentData['greens'],
            putts: studentData['putts'],
            yardage: studentData['yardage'],
            fullTeeYardage: studentData['fullTeeYardage'],
            level: studentData['level'],
          }
        }
        return std
      })
      dispatch(setField({ field: 'students', value: updatedStudents }))
    }
    openEditModal({ action: 'Log Scores' })
  }

  const removeDraftScores = () => {
    const localScores = JSON.parse(localStorage.getItem('draftScores'))
    delete localScores[event.id]
    localStorage.setItem('draftScores', JSON.stringify(localScores))
  }

  const startFromScratch = () => {
    removeDraftScores()
    setShowLoadScoresModal(false)
    openEditModal({ action: 'Log Scores' })
  }

  useEffect(() => {
    fetchEvent()
  }, [fetchEvent])

  useEffect(() => {
    setTabIndex(completed ? 1 : 0)
  }, [completed])

  return (
    <div className={styles.eventDetail} style={{ marginLeft: !showForm ? '-15px' : '0' }} >
      {showForm ?
        <EventForm
          isEdit
          onClickBack={closeEditModal}
          updateEvent={updateEvent}
          updateEnrollments={updateEnrollments}
          handleEventSubmittedClick={handleEventSubmittedClick}
          eventLoader={eventLoader}
        /> :
        eventLoader
          ? (<Loader message='loading event' />)
          : (
            <div className='w-100'>
              <EventHeader
                className='sticky-top'
                event={event}
                onClickBack={onClickBack}
                openEditModal={openEditModal}
                handleLogScoresClick={handleLogScoresClick}
                setShowDeleteModal={setShowDeleteModal}
              />
              {!event.series?.id ?
                <div className={classNames('row mx-0', styles.leaderboardPage)}>
                  <div className='col-md-4'>
                    <EventInfo/>
                  </div>
                  <div className='col-md-8'>
                    <Leaderboard/>
                  </div>
                </div> :
                <>
                  {(eventScores || scoresPassedDue) &&
                    <EventInProgressAlert scoresPassedDue={scoresPassedDue} onClick={loadScores}/>
                  }
                  <Tabs className='pb-5' selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                    <TabList className={classNames(styles.tabList, 'd-flex')} >
                      <Tab className={`${styles.tab} react-tabs__tab`} selectedClassName={styles.activeTab}>Roster</Tab>
                      <Tab className={`${styles.tab} react-tabs__tab`} selectedClassName={styles.activeTab}>Leaderboard</Tab>
                    </TabList>
                    <TabPanel className={styles.tabPanel}>
                      <div className={classNames('col-12 px-0', styles.guestList)}>
                        <GuestList event={event} removeStudent={removeStudent}/>
                      </div>
                    </TabPanel>
                    <TabPanel className={styles.tabPanel}>
                      {completed ?
                        <div className={classNames('row mx-0', styles.leaderboardPage)}>
                          <div className='col-md-4'>
                            <EventInfo/>
                          </div>
                          <div className='col-md-8'>
                            <Leaderboard/>
                          </div>
                        </div> :
                        <div>
                          <Placeholder
                            logScores={eventScores ? loadScores : () => openEditModal({ action: 'Log Scores' })}
                            eventInProgress={!!eventScores}
                            savedScoresCount={savedScoresCount}
                          />
                        </div>
                      }
                    </TabPanel>
                  </Tabs>
                </>
              }
              {!completed &&
                <PageFooter
                  firstButton={sendEmailButton}
                  secondButton={event.status === 'draft' ? null : logScoresButton}
                  reversed
                />
              }
            </div>
          )
      }
      <ConfirmationModal2
        showModal={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        onDeny={() => setShowDeleteModal(false)}
        denyMessage='Cancel'
        onProceed={() => deleteEvent()}
        proceedMessage='Delete Event'
        icon={<i className='fa fa-exclamation-triangle' style={{ fontSize: '72px', color: colors.secondaryColor }} />}
        title='Are you sure?'
        message='This will delete all activities for the golfers of this event.'
      />

      <ConfirmationModal2
        showModal={showDeleteEnrollmentModal}
        closeModal={() => setShowDeleteEnrollmentModal(false)}
        onDeny={() => setShowDeleteEnrollmentModal(false)}
        denyMessage='Cancel'
        onProceed={() => handleDeleteEnrollment()}
        proceedMessage='Remove Student'
        icon={<i className='fa fa-exclamation-triangle' style={{ fontSize: '72px', color: colors.secondaryColor }} />}
        title='Are you sure?'
        message='This will remove the student from this event.'
      />

      <ConfirmationModal2
        showModal={showLoadScoresModal}
        closeModal={() => setShowLoadScoresModal(false)}
        onDeny={startFromScratch}
        denyMessage='Start from Scratch'
        onProceed={loadScores}
        proceedMessage='Load Scores'
        icon={EditIconComponent}
        title='Load draft scores?'
        message='There are draft scores saved for this event. Do you want to load them?'
      />
    </div>
  )
}

EventDetail.propTypes = {
  eventId: PropTypes.number,
}

export default EventDetail
