import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { createLead } from '../../actions/leadsActions'
import { ReactComponent as CheckCircleWhiteIcon } from '../../assets/images/common/icons/check-circle-white.svg'

import Modal from '../common/Modal'
import Input from '../common/form/Input'
import MCElement from '../common/MCElement'
import FieldError from '../common/FieldError'
import SubmitButton from '../common/buttons/SubmitButton'
import colors from '../../assets/styles/globals.scss'

import classes from './RequestLocationModal.module.scss'

const INTERESTS = { adult: 'Adult Programs', junior: 'Junior Programs', other: 'Other' }
const SKILL_LEVELS = { beginner: 'Beginner', intermediate: 'Intermediate', advanced: 'Advanced' }

const customErrorMessages = {
  location: 'Please enter the location.',
  familyMembersCount: 'Please select the number of interested family members.',
  interests: 'Please select at least one interest.',
  skillLevels: 'Please select at least one skill level.',
}

const RequestLocationModal = ({ isOpen, onClose, address }) => {
  const dispatch = useDispatch()

  const user = useSelector(state => state.user.current_user)

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [location, setLocation] = useState(address ?? '')
  const [familyMembersCount, setFamilyMembersCount] = useState('')
  const [interests, setInterests] = useState({})
  const [skillLevels, setSkillLevels] = useState({})
  const [note, setNote] = useState('')
  const [addToEmailList, setAddToEmailList] = useState(true)
  const [errors, setErrors] = useState({})

  const invalidFields = {
    location: location === '',
    familyMembersCount: familyMembersCount === '',
    interests: Object.values(interests).filter(value => value).length === 0,
    skillLevels: Object.values(skillLevels).filter(value => value).length === 0,
  }

  const formValid = () => {
    const errors = {};

    ['location', 'familyMembersCount', 'interests', 'skillLevels'].forEach((type) => {
      if (invalidFields[type]) {
        errors[type] = customErrorMessages[type]
      }
    })

    setErrors(errors)

    return Object.keys(errors).length === 0
  }

  const handleSubmit = () => {
    if (formValid()) {
      setLoading(true)

      const leadData = {
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        phone: user.phone,
        location: location,
        numGolfers: familyMembersCount,
        skillLevels: Object.entries(skillLevels).filter(([_, value]) => value).map(([key]) => key),
        interests: Object.entries(interests).filter(([_, value]) => value).map(([key]) => key),
        note: note,
        optIn: addToEmailList,
      }

      dispatch(createLead(leadData)).unwrap()
        .then(() => {
          setLoading(false)
          setSuccess(true)
        })
    }
  }

  const exitModal = () => {
    setLocation('')
    setFamilyMembersCount('')
    setInterests({})
    setSkillLevels({})
    setNote('')
    setAddToEmailList(true)
    setErrors({})
    setSuccess(false)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} className={classNames(classes.requestLocationModal, 'd-flex flex-column')} onClose={exitModal}>
      <main className={classNames(classes.modalContent, 'd-flex flex-column justify-content-center h-100')}>
        {success ? (
          <>
            <CheckCircleWhiteIcon className={classNames(classes.checkCircleWhiteIcon, 'align-self-center')} />

            <h1 className={classNames(classes.successTitle, 'mt-1')}>Success</h1>

            <h6 className={classNames(classes.successSubtitle, 'mt-3')}>
              Thanks for letting us know about your interest! Someone from Operation 36 support team will reach out to you soon.
            </h6>

            <SubmitButton
              onClick={exitModal}
              buttonMessage='Exit'
              buttonColor='navy-button'
              className='mt-5'
            />
          </>
        ) : (
          <>
            <h3 className={classes.title}>Request a Location</h3>
            <h6 className={classNames(classes.subtitle, 'mt-3 mb-0')}>
              No facility nearby? Don&apos;t worry! Please tell us a bit about yourself and
              what you&apos;re looking for, and we&apos;ll try to set up a location near you.
            </h6>

            <label htmlFor='location'>Location</label>
            <Input
              name='location'
              type='text'
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              error={errors.location}
            />

            <label htmlFor='familyMembersCount'>Number of Family Members interested</label>
            <section className={classNames('d-flex align-items-center justify-content-between')}>
              {['1', '2', '3', '4', '5+'].map((memberCount) => (
                <article key={memberCount} className='d-flex flex-column align-items-center'>
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                  <div onClick={() => setFamilyMembersCount(memberCount)}>
                    <MCElement
                      type='radio'
                      primaryColor={familyMembersCount === memberCount ? colors.secondaryColor : colors.lightNavy}
                      isSelected={familyMembersCount === memberCount}
                      onKeyDown={(e) => e.key === 'Enter' && (() => setFamilyMembersCount(memberCount))}
                    />
                  </div>

                  <label className='mt-2'>{memberCount}</label>
                </article>
              ))}
            </section>
            {errors.familyMembersCount && <FieldError message={errors.familyMembersCount} />}

            <div className='row mb-2'>
              <section className='col-6'>
                <label htmlFor='interests' className='mb-0'>Interests</label>
                {Object.keys(INTERESTS).map((interest, index) => (
                  <article key={index} className='d-flex align-items-center mt-3'>
                    <MCElement
                      type='checkbox'
                      isSelected={interests[interest]}
                      clicked={() => setInterests({ ...interests, [interest]: !interests[interest] })}
                      primaryColor={interests[interest] ? colors.primaryOrange : colors.lightNavy}
                      secondaryColor={colors.whiteColor}
                    />

                    <span className='ml-2'>{INTERESTS[interest]}</span>
                  </article>
                ))}
                {errors.interests && <FieldError message={errors.interests} />}
              </section>
              <section className='col-6'>
                <label htmlFor='skillLevels' className='mb-0'>Skill Level</label>
                {Object.keys(SKILL_LEVELS).map((skillLevel, index) => (
                  <article key={index} className='d-flex align-items-center mt-3'>
                    <MCElement
                      type='checkbox'
                      isSelected={skillLevels[skillLevel]}
                      clicked={() => setSkillLevels({ ...skillLevels, [skillLevel]: !skillLevels[skillLevel] })}
                      primaryColor={skillLevels[skillLevel] ? colors.secondaryColor : colors.lightNavy}
                      secondaryColor={colors.whiteColor}
                    />

                    <span className='ml-2'>{SKILL_LEVELS[skillLevel]}</span>
                  </article>
                ))}
                {errors.skillLevels && <FieldError message={errors.skillLevels} />}
              </section>
            </div>

            <label htmlFor='note'>Tell us anything else you would like us to know</label>
            <Input
              name='note'
              type='textarea'
              value={note}
              onChange={(e) => setNote(e.target.value)}
              styles={{ inputContainer: { height: '150px' } }}
              maxLength={500}
            />

            <section className='d-flex align-items-center mt-3 mb-2'>
              <MCElement
                type='checkbox'
                isSelected={addToEmailList}
                clicked={() => setAddToEmailList(prev => !prev)}
                primaryColor={addToEmailList ? colors.primaryOrange : colors.lightNavy}
                secondaryColor={colors.whiteColor}
              />
              <span className='ml-2'>Add me to the Operation36 email list</span>
            </section>

            <SubmitButton
              onClick={handleSubmit}
              className={classNames(classes.submitButton, 'align-self-center mt-2')}
              buttonMessage='Submit'
              buttonColor='orange-button'
              loading={loading}
              disabled={loading || !formValid}
            />
          </>
        )}
      </main>
    </Modal>
  )
}

RequestLocationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  address: PropTypes.string,
}

export default RequestLocationModal
