import * as types from "../actions/actionTypes";
// import update from 'immutability-helper';
// state = [] : Start out with no accounts
export default function lessonPlans(state = [], action) {
  switch (action.type) {
    case types.GRAB_LESSON_PLANS:
      return Object.assign({}, state, {
        all_plans: action.lesson_plans.filter(lp => !lp.is_pinned),
        finished: action.lesson_plans.filter(
          lp => !lp.is_draft && !lp.is_pinned
        ),
        drafts: action.lesson_plans.filter(lp => lp.is_draft),
        pinned: action.lesson_plans.filter(lp => lp.is_pinned),
        in_use: action.lesson_plans.filter(lp => lp.in_use && !lp.is_pinned),
        community_lesson_plans: action.community_lesson_plans, // this holds the whole community's lesson plans - including the current user's
        really_all_plans: action.lesson_plans // for Lesson plan builder to be able to search every lesson plan when loading a specific plan to edit / copy
      });

    // case types.CREATE_LESSON_PLAN_DRAFT:
    //   return Object.assign({}, state, {
    //     drafts: [...state.drafts, action.draft]
    //   });

    case types.LESSON_PLAN_PIN:
      let status = action.lesson_plan.is_pinned;
      if (status) {
        return Object.assign({}, state, {
          pinned: [...state.pinned, action.lesson_plan],
          all_plans: state.all_plans.filter(
            lp => lp.id !== action.lesson_plan.id
          ),
          finished: state.finished.filter(
            lp => lp.id !== action.lesson_plan.id
          ),
          in_use: state.in_use.filter(lp => lp.id !== action.lesson_plan.id)
        });
      } else {
        let newState = Object.assign({}, state, {
          pinned: state.pinned.filter(lp => lp.id !== action.lesson_plan.id),
          finished: [...state.finished, action.lesson_plan],
          all_plans: [...state.all_plans, action.lesson_plan]
        });
        if (action.lesson_plan.in_use) {
          newState.in_use = [...state.in_use, action.lesson_plan];
        }
        return newState;
      }

    case types.DELETE_LESSON_PLAN:
      return Object.assign({}, state, {
        all_plans: state.all_plans.filter(
          lp => lp.id !== action.lesson_plan_id
        ),
        finished: state.finished.filter(lp => lp.id !== action.lesson_plan_id),
        drafts: state.drafts.filter(lp => lp.id !== action.lesson_plan_id),
        pinned: state.pinned.filter(lp => lp.id !== action.lesson_plan_id),
        in_use: state.in_use.filter(lp => lp.id !== action.lesson_plan_id),
        previews: state.previews?.filter(lp => lp.id !== action.lesson_plan_id),
        really_all_plans: state.really_all_plans.filter(
          lp => lp.id !== action.lesson_plan_id
        ), // for Lesson plan builder to be able to search every lesson plan when loading a specific plan to edit / copy
        community_lesson_plans: state.community_lesson_plans.filter(
          lp => lp.id !== action.lesson_plan_id
        )
      });

    case types.GRAB_ONE_LESSON_PLAN:
      return Object.assign({}, state, {
        selected_plan: action.lesson_plan
      });

    case types.GRAB_LP_TEMPLATES:
      return Object.assign({}, state, {
        op36Templates: action.templates
      });

    default:
      return state;
  }
}
