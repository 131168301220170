import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { without } from 'lodash'

import { selectAllCommunityStudents } from '../../../../reducers/programDashboardReducer'
import StudentCard from './StudentCard'
import PropTypes from 'prop-types'

const AddStudents = ({ activeMembersIds, setActiveMembersIds }) => {
  const { program } = useSelector(state => state.programDashboard)
  const allCommunityStudents = useSelector(selectAllCommunityStudents)

  const handleCheckStudent = useCallback((userId, isChecked) => () => {
    setActiveMembersIds(allIds => {
      if (isChecked) {
        return without(allIds, userId)
      } else {
        return [...allIds, userId]
      }
    })
  }, [setActiveMembersIds])

  return (
    <>
      {allCommunityStudents
        .map((user, index) => {
          const isChecked = activeMembersIds.includes(user.id)

          return (
            <StudentCard
              key={index}
              user={user}
              isChecked={isChecked}
              onClick={handleCheckStudent}
              programId={program.id}
            />
          )
        })
      }
    </>
  )
}

AddStudents.propTypes = {
  activeMembersIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  setActiveMembersIds: PropTypes.func.isRequired,
}

export default AddStudents
