import React from "react";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom'
import { ReactComponent as OutsideLinkIcon } from '../../../assets/images/common/icons/outside-link-icon.svg'
import { ReactComponent as TableSelectDropDownIcon } from '../../../assets/images/common/icons/drop-down-arrow.svg'
import { UncontrolledPopover } from 'reactstrap'
import colors from '../../../assets/styles/globals.scss'
import "./PageHeader.scss";

// import calendarIcon from "./icons/calendar.png";
// import gamepadIcon from "./icons/gamepad.png";
// import learnIcon from "./icons/learn.png";
// import peopleIcon from "./icons/people.png";
// import accountIcon from "./icons/account-dark.png";
// import emailIcon from "./icons/email-dark.png";
// import familiesIcon from "./icons/families.png";
// import programsIcon from "./icons/programs-dark.png";
// import resourcesIcon from "./icons/resources.png";
// import studentsIcon from "./icons/students-dark.png";
// import activitiesIcon from "./icons/activity-dark.png";

let iconNames = [
  "calendar",
  "gamepad",
  "learn",
  "people",
  "account",
  "email",
  "families",
  "programs",
  "resources",
  "students",
  "activities"
];

const PageHeader = ({
  title,
  subtitle,
  backButton,
  // icon,
  // headerIcon,
  buttonIcon,
  buttonIcon1,
  buttonIcon2,
  buttonLabel,
  buttonLabel1,
  buttonLabel2,
  // onClickBackButton,
  onClickButton,
  onClickButton1,
  onClickButton2,
  // customCSS,
  questionButton,
  onClickQuestionButton,
  community_id,
  showCopyProgramModal,
  isBuilderRedesignFeatureEnabled,
}) => {
  let Button;
  let Button1;
  let Button2;
  let QuestionButton;
  // let backButton;

  if (!!buttonLabel) {
    Button = (
      <button
        className="btn btn-primary text-white pull-right mr-2"
        onClick={() => onClickButton()}
      >
        <i className={buttonIcon} aria-hidden="true" /> {buttonLabel}
      </button>
    );
  }

  if (!!buttonLabel1) {
    Button1 = (
      <button
        className="btn btn-tertiary text-white pull-right mr-2"
        onClick={() => onClickButton1()}
      >
        <i className={buttonIcon1} aria-hidden="true" /> {buttonLabel1}
      </button>
    );
  }

  if (!!buttonLabel2) {
    Button2 = (
      <button
        className="btn btn-danger pull-right mr-2"
        onClick={() => onClickButton2()}
      >
        <i className={buttonIcon2} aria-hidden="true" /> {buttonLabel2}
      </button>
    );
  }

  if (questionButton) {
    QuestionButton = (
      <button
        className="btn btn-link mr-2 question-button"
        onClick={onClickQuestionButton}
      >
        <i className="fa fa-question" aria-hidden="true" />
      </button>
    );
  }

  if (!!backButton) {
    backButton = (
      <a href="#" className="btn btn-white rounded">
        <i className="fa fa-long-arrow-left" aria-hidden="true" />{" "}
        <span className="d-none d-md-inline">Back</span>
      </a>
    );
  }

  return (
    <div>
      <div className="page-header mb-15px">
        <div className="row">
          <div className="col-12 d-flex align-items-center">
            <div>{backButton && backButton}</div>
            <div className="row p-0 m-0 w-100 d-flex align-items-center mb-2">
              <div className='p-0 m-0 col-6 header-title-container'>
                {/* {headerIcon ? (
                  <i
                    className={`${headerIcon} fa-4x fa-header-icon d-block float-left`}
                    aria-hidden="true"
                  />
                ) : (
                  <img
                    className="header-icon"
                    alt="header-icon"
                    src={getIcon(icon)}
                  />
                )} */}
                <h1 className={`header-title`}>{title}</h1>
                <br />
                <h3 className="d-block">{subtitle}</h3>
              </div>
              <div
                className={`p-0 m-0 col-6 header-button-container ${Button &&
                  !Button1 &&
                  !Button2 &&
                  'pt-2'}`}
              >
                {community_id && (
                  <>
                    <Link to={`/landing/${community_id}`} target='_blank' className='d-flex align-items-center view-community-link'>
                      <OutsideLinkIcon id='outsideLinkIcon'/>
                      <span className='view-community-label'>View Community Landing Page</span>
                    </Link>

                    {isBuilderRedesignFeatureEnabled && (
                      <div>
                        <button id='buildProgramButton' className='btn btn-primary pl-3 pr-3'>
                          <div className='d-flex align-items-center'>
                            <span className='mr-2'>Build a Program</span>
                            <TableSelectDropDownIcon stroke={colors.lightNavy} style={{ marginLeft: '2px' }}/>
                          </div>
                        </button>
                        {!showCopyProgramModal &&
                          <UncontrolledPopover
                            placement='bottom'
                            target='buildProgramButton'
                            trigger='legacy'
                            popperClassName='program-popover-style'
                          >
                            <div className='d-flex flex-column build-program-actions'>
                              <Link to={'/programs/new'}>Build a Program</Link>
                              <p aria-hidden='true' onClick={onClickButton}>Copy Existing Program</p>
                            </div>
                          </UncontrolledPopover>
                        }
                      </div>
                    )}
                  </>
                )}
                {Button && Button}
                {Button1 && Button1}
                {Button2 && Button2}
                {QuestionButton && QuestionButton}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

PageHeader.propTypes = {
  subtitle: PropTypes.string,
  backButton: PropTypes.bool,
  title: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(iconNames),
  community_id: PropTypes.number,
  showCopyProgramModal: PropTypes.bool,
  isBuilderRedesignFeatureEnabled: PropTypes.bool,
}

export default PageHeader
