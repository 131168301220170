import * as constants from "./actionTypes";
import { createAsyncThunk } from '@reduxjs/toolkit'

// import CONSENT_VERSIONS from '../constants/consentVersions';

import Axios from '../axios'
import { deserialize, serialize } from '../utility/serialization'
import { handleAPIError } from '../utility/actionHelper'

function successRequestingCommunityLeads(response) {
  if (response.status === 200) {
    return { type: constants.GET_LEADS, leads: response.data.leads };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successDeletingLead(response) {
  if (response.status === 200) {
    return { type: constants.DELETE_LEAD, lead: response.data.id };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successRequestingLeadNotes(response) {
  if (response.status === 200) {
    return { type: constants.GET_LEAD_NOTES, notes: response.data.notes };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successCreatingLeadNote(response) {
  if (response.status === 201) {
    return { type: constants.CREATE_LEAD_NOTE, note: response.data.note };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successUpdatingCommunityLead(response) {
  if (response.status === 200) {
    return { type: constants.UPDATE_LEAD, lead: response.data.lead };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successGettingAdminCounts(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_ADMIN_COUNTS,
      admin_counts: response.data.admin_counts
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export const createLead = createAsyncThunk(
  'leads/createLead',
  async (leadData, { rejectWithValue }) => {
    try {
      const { data } = await requestCreateLead(leadData)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)


export function updateCommunityLead(leadId, data) {
  return function(dispatch) {
    return updateLead(leadId, data).then(response =>
      dispatch(successUpdatingCommunityLead(response))
    );
  };
}

export function getCommunityLeads(communityId) {
  return function(dispatch) {
    return requestCommunityLeads(communityId).then(response =>
      dispatch(successRequestingCommunityLeads(response))
    );
  };
}

export function deleteLead(leadId) {
  return function(dispatch) {
    return requestLeadDelete(leadId).then(response =>
      dispatch(successDeletingLead(response))
    );
  };
}

export function getLeadNotes(leadId) {
  return function(dispatch) {
    return requestLeadNotes(leadId).then(response =>
      dispatch(successRequestingLeadNotes(response))
    );
  };
}

export function createLeadNote(data) {
  return function(dispatch) {
    return createNote(data).then(response => {
      dispatch(successCreatingLeadNote(response));
    });
  };
}

export function adminLeadCounts() {
  return function(dispatch) {
    return getAdminLeadCounts().then(response =>
      dispatch(successGettingAdminCounts(response))
    );
  };
}

const requestCreateLead = (data) => {
  const requestUrl = '/api/v1/leads'

  return Axios.post(requestUrl, serialize(data))
}

function updateLead(leadId, data) {
  let requestUrl = "/api/v1/leads/" + leadId;
  return Axios.put(requestUrl, data);
}

function requestCommunityLeads(communityId) {
  let requestUrl = "/api/v1/leads?community_id=" + communityId;
  return Axios.get(requestUrl);
}

function requestLeadDelete(leadId) {
  let requestUrl = "/api/v1/leads/" + leadId;
  return Axios.delete(requestUrl);
}

function requestLeadNotes(leadId) {
  let requestUrl = "/api/v1/lead_notes?lead_id=" + leadId;
  return Axios.get(requestUrl);
}

function createNote(data) {
  let requestUrl = "/api/v1/lead_notes";
  return Axios.post(requestUrl, data);
}

function getAdminLeadCounts() {
  let requestUrl = "/api/v1/leads/admin_counts";
  return Axios.get(requestUrl);
}
