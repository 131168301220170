import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { UncontrolledPopover } from 'reactstrap'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './RegistrationActions.scss'

import { ReactComponent as EllipsisIcon } from '../../../../assets/images/common/icons/ellipsis-icon.svg'
import * as enrollmentActions from '../../../../actions/enrollmentActions'
import RefundEnrollmentModal from './modals/RefundEnrollmentModal'
import DropEnrollmentModal from './modals/DropEnrollmentModal'
import ApproveEnrollmentModal from './modals/ApproveEnrollmentModal'

const RegistrationActions = ({ course, enrollment }) => {
  const dispatch = useDispatch()

  const paidOnline = enrollment.paymentStatus === 'paid_online'
  const [actionLoader, setActionLoader] = useState(false)
  const [coachMessage, setCoachMessage] = useState('')
  const [showRefundEnrollmentModal, setShowRefundEnrollmentModal] = useState(false)
  const [showDropEnrollmentModal, setShowDropEnrollmentModal] = useState(false)
  const [showApproveEnrollmentModal, setShowApproveEnrollmentModal] = useState(false)
  const [notifyViaEmail, setNotifyViaEmail] = useState(true)

  const handleCoachMessageChange = e => {
    setCoachMessage(e.target.value)
  }

  const handleApproveEnrollment = () => {
    setActionLoader(true)
    setShowApproveEnrollmentModal(false)
    dispatch(enrollmentActions.updateEnrollment({ enrollmentData: { id: enrollment.id, status: 'active' }, coachMessage })).unwrap()
      .catch(() => toast.error('Something went wrong. Please try again.', { position: toast.POSITION.TOP_RIGHT }))
      .finally(() => {
        setActionLoader(false)
        setCoachMessage('')
      })
  }

  const handleRemoveEnrollment = (refund) => {
    setActionLoader(true)
    setShowDropEnrollmentModal(false)
    setShowRefundEnrollmentModal(false)
    dispatch(enrollmentActions.deleteEnrollment({ enrollmentId: enrollment.id, refund, coachMessage, notifyViaEmail })).unwrap()
      .then(() =>
        toast.success(`Enrollment ${enrollment.status === 'active' ? 'dropped' : 'declined'} successfully.`, { position: toast.POSITION.TOP_RIGHT })
      )
      .catch(() => toast.error('Something went wrong. Please try again.', { position: toast.POSITION.TOP_RIGHT }))
      .finally(() => {
        setActionLoader(false)
        setCoachMessage('')
      })
  }

  return (
    <div id='RegistrationActions' className='d-flex justify-content-center align-items-center'>
      {enrollment.status === 'applied' ? (
        <>
          <p
            className={classNames('my-1 approve-button', { 'disabled': actionLoader })}
            onClick={() => setShowApproveEnrollmentModal(true)}
            aria-hidden='true'
          >
            Approve
          </p>

          <p
            className={classNames('ml-auto decline-button', { 'disabled': actionLoader })}
            onClick={() => setShowDropEnrollmentModal(true)}
            aria-hidden='true'
          >
            Decline
          </p>
        </>
      ) : (
        <>
          <EllipsisIcon id={`ellipsisIcon${enrollment.id}`} className='ellipsis-icon' />
          {!actionLoader && !showDropEnrollmentModal && !showRefundEnrollmentModal &&
            <UncontrolledPopover
              placement='bottom'
              target={`ellipsisIcon${enrollment.id}`}
              trigger='legacy'
              popperClassName='popover-style'
            >
              <div className='d-flex flex-column enrollment-actions'>
                <Link to={`/profile/${enrollment.user.id}`}>See Profile</Link>
                {!course.isCompleted &&
                  <p
                    onClick={() => paidOnline ? setShowRefundEnrollmentModal(true) : setShowDropEnrollmentModal(true)}
                    aria-hidden='true'
                  >
                    Drop Enrollment
                  </p>
                }
              </div>
            </UncontrolledPopover>
          }
          <RefundEnrollmentModal
            showModal={showRefundEnrollmentModal}
            closeModal={() => { setShowRefundEnrollmentModal(false); setCoachMessage('') }}
            coachMessage={coachMessage}
            handleCoachMessageChange={handleCoachMessageChange}
            handleRemoveEnrollment={handleRemoveEnrollment}
            enrollment={enrollment}
          />
        </>
      )}
      <DropEnrollmentModal
        notifyViaEmail={notifyViaEmail}
        handleCheckboxChange={() => setNotifyViaEmail(!notifyViaEmail)}
        showModal={showDropEnrollmentModal}
        closeModal={() => { setShowDropEnrollmentModal(false); setCoachMessage('') }}
        coachMessage={coachMessage}
        handleCoachMessageChange={handleCoachMessageChange}
        handleRemoveEnrollment={() => handleRemoveEnrollment(false)}
        enrollment={enrollment}
      />
      <ApproveEnrollmentModal
        showModal={showApproveEnrollmentModal}
        closeModal={() => { setShowApproveEnrollmentModal(false); setCoachMessage('') }}
        coachMessage={coachMessage}
        handleCoachMessageChange={handleCoachMessageChange}
        handleApproveEnrollment={handleApproveEnrollment}
      />
    </div>
  )
}

RegistrationActions.propTypes = {
  course: PropTypes.shape({
    id: PropTypes.number,
    isCompleted: PropTypes.bool,
  }),
  enrollment: PropTypes.shape({
    id: PropTypes.number,
    paymentStatus: PropTypes.oneOf(['paid', 'invoiced', 'outstanding', 'paid_online']),
    status: PropTypes.oneOf(['applied', 'active', 'suspended', 'completed', 'pending']),
    user: PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }),
}

export default RegistrationActions
