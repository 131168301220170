import PropTypes from 'prop-types'
import classNames from 'classnames'
import StudentRow from './StudentRow'
import InfiniteScroll from '../../infinite-scroll/InfiniteScroll'

import LoadingSpinner from '../LoadingSpinner'
import styles from './LeaderboardRanking.module.scss'
import { ReactComponent as GolferIcon } from '../../../assets/images/common/communityLeaderboard/golfer-half-icon.svg'

const StatsItem = ({ icon, text, data }) => (
  <div className={styles.leaderboardStatsItem}>
    {icon}
    <div className='ml-2'>
      <div className={styles.data}>{data}</div>
      <div className={styles.text}>{text}</div>
    </div>
  </div>
)

StatsItem.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.string,
  data: PropTypes.number,
}

const LeaderboardRanking = ({
  rankingStudents,
  withStats,
  stats,
  statsItems,
  fetchData,
  withClearFiltersButton,
  onClearFiltersClick,
  pagination,
}) => {
  const hasMore = !pagination?.totalPages || pagination?.nextPage <= pagination?.totalPages

  if (withStats ? !rankingStudents || !stats : !rankingStudents) {
    return (
      <LoadingSpinner
        text='Loading Ranking...'
        classNames={{
          wrapper: 'h-100 pt-0',
          spinner: 'fa-3x',
        }}
      />
    )
  }

  return (
    <div id='leaderboardRanking' className={classNames('py-3', styles.leaderboardRanking)}>
      {statsItems &&
        <div className='d-flex justify-content-around align-items-center'>
          {statsItems.map((item, index) => (
            <StatsItem key={index} icon={item.icon} text={item.text} data={stats[item.key]} />
          ))}
        </div>
      }
      {rankingStudents.length === 0 ? (
        <div className={classNames('d-flex flex-column justify-content-center align-items-center text-center', styles.noActivities)}>
          <GolferIcon />
          <h3 className={classNames('my-3', styles.noActivitiesTitle)}>No Activities</h3>
          <p className={styles.noActivitiesText}>
            There is no activity in the selected range. Please adjust your filters or check back later after your programs start.
          </p>
          {withClearFiltersButton &&
            <button
              className={classNames('ml-3 w-30 mt-3 animation-shrink d-flex align-items-center justify-content-center', styles.resetFiltersButton)}
              onClick={onClearFiltersClick}
            >
              Clear Filters<i className='ml-2 fa fa-close' />
            </button>
          }
        </div>
      ) : (
        <InfiniteScroll
          dataLength={rankingStudents.length}
          next={() => fetchData(pagination.nextPage)}
          hasMore={hasMore}
          scrollableTarget='leaderboardRanking'
        >
          {rankingStudents.map((student, index) =>
            <StudentRow
              key={student.userId}
              rank={index + 1}
              profile={student.avatar?.fileUrl}
              name={student.name}
              points={student.points}
            />
          )}
        </InfiniteScroll>
      )}
    </div>
  )
}

LeaderboardRanking.propTypes = {
  rankingStudents: PropTypes.array,
  stats: PropTypes.object,
  statsItems: PropTypes.array,
  fetchData: PropTypes.func,
  withClearFiltersButton: PropTypes.bool,
  onClearFiltersClick: PropTypes.func,
  pagination: PropTypes.object,
}

export default LeaderboardRanking
