import { useSelector } from 'react-redux'
import { pluralize } from '../../../../utility/helperFunctions'
import classnames from 'classnames'
import GolfersTable from './GolfersTable'

import classes from './EnrollmentsTab.module.scss'
import { ReactComponent as InfoCircleIcon } from '../../../../assets/images/common/icons/info-circle-icon-outline.svg'
import { ReactComponent as GolferHalfIcon } from '../../../../assets/images/common/icons/golfer-half-icon.svg'

const EnrollmentsTab = () => {
  const { program } = useSelector(state => state.programDashboard)

  return (
    <div className={classnames('pt-3 d-flex flex-column', classes.enrollmentsTab)}>
      <div className='ml-3 d-flex align-items-center'>
        <span className={classes.userCount}>
          {`${program.enrollments.length}`}&nbsp;
          {`${program.golferLimit ? `/ ${program.golferLimit}` : ''}`}&nbsp;
          {program.golferLimit ? pluralize('Golfer', program.golferLimit) : pluralize('Golfer', program.enrollments.length)}
        </span>
        <InfoCircleIcon role='button' className={classnames('ml-2', classes.icon)} />
      </div>
      {program.enrollments.length === 0 ? (
        <div className={classnames('d-flex flex-column align-items-center', classes.placeholder)}>
          <GolferHalfIcon className={classes.icon} />
          <h2 className={classes.title}>No Enrollments Yet</h2>
          <p className={classes.text}>Share this program with the students in your community!</p>
        </div>
      ) : (
        <GolfersTable course={program} />
      )}
    </div>
  )
}

export default EnrollmentsTab
