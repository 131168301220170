import PropTypes from 'prop-types'
import { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import * as Constants from '../../../misc/Constants'
import { yardageByLevel, fullTeeYardageByLevel } from '../../../utility/curriculumHelper'
import { getEventRosterProgram } from '../../../actions/programActions'
import { getEventCourses } from '../../../actions/userActions'
import { getAllStudents } from '../../../actions/communitiesActions'

import StudentCard from '../../students/StudentCard/StudentCard'
import StateSwitcher from '../../common/StateSwitcher'
import SearchInput from '../../common/SearchInput'
import CommunityProgramsList from './CommunityProgramsList'
import CommunityStudentsList from './CommunityStudentsList'

import './ManageRoster.scss'
import colors from '../../../assets/styles/globals.scss'
import { ReactComponent as PersonIcon } from '../../../assets/images/common/icons/person-icon.svg'

const perPage = Constants.PER_PAGE_DEFAULT

// eslint-disable-next-line max-lines-per-function
const ManageRoster = ({
  isQuickLog,
  students,
  courses,
  switcherSelected,
  setSwitcherSelected,
  addStudent,
  deleteStudent,
  addCourse,
  deleteCourse,
  updateStudent,
  updateCourse,
  seriesRoster,
}) => {
  const dispatch = useDispatch()
  const studentsLoader = useSelector(state => state.communityStudents.loading)
  const communityStudents = useSelector(state => state.communityStudents.students)
  const communityCoursesFetched = useSelector(state => !!state.courses.courses)
  const currentUser = useSelector(state => state.user.current_user)

  const [switcherState, setSwitcherState] = useState('students')
  const [coursesSearch, setcoursesSearch] = useState('')
  const [studentsSearch, setStudentsSearch] = useState('')
  const [coursesLoader, setCoursesLoader] = useState(true)

  const programsSelected = (switcherState === 'programs')

  const fetchCourses = useCallback((page = null, perPage = null, searchTerm = null) => {
    setCoursesLoader(true)
    dispatch(getEventCourses({
      communityId: currentUser.communities[0].id,
      withActiveEnrollments: isQuickLog,
      page,
      perPage,
      search: searchTerm,
    })).finally(() => setCoursesLoader(false))
  }, [dispatch, currentUser.communities, isQuickLog])

  const fetchStudents = useCallback((page = null, perPage = null, searchTerm = null) => {
    dispatch(getAllStudents(currentUser.communities[0].id, page, perPage, searchTerm, 'infiniteScroll'))
  }, [dispatch, currentUser.communities])

  const switcherToggle = () => {
    if (programsSelected) {
      setSwitcherState('students')
    } else {
      setSwitcherState('programs')
    }
  }

  useEffect(() => {
    if (switcherSelected) {
      setSwitcherState(switcherSelected)
      setSwitcherSelected('')
    }
  }, [switcherSelected, setSwitcherSelected])

  useEffect(() => {
    if (studentsLoader && communityStudents.length === 0) {
      fetchStudents(1, perPage)
    }

  }, [fetchStudents, studentsLoader, communityStudents])

  useEffect(() => {
    if (!communityCoursesFetched) {
      fetchCourses(1, perPage)
    }
  }, [fetchCourses, communityCoursesFetched])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchStudents = useCallback(
    _.debounce((value) => {
      fetchStudents(1, perPage, value)
    }, 400),
    []
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchCourses = useCallback(
    _.debounce((value) => {
      fetchCourses(1, perPage, value)
    }, 400),
    []
  )

  const searchUpdated = (e) => {
    const value = e.target.value
    if (programsSelected) {
      setcoursesSearch(value)
      debouncedSearchCourses(value)
    } else {
      setStudentsSearch(value)
      debouncedSearchStudents(value)
    }
  }

  const handleAddStudent = (std, course = null, pkg = null) => {
    if (std.id > 0) {
      const currentFormalDivision = std.currentFormalDivision || std.current_formal_division
      const studentData = {
        user_id: std.id,
        first_name: std.firstName || std.first_name,
        last_name: std.lastName || std.last_name,
        current_formal_division: currentFormalDivision,
        level: currentFormalDivision,
        stats: std.stats,
        yardage: yardageByLevel[currentFormalDivision],
        fullTeeYardage: fullTeeYardageByLevel[currentFormalDivision],
        avatar: std.avatar,
        age: std.age,
      }
      const index = students.findIndex(s => s.user_id === std.id)
      if (index === -1) {
        if (course?.id) {
          if (pkg?.id) {
            dispatch(addStudent({ ...studentData, addedFrom: { [course.id]: { [pkg.id]: { packageName: pkg.name, courseName: course.name } } } }))
          } else {
            dispatch(addStudent({ ...studentData, addedFrom: { [course.id]: course.name } }))
          }
        } else {
          dispatch(addStudent({ ...studentData, addedFrom: { students: true } }))
        }
      } else {
        if (course?.id) {
          if (pkg?.id) {
            dispatch(updateStudent({ ...studentData, addedFrom: {
              ...students[index].addedFrom,
              [course.id]: students[index].addedFrom[course.id]
                ? { [pkg.id]: { packageName: pkg.name, courseName: course.name } }
                : { ...students[index].addedFrom[course.id], [pkg.id]: { packageName: pkg.name, courseName: course.name } },
            } }))
          } else {
            dispatch(updateStudent({ ...studentData, addedFrom: { ...students[index].addedFrom, [course.id]: course.name } }))
          }
        } else {
          dispatch(updateStudent({ ...studentData, addedFrom: { ...students[index].addedFrom, students: true } }))
        }
      }
    }
  }

  const handleDeleteStudent = (std, course = null, packageId = null, lastPackage) => {
    if (course?.id) {
      const index = students.findIndex(s => s.user_id === std)
      if (index === -1) {
        return
      }
      const from = { ...students[index].addedFrom }
      if (packageId) {
        const fromCourse = { ...from[course.id] }
        delete fromCourse[packageId]
        if (_.isEmpty(fromCourse)) {
          lastPackage ? from[course.id] = course.name : delete from[course.id]
        }
      } else {
        delete from[course.id]
      }
      if (_.isEmpty(from)) {
        dispatch(deleteStudent(std))
      } else {
        dispatch(updateStudent({ ...students[index], addedFrom: from }))
      }
    } else {
      dispatch(deleteStudent(std))
    }
  }

  const handleAddCourse = (course) => {
    if (course.id > 0) {
      dispatch(addCourse(course))
    }
  }

  const handleDeleteCourse = (course) => {
    dispatch(deleteCourse(course))
  }

  const handleAddPackage = (pkg, course) => {
    if (pkg.id > 0) {
      const selectedPackages = [...course.selectedPackages]
      selectedPackages.push(pkg)
      dispatch(updateCourse({ ...course, selectedPackages: selectedPackages }))
    }
  }

  const handleDeletePackage = (pkg, course) => {
    const selectedPackages = [...course.selectedPackages]
    const index = selectedPackages.findIndex(p => p.id === pkg.id)
    selectedPackages.splice(index, 1)
    dispatch(updateCourse({ ...course, selectedPackages: selectedPackages }))
  }

  const packageAdded = (pkg, course) => {
    handleAddPackage(pkg, course)
    course.enrollments.map((e) => {
      const std = e.user
      if (std.enrollments.find((e) => e.packageId === pkg.id && e.courseId === course.id)) {
        handleAddStudent(std, course, pkg)
      } else if (course.selectedPackages.length === 0){
        handleDeleteStudent(std.id, course)
      }
    })
  }

  const packageRemoved = (pkg, course) => {
    const lastPackage = course.selectedPackages.length === 1
    course.enrollments.map((e) => {
      const std = e.user
      if (std.enrollments.find((e) => e.packageId === pkg.id && e.courseId === course.id)) {
        handleDeleteStudent(std.id, course, pkg.id, lastPackage)
      } else if (lastPackage) {
        handleAddStudent(std, course)
      }
    })
    handleDeletePackage(pkg, course)
  }

  const coursesCheckBoxToggle = (checked, course) => {
    if (checked) {
      const courseIndex = courses.findIndex(c => c.id === course.id)
      courses[courseIndex].enrollments.map((e) => {
        handleDeleteStudent(e.userId, course)
      })
      handleDeleteCourse(course.id)
    } else {
      getEventRosterProgram(course.id).then(response => {
        const course = response.course
        handleAddCourse(course)
        course.enrollments.map((e) => {
          const std = e.user
          handleAddStudent(std, course)
        })
      })
    }
  }

  const studentsCheckBoxToggle = (checked, id) => {
    const studentIndex = communityStudents.findIndex(s => s.id === id)
    if (checked) {
      handleDeleteStudent(id)
    } else {
      handleAddStudent(communityStudents[studentIndex])
    }
  }

  return (
    <div id='ManageRoster' className='d-flex justify-content-center'>
      <div className='col-6 populate-roster'>
        <p className='pl-3 large-text'>Populate {seriesRoster && 'Master'} Roster</p>
        <div>
          <StateSwitcher
            options={Constants.SWITCHER_OPTIONS}
            initialTypeSelected={'programs'}
            selectedType={switcherState}
            stateSwitcher={switcherToggle}
            position={'left'}
          />
        </div>
        <div className='search-wrapper'>
          <p>
            Quickly populate your event roster by selecting programs/packages, or by selecting individual students.
          </p>
          <div className='mt-3 roster-search-bar'>
            <SearchInput
              placeholder={`Search for ${programsSelected ? 'Active Programs' : 'Students'}`}
              value={programsSelected ? coursesSearch : studentsSearch}
              onChange={searchUpdated}
            />
          </div>
        </div>
        <hr className='main-hr'/>
        <div id='studentsList' className='mt-3 students-list'>
          {programsSelected ?
            <CommunityProgramsList
              courses={courses}
              fetchCourses={fetchCourses}
              loader={coursesLoader}
              coursesSearch={coursesSearch}
              packageAdded={packageAdded}
              packageRemoved={packageRemoved}
              coursesCheckBoxToggle={coursesCheckBoxToggle}
            /> :
            <CommunityStudentsList
              students={students}
              fetchStudents={fetchStudents}
              loader={studentsLoader}
              studentsSearch={studentsSearch}
              studentsCheckBoxToggle={studentsCheckBoxToggle}
            />}
        </div>
      </div>
      <div className='col-6 roster-summary' >
        <div className='students-list mx-auto'>
          <p className='large-text'>{`Roster Summary (${students.length})`}</p>

          {students.length > 0 ?
            <div className='d-flex flex-wrap'>
              { students?.map((s) => (
                <StudentCard key={s.user_id} student={s} onXClick={studentsCheckBoxToggle}/>
              ))
              }
            </div>
            :
            <div className='no-students-placeholder'>
              <PersonIcon id='golferIcon' fill={colors.lightNavy} width={'64px'} height={'64px'}/>
              <p className='placeholder-title'>No Students Added</p>
              <p className='placeholder-description'>
                Add your participants individually or by program from the menu on the left.
              </p>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

ManageRoster.propTypes = {
  isQuickLog: PropTypes.bool,
  switcherSelected: PropTypes.string,
  setSwitcherSelected: PropTypes.func,
  students: PropTypes.array.isRequired,
  courses: PropTypes.array.isRequired,
  addStudent: PropTypes.func.isRequired,
  updateStudent: PropTypes.func.isRequired,
  deleteStudent: PropTypes.func.isRequired,
  addCourse: PropTypes.func.isRequired,
  updateCourse: PropTypes.func.isRequired,
  deleteCourse: PropTypes.func.isRequired,
  seriesRoster: PropTypes.bool,
}

export default ManageRoster
