import { useMemo, useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import moment from 'moment'

import SubmitButton from '../../../../common/buttons/SubmitButton'
import { displayWithCurrency } from '../../../../../utility/currency'
import { ReactComponent as WarningIcon } from '../../../../../assets/images/common/icons/warning-icon.svg'
import { ReactComponent as ModalXIcon } from '../../../../../assets/images/common/icons/modal-X-icon.svg'
import './RefundEnrollmentModal.scss'
import Input from '../../../../common/form/Input'

const RefundEnrollmentModal = ({ showModal, closeModal, coachMessage, handleCoachMessageChange, handleRemoveEnrollment, enrollment }) => {
  const [refundEnrollment, setRefundEnrollment] = useState(false)
  const transactionDetails = useMemo(() => ({
    'Golfer': `${enrollment.user.firstName} ${enrollment.user.lastName}`,
    'Date': moment(enrollment.paid_at).format('MM/DD/YYYY'),
    'Amount': displayWithCurrency(enrollment.transactionAmount, enrollment.currency),
    'Stripe Fee': displayWithCurrency(enrollment.fees.stripeFee, enrollment.convertedCurrency),
    'Application Fee': displayWithCurrency(enrollment.fees.applicationFee, enrollment.currency)
  }), [enrollment])

  const closeModalAndSetRefund = () => {
    setRefundEnrollment(false)
    closeModal()
  }

  const removeEnrollmentAndSetRefund = (refundEnrollment) => {
    setRefundEnrollment(false)
    handleRemoveEnrollment(refundEnrollment)
  }

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={closeModalAndSetRefund}
      contentLabel='Refund Enrollment Modal'
      className={classNames('refund-enrollment-modal-content', { 'second-modal-screen-content': refundEnrollment })}
      autoFocus
    >
      <div id='RefundEnrollmentModal'>
        <div className='animated fadeIn'>
          <div className='d-flex justify-content-end text-align-right'>
            <ModalXIcon id='modalXIcon' onClick={closeModalAndSetRefund} />
          </div>
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <WarningIcon id='warningIcon' />
            <p className='title'>
              {refundEnrollment
                ? 'Drop & Refund Enrollment'
                : 'Drop Enrollment'
              }
            </p>
            <p className='sub-title'>
              {refundEnrollment
                ? 'Dropping this golfer will issue a full refund payment in Stripe.'
                : 'In addition to dropping this enrollment, would you also like to refund it?'
              }
            </p>
          </div>
          {refundEnrollment &&
            <>
              <p className='sub-title mt-2'>
                Refunds take 5-10 days to appear on your account. Stripe's fees for the original payment won't be returned, but there are
                no additional fees for the refund.
              </p>
              <p className='transaction-details'>Transaction Details: </p>
              <div className='divider' />
              {Object.entries(transactionDetails).map(([key, value]) =>
                <div className='d-flex justify-content-between align-items-center' key={key}>
                  <p className='key'>{key}</p>
                  <p className='value'>{value}</p>
                </div>
              )}
            </>
          }
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <p className='sub-title mt-4'>Do you want to send an additional message telling the family why their enrollment was dropped?</p>
            <Input
              name='description'
              type='textarea'
              onChange={handleCoachMessageChange}
              placeholder='Write a custom message for the family! (optional)'
              value={coachMessage}
              classNames={{ inputContainer: 'coachMessageInputContainer', inputContainerWrapper: 'mt-3 coachMessageWrapper' }}
            />
          </div>
          <div>
            <div className='row py-3'>
              <div className='col-12'>
                <SubmitButton
                  onClick={refundEnrollment ? () => removeEnrollmentAndSetRefund(true) : () => setRefundEnrollment(true)}
                  buttonMessage='Drop and Refund'
                  buttonColor='orange-button'
                />
              </div>
              <div className='col-12'>
                <SubmitButton
                  onClick={refundEnrollment ? closeModalAndSetRefund : () => removeEnrollmentAndSetRefund(false)}
                  buttonMessage={refundEnrollment ? 'Close' : 'Drop (No refund)'}
                  buttonColor='navy-button'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

RefundEnrollmentModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  coachMessage: PropTypes.string,
  handleCoachMessageChange: PropTypes.func.isRequired,
  handleRemoveEnrollment: PropTypes.func.isRequired,
  enrollment: PropTypes.object.isRequired
}

export default RefundEnrollmentModal
