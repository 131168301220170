import PropTypes from 'prop-types'
import styles from './CustomPlaceholder.module.scss'

const CustomPlaceholder = ({
  icon,
  subtext,
  title,
  handleAction,
  guideText,
  buttonText,
  guideLink }) =>

  <div className={styles.noSeriesPlaceholder}>
    {icon}
    <p className={styles.placeholderTitle}>{title}</p>
    <div className={styles.nineHolePlaceholderDescription}>{subtext}</div>
    {guideText &&
    <a className={styles.learnMoreText} href={guideLink} target='_blank'>
      {guideText}
    </a>}
    {buttonText && <button className='btn btn-primary pl-3 pr-3 d-flex align-items-center' id='create-event-btn' onClick={handleAction}>
      {buttonText}
    </button>}
  </div>


CustomPlaceholder.propTypes = {
  typeCompleted: PropTypes.bool,
  icon: PropTypes.object,
  textStyle: PropTypes.object,
  subtext: PropTypes.string,
  title: PropTypes.string,
  guideText: PropTypes.string || undefined,
  buttonText: PropTypes.string || undefined,
  guideLink: PropTypes.string || undefined,
  handleAction: PropTypes.func || undefined,
}

export default CustomPlaceholder
