import PropTypes from 'prop-types'
import classnames from 'classnames'

import { defaultEventCompareFunction } from '../../utility/helperFunctions'
import EventRow from './SeriesFlow/EventRow'
import { Link } from 'react-router-dom'

import styles from './SeriesPresentationSchedule.module.scss'
import { ReactComponent as EditIcon } from '../../assets/images/common/icons/edit-icon.svg'

const SeriesPresentationList = ({ series, timezone, className, isEditable }) => {
  return (
    <div className={className}>
      {series.map((series, index) => (
        <div className={styles.seriesSchedule} key={index}>
          <div className='m-3 mx-lg-0'>
            <div className='d-flex justify-content-between'>
              <h4 className={styles.title}>{series.title}</h4>
              {isEditable && (
                <Link to={`/series/${series.id}/edit`} target='_blank' className={classnames('d-flex align-items-center', styles.action)}>
                  <EditIcon className={styles.actionIcon} />
                  <span className={classnames('ml-2', styles.actionText)}>Edit Event Series</span>
                </Link>
              )}
            </div>
            <p className={styles.subtitle}>{series.subtitle}</p>
          </div>
          <div className={styles.events}>
            {series.events.toSorted(defaultEventCompareFunction)
              .map((event, index) => (
                <EventRow key={index} event={{ ...event }} timezone={timezone} index={index} withExternalLink={isEditable} />
              ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default SeriesPresentationList

SeriesPresentationList.propTypes = {
  series: PropTypes.array.isRequired,
  timezone: PropTypes.string.isRequired,
  className: PropTypes.string,
  isEditable: PropTypes.bool,
}
