import { createAsyncThunk } from '@reduxjs/toolkit'

import Axios from '../axios'
import { handleAPIError } from '../utility/actionHelper'
import { serialize, deserialize } from '../utility/serialization'

// THUNKS

export const getRepresentative = createAsyncThunk(
  'dashboard/getRepresentative',
  async (userId, { rejectWithValue }) => {
    try {
      const { data } = await requestRepresentative(userId)
      return data
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const getCommunityFeedActivities = createAsyncThunk(
  'dashboard/getCommunityFeedActivities',
  async ({ communityId, page, perPage }, { rejectWithValue }) => {
    try {
      const { data } = await requestCommunityActivities(communityId, page, perPage)
      return data
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const getCommunityAllTimeTotals = createAsyncThunk(
  'dashboard/getCommunityAllTimeTotals',
  async (communityId, { rejectWithValue }) => {
    try {
      const { data } = await requestCommunityAllTimeTotals(communityId)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

// API CALLS

function requestRepresentative(userId) {
  const requestUrl = `/api/v1/users/${userId}?preview=true`
  return Axios.get(requestUrl)
}

function requestCommunityActivities(communityId, page, perPage) {
  const requestUrl = `/api/v1/communities/${communityId}/activities`
  return Axios.get(requestUrl, {
    params: {
      page: page,
      per_page: perPage,
    },
  })
}

function requestCommunityAllTimeTotals(communityId) {
  const requestUrl = `/api/v1/reports/dashboard_all_time_totals?community_id=${communityId}`
  return Axios.get(requestUrl)
}
