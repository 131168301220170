import PropTypes from 'prop-types'
import classnames from 'classnames'
import { defaultUserEnrollmentCompareFunction } from '../../utility/helperFunctions'

import ReactTooltip from 'react-tooltip'
import EnrollmentTooltipStudentRow from './EnrollmentTooltipStudentRow'

import styles from './EnrollmentsProgressBar.module.scss'

const EnrollmentsProgressBar = ({ programId, capacity, enrollmentsCount, withTooltip, appliedEnrollments, otherEnrollments, classNames }) => (
  <>
    <div
      className={classnames(styles.golfersInfo, { [classNames?.golfersInfo]: !!classNames?.golfersInfo })}
      data-for={`enrollmentsTooltip-${programId}`}
      data-tip
    >
      <div className={classnames(styles.golfersCount, { [classNames?.golfersCount]: !!classNames?.golfersCount })}>
        {capacity ?
          enrollmentsCount === 0 ?
            <>No Enrollments</>
            :
            <>
              <span>{enrollmentsCount}</span>
              /
              {capacity} golfers
            </>
          :
          <><span className={styles.count}>{enrollmentsCount}</span> golfers</>
        }
      </div>
      {capacity &&
        <div className={classnames('progress', styles.progressBackground)}>
          <div
            className={styles.progressBar}
            role='progressbar'
            style={{ width: `${enrollmentsCount * 100 / capacity}%` }}
            aria-valuenow='25'
            aria-valuemin='0'
            aria-valuemax='100' />
        </div>
      }
    </div>
    {withTooltip && (
      <ReactTooltip
        id={`enrollmentsTooltip-${programId}`}
        effect='solid'
        delayUpdate={1000}
        delayHide={100}
        place={'bottom'}
        className={styles.enrollmentsTooltip}
      >
        <div>
          <div className={styles.tooltipSection}>
            <p className={styles.tooltipTitle}>Enrolled: {enrollmentsCount}</p>
            <p className={styles.tooltipTitle}>Capacity: {capacity ? capacity : 'Not set'}</p>
          </div>
          {appliedEnrollments.length > 0 &&
            <div className={styles.tooltipSection}>
              <p className={styles.tooltipTitle}>Needs Approval:</p>
              {appliedEnrollments.toSorted(defaultUserEnrollmentCompareFunction)
                .map((enrollment, idx) => <EnrollmentTooltipStudentRow student={enrollment.user} key={idx} />)
              }
            </div>
          }
          <div className={styles.tooltipSection}>
            <p className={styles.tooltipTitle}>Enrolled:</p>
            {otherEnrollments.toSorted(defaultUserEnrollmentCompareFunction)
              .map((enrollment, idx) => <EnrollmentTooltipStudentRow student={enrollment.user} key={idx} />)
            }
          </div>
        </div>
      </ReactTooltip>
    )}
  </>
)

EnrollmentsProgressBar.propTypes = {
  programId: PropTypes.number.isRequired,
  capacity: PropTypes.number,
  enrollmentsCount: PropTypes.number.isRequired,
  withTooltip: PropTypes.bool,
  appliedEnrollments: PropTypes.array,
  otherEnrollments: PropTypes.array,
  classNames: PropTypes.shape({
    golfersCount: PropTypes.string,
    golfersInfo: PropTypes.string,
  }),
}

export default EnrollmentsProgressBar
