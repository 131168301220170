import Axios from '../axios'
import { serialize, deserialize } from '../utility/serialization'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { handleAPIError } from '../utility/actionHelper'

// THUNKS

export const getScheduleSessionsByMonth = createAsyncThunk(
  'scheduleSessions/getScheduleSessionsByMonth',
  async ({ selectedMonthDate }, { rejectWithValue }) => {
    try {
      const { data } = await requestGetScheduleSessionsByMonth(selectedMonthDate)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const updateScheduleSession = createAsyncThunk(
  'schedulesSessions/updateScheduleSession',
  async (scheduleSessionData, { rejectWithValue }) => {
    try {
      const { data } = await requestUpdateScheduleSession(scheduleSessionData)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const deleteScheduleSession = createAsyncThunk(
  'schedules/deleteScheduleSession',
  async (scheduleSessionId, { rejectWithValue }) => {
    try {
      const { data } = await requestDeleteScheduleSession(scheduleSessionId)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

// API CALLS

const requestGetScheduleSessionsByMonth = (monthDate) => {
  const requestUrl = '/api/v1/schedule_sessions/dashboard_calendar'

  return Axios.get(requestUrl, { params: serialize({ monthDate: monthDate.toDateString() }) })
}

function requestUpdateScheduleSession(scheduleSession) {
  const requestUrl = '/api/v1/schedule_sessions/' + scheduleSession.id

  const data = {
    title: scheduleSession.title,
    location: scheduleSession.location,
    description: scheduleSession.description,
    start_date: scheduleSession.startDate,
    end_date: scheduleSession.endDate
  }

  return Axios.put(requestUrl, data)
}

function requestDeleteScheduleSession(scheduleSession_id) {
  let requestUrl = '/api/v1/schedule_sessions/' + scheduleSession_id
  return Axios.delete(requestUrl)
}
