import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { getTitle } from '../../../../utility/materials'
import CoachEmailInfo from './CoachEmailInfo'
import ButtonLink from '../../../common/buttons/ButtonLink'

import './RegistrationCompletedMaterials.scss'

const RegistrationCompletedMaterials = () => {
  const course = useSelector(state => state.programPresentation.course)
  const { acceptedMaterials, selectedPaymentMethod } = useSelector(state => state.programRegistration)
  const leadCoach = course.community.leadCoach

  return (
    <div id='RegistrationCompletedMaterials' className='h-100 d-flex flex-column justify-content-lg-around'>
      <div className='d-none d-lg-flex flex-column px-5'>
        <h1 className='d-flex align-items-center post-registration-title'>
          <i className='fa fa-check-circle mr-2' />&nbsp;
          {course.hasManualEnrollmentsApproval && selectedPaymentMethod === 'cash' ? 'Enrollment request sent!' : 'Successfully Registered!' }
        </h1>
      </div>
      <div className='my-md-4 px-md-5 d-flex flex-column align-items-center align-items-md-start'>
        <h2 className='final-step-header font-weight-bold'>
          Final Step:&nbsp;
          <br className='d-md-none' />
          Order your materials!
        </h2>
      </div>
      {Object.values(acceptedMaterials).map(material => (
        <Fragment key={material.id}>
          <div className='px-md-5 d-flex justify-content-center justify-content-md-start max-width-xl-85'>
            <div>
              <p className='my-3 get-material-paragraph d-md-none'>
                This program requires a {getTitle(material)}. Please follow the link below to order your kit. This will be shipped directly to you.
              </p>
              <img src={material.photo.fileUrl} alt='Selected material' className='material-photo' />
            </div>
            <div className='ml-4 d-none d-md-flex flex-column justify-content-between'>
              <h3 className='font-weight-bold'>{material.name}</h3>
              <div>
                <p className='get-material-paragraph'>
                  This program requires a {getTitle(material)}.&#20;
                  Please follow the link below to order your kit. This will be shipped directly to you.
                </p>
                <p className='material-description'>{material.description}</p>
              </div>
            </div>
          </div>
          <div className='px-md-5 my-4 d-none d-md-block max-width-xl-85'>
            <ButtonLink
              noTopMargin
              navigateTo={material.url}
              isExternal
              className='w-100 btn btn-primary'
              target='_blank'
            >
              {`Get your ${getTitle(material)}!!!`}
            </ButtonLink>

            <ButtonLink navigateTo={'/dashboard'} linkColor='dark-navy' className='w-100' >
              Go to Dashboard
            </ButtonLink>
          </div>
        </Fragment>
      ))}
      <div className='mt-4 px-3 px-md-5'>
        <CoachEmailInfo leadCoach={leadCoach} />
      </div>
    </div>
  )
}

export default RegistrationCompletedMaterials
