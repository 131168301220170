import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

import { setCurrentTab } from '../../../actions/commonActions'
import { getAllCommunityStudents } from '../../../actions/communitiesActions'
import { deleteProgram, getDashboardProgram } from '../../../actions/programActions'
import { resetDashboard } from '../../../reducers/programDashboardReducer'
import { checkLicensePermission } from '../../../utility/communityLicense'
import PageHeader from '../../common/PageHeader/PageHeader'
import DefaultMultiActionsPopover from '../../common/PageHeader/DefaultMultiActionsPopover'
import AddStudentsManuallyModal from './add-students-manually-modal/AddStudentsManuallyModal'
import Loader from '../../common/Op36Loader-web'
import LiveModal from '../ProgramBuilder/Modals/LiveModal'
import ConfirmationModal from '../../common/ConfirmationModal'
import Header from './Header'
import LessonPlanTab from './LessonPlanTab'
import SeriesScheduleTab from './SeriesScheduleTab'
import ScheduleTab from './ScheduleTab'
import ReportsTab from './ReportsTab'
import EnrollmentsTab from './enrollments-tab/EnrollmentsTab'
import OverviewTab from './overview-tab/OverviewTab'

import classes from './ProgramDashboardManager.module.scss'

const ProgramDashboardManager = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const dispatch = useDispatch()
  const licenseType = useSelector(state => state.user.current_user.communities[0].license_type)
  const { program, isLoading } = useSelector(state => state.programDashboard)

  const [isAddStudentsModalOpen, setAddStudentsModalOpen] = useState(false)
  const [isLiveModalOpen, setLiveModalOpen] = useState(false)
  const [isLoadingDeleteProgram, setIsLoadingDeleteProgram] = useState(false)
  const [isDeleteStudentConfirmationModalOpen, setIsDeleteStudentConfirmationModalOpen] = useState(false)

  const hasStandardLegacyLicense = checkLicensePermission(licenseType, ['standard_legacy'])
  const programIdParams = params.id
  const withLiveModal = location.state?.showLiveModal

  const unapprovedEnrollmentsCount = useMemo(() => (
    program?.enrollments.filter(enrollment => enrollment.status === 'applied').length
  ), [program?.enrollments])

  const tabsData = useMemo(() => ([
    {
      name: 'Overview',
      body: <OverviewTab />,
    },
    ...program?.withSchedule ? [{
      name: 'Schedule',
      body: <ScheduleTab />,
    }] : [],
    ...program?.lessonPlan ? [{
      name: 'Lesson Plan',
      body: <LessonPlanTab />,
    }] : [],
    ...program?.series.length > 0 ? [{
      name: '9 Hole Events',
      body: <SeriesScheduleTab />,
    }] : [],
    {
      name: 'Reports',
      body: <ReportsTab />,
    },
    {
      name: 'Manage Enrollments',
      body: <EnrollmentsTab />,
      withDot: program?.hasManualEnrollmentsApproval && unapprovedEnrollmentsCount > 0,
    },
  ]), [program, unapprovedEnrollmentsCount])

  useEffect(() => {
    dispatch(setCurrentTab('Programs'))
    dispatch(getDashboardProgram(programIdParams)).unwrap()
      .catch(() => toast.error('Something went wrong. Please try again.', { position: toast.POSITION.TOP_RIGHT }))
    if (withLiveModal) {
      setLiveModalOpen(true)
    }

    return () => dispatch(resetDashboard())
  }, [dispatch, programIdParams, withLiveModal])

  const handleOpenAddStudentsModal = () => {
    setAddStudentsModalOpen(true)
    fetchAllCommunityStudents()
  }

  const fetchAllCommunityStudents = (search) => {
    dispatch(getAllCommunityStudents({
      communityId: program.communityId,
      ...(search ? { search: search } : {}),
    })).unwrap()
      .catch(() => toast.error('Something went wrong. Please try again.', { position: toast.POSITION.TOP_RIGHT }))
  }

  const handleDeleteProgram = async () => {
    try {
      setIsLoadingDeleteProgram(true)
      await dispatch(deleteProgram(program.id)).unwrap()
      navigate('/programs')
    } catch (err) {
      toast.error('Something went wrong. Please try again.', { position: toast.POSITION.TOP_RIGHT })
      setIsDeleteStudentConfirmationModalOpen(false)
      setIsLoadingDeleteProgram(false)
    }
  }

  const handleCloseConfirmDeleteModal = () => setIsDeleteStudentConfirmationModalOpen(false)

  const handleGoBack = () => navigate('/programs')

  if (!program || isLoading) {
    return (
      <div>
        <PageHeader
          title='Group Programs'
          backButton
          onClickBaasdck={handleGoBack}
        />
        <Loader message='Loading Program...' />
      </div>
    )
  }

  return (
    <>
      <PageHeader
        title='Group Programs'
        backButton
        onClickBack={handleGoBack}
        actionComponent={(
          <DefaultMultiActionsPopover
            isModalOpen={isAddStudentsModalOpen}
            title='Manage Program'
            actions={[
              { label: 'Edit Program', link: true, to: `/programs/${programIdParams}/edit` },
              { label: 'Edit Schedule', link: true, to: `/programs/${programIdParams}/edit?step=2` },
              ...(hasStandardLegacyLicense ? [{ label: 'Add Students', onClick: handleOpenAddStudentsModal }] : []),
              { label: 'Delete', color: 'red', onClick: () => setIsDeleteStudentConfirmationModalOpen(true), isLoading: isLoadingDeleteProgram },
            ]}
          />
        )}
      />
      <div className={classes.dashboardManagerPage}>
        <Header />
        <Tabs
          defaultIndex={program?.hasManualEnrollmentsApproval && unapprovedEnrollmentsCount > 0 ? tabsData.length - 1 : 0}
          className={classes.tabs}
          selectedTabClassName={classes.selectedTab}
          selectedTabPanelClassName={classes.selectedTabPanel}
        >
          <TabList className={classes.tabList}>
            {tabsData.map((data, index) => (
              <Tab key={index} className={classes.tab}>
                {data.name} {data.withDot}
                {data.withDot && <div className={classes.tabDot}/>}
              </Tab>
            ))}
          </TabList>

          {tabsData.map((data, index) => (
            <TabPanel key={index}>{data.body}</TabPanel>
          ))}
        </Tabs>
      </div>
      {hasStandardLegacyLicense && (
        <AddStudentsManuallyModal
          isModalShown={isAddStudentsModalOpen}
          closeModal={() => setAddStudentsModalOpen(false)}
          fetchAllCommunityStudents={fetchAllCommunityStudents}
          program={program}
        />
      )}
      {withLiveModal && (
        <LiveModal
          program={program}
          showModal={isLiveModalOpen}
          onClose={() => setLiveModalOpen(false)}
        />
      )}
      <ConfirmationModal
        showConfirmationModal={isDeleteStudentConfirmationModalOpen}
        closeConfirmationModal={handleCloseConfirmDeleteModal}
        confirmationMessage='Are you sure you want to delete this program? It will permanently delete the enrollments.'
        confirmationDenied={handleCloseConfirmDeleteModal}
        confirmationProceed={handleDeleteProgram}
      />
    </>
  )
}

export default ProgramDashboardManager
