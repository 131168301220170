import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { requestCheckExportStatus } from '../../../actions/reportsActions'
import { deserialize } from '../../../utility/serialization'

const useReportPolling = ({ exportId, setExportId }) => {
  const dispatch = useDispatch()
  const [intervalId, setIntervalId] = useState(null)
  const [exportUrl, setExportUrl] = useState(null)
  const [exportError, setExportError] = useState(null)

  useEffect(() => {
    if (exportId && !intervalId) {
      const interval = setInterval(() => {
        requestCheckExportStatus(exportId).then((response) => {
          const job = deserialize(response.data.job)

          if (job.status === 'success') {
            setExportUrl(job.parsedResult.fileUrl)
            setExportError(false)
            setExportId(null)
          }
          if (job.status === 'error') {
            setExportError(false)
            setExportId(null)
          }
        })
      }, 1000)
      setIntervalId(interval)
    }
    if (!exportId && intervalId) {
      clearInterval(intervalId)
      setIntervalId(null)
    }
  }, [setExportId, dispatch, exportId, intervalId])

  return [
    exportUrl,
    exportError,
    () => {
      setExportUrl(null)
      setExportError(null)
    },
  ]
}

export default useReportPolling
