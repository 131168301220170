import PropTypes from 'prop-types'
import React, { memo, useMemo } from 'react'
import classnames from 'classnames'

import styles from './StudentCard.module.scss'
import AvatarPlaceholder from '../../../../assets/images/mock/StockProfilePic.png'
import d1 from '../../../../assets/images/division-badges/D01-Badge.png'
import d2 from '../../../../assets/images/division-badges/D02-Badge.png'
import d3 from '../../../../assets/images/division-badges/D03-Badge.png'
import d4 from '../../../../assets/images/division-badges/D04-Badge.png'
import d5 from '../../../../assets/images/division-badges/D05-Badge.png'
import d6 from '../../../../assets/images/division-badges/D06-Badge.png'
import d7 from '../../../../assets/images/division-badges/D07-Badge.png'
import d8 from '../../../../assets/images/division-badges/D08-Badge.png'
import d9 from '../../../../assets/images/division-badges/D09-Badge.png'
import d10 from '../../../../assets/images/division-badges/D10-Badge.png'

const mapDivisionToIcon = division => {
  switch (division) {
    case 1:
      return d1
    case 2:
      return d2
    case 3:
      return d3
    case 4:
      return d4
    case 5:
      return d5
    case 6:
      return d6
    case 7:
      return d7
    case 8:
      return d8
    case 9:
      return d9
    case 10:
      return d10
    default:
      return ''
  }
}

const arePropsEqual = (oldProps, newProps) =>
  (oldProps.user.id === newProps.user.id &&
    oldProps.user.enrollments === newProps.user.enrollments &&
    oldProps.user.actionFailed === newProps.user.actionFailed &&
    oldProps.programId === newProps.programId &&
    oldProps.isChecked === newProps.isChecked) &&
    oldProps.onClick === newProps.onClick

const StudentCard = memo(({ user, programId, isChecked, onClick }) => {
  const isEnrolled = useMemo(() =>
    user.enrollments.some(enrollment => enrollment.courseId === programId && enrollment.status === 'active'),
  [programId, user.enrollments]
  )

  const isPending = useMemo(() =>
    user.enrollments.some(enrollment => enrollment.courseId === programId && enrollment.status === 'applied'),
  [programId, user.enrollments]
  )

  return (
    <button
      className={classnames(
        'w-100 d-flex justify-content-between align-items-center',
        styles.studentCard,
        isPending || isEnrolled ? styles.unclickable : styles.clickable,
        {
          [styles.checked]: isChecked,
          [styles.error]: user.actionFailed,
        }
      )}
      onClick={onClick(user.id, isChecked)}
      disabled={isPending || isEnrolled}
    >
      {isPending && <p className='mr-2'>pending</p>}
      <div className={classnames('d-flex align-items-center', styles.imageNameContainer)}>
        <div
          className={styles.studentPic}
          style={{
            backgroundImage: `url(${
              user.avatar ? user.avatar.fileUrl : AvatarPlaceholder
            })`,
          }}
        />
        <p>{`${user.firstName} ${user.lastName}`}</p>
      </div>
      <div
        className={styles.divisionIcon}
        style={{ backgroundImage: `url(${mapDivisionToIcon(user.currentDivision)})` }}
      />
    </button>
  )
}, arePropsEqual)

StudentCard.displayName = 'StudentCard'

StudentCard.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    avatar: PropTypes.shape({
      fileUrl: PropTypes.string,
    }),
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    currentDivision: PropTypes.number,
    enrollments: PropTypes.array,
    actionFailed: PropTypes.bool,
  }).isRequired,
  programId: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  isChecked: PropTypes.bool.isRequired,
}

export default StudentCard
