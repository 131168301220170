import moment from 'moment'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { toast } from 'react-toastify'
import { formatDates } from '../../../utility/datesHelper'
import { Link } from 'react-router-dom'

import EnrollmentsProgressBar from '../EnrollmentsProgressBar'

import classes from './Header.module.scss'
import globals from '../../../assets/styles/globals.scss'
import { ReactComponent as ShareIcon } from '../../../assets/images/common/icons/share-icon.svg'
import { ReactComponent as OutsideLinkIcon } from '../../../assets/images/common/icons/outside-link-icon.svg'
import { ReactComponent as FlagCircleIcon } from '../../../assets/images/common/icons/flag-icon.svg'
import { ReactComponent as InfoCircleIcon } from '../../../assets/images/common/icons/info-circle-icon.svg'

const statusToData = {
  green: {
    header: {
      color: globals.headerDarkGreen,
    },
    banner: {
      color: globals.darkTeal,
      backgroundColor: globals.lighterTeal,
      text: () => 'Program Complete',
    },
  },
  blue: {
    header: {
      color: globals.headerDarkBlue,
    },
    banner: {
      color: globals.darkBlue,
      backgroundColor: globals.lighterBlue,
      text: (program) => `Program Active: ${moment(program.endDate).diff(moment(), 'days')} Days Left`,
    },
  },
  gray: {
    header: {
      color: globals.headerDarkGray,
    },
    banner: {
      color: globals.slateGray,
      backgroundColor: globals.placeholderGray,
      text: (program) => {
        if (program.status === 'future') {
          return `Future Program - Registration Opens In ${moment(program.registrationStartsAt).diff(moment(), 'days')} Days`
        }

        return `Program Starts In ${moment(program.startDate).diff(moment(), 'days')} Days.`
      },
    },
  },
}

const Header = () => {
  const timezone = useSelector(state => state.user.current_user.communities[0].timezone)
  const { program } = useSelector(state => state.programDashboard)
  const programSignUpPageUrl = `${process.env.REACT_APP_BASE_URL}/landing/${program.communityId}/class/${program.id}`

  const headerStatus = useMemo(() => {
    switch (program.status) {
      case 'completed':
        return 'green'
      case 'active':
        return moment(program.startDate).isAfter(moment(), 'day') ? 'gray' : 'blue'
      default:
        return 'gray'
    }
  }, [program])

  const handleClickShareButton = () => {
    navigator.clipboard.writeText(programSignUpPageUrl)
      .then(() => {
        toast.info('Link was copied to clipboard', { position: toast.POSITION.TOP_RIGHT })
      })
  }

  return (
    <div className={classnames('d-flex flex-column', classes.header)}>
      <div
        style={{
          background: program.headerImage
            ? `linear-gradient(${statusToData[headerStatus].header.color}, ${statusToData[headerStatus].header.color}), ` +
            `url(${program?.headerImage?.originalFileUrl}) center/cover no-repeat`
            : statusToData[headerStatus].header.color,
        }}
        className={classnames('pb-4 pl-4 pr-5 d-flex justify-content-between', classes.programInfo)}
      >
        <div className='d-flex flex-column'>
          <h1 className={classes.title}>{program.name}</h1>
          <span className={classes.dates}>{formatDates(program.startDate, program.endDate, timezone)}</span>
          <span className={classes.meetingTimes}>{program.meetingTimes}</span>
        </div>
        <div className='d-flex flex-column align-items-end justify-content-between'>
          <EnrollmentsProgressBar
            programId={program.id}
            capacity={program.golferLimit}
            enrollmentsCount={program.enrollments.length}
            classNames={{
              golfersCount: classes.golfersCount,
            }}
          />
          {program.status !== 'completed' && (
            <div className='mt-2 d-flex'>
              <button
                onClick={handleClickShareButton}
                className={classes.shareButton}
              >
                <ShareIcon className={classnames('mr-2', classes.shareIcon)} />
                Copy & Share
              </button>
              <Link
                target='_blank'
                to={`/landing/${program.communityId}/class/${program.id}`}
                className='ml-3 d-flex align-items-center'
              >
                <OutsideLinkIcon className='mr-2' />
                View Sign-Up Page
              </Link>
            </div>
          )}
        </div>
      </div>
      <div
        className='px-3 py-2 d-flex justify-content-between align-items-center'
        style={{ backgroundColor: statusToData[headerStatus].banner.backgroundColor }}
      >
        <div className='d-flex align-items-center'>
          <FlagCircleIcon width={20} height={20} className='mr-2' style={{ fill: statusToData[headerStatus].banner.color }} />
          <span
            style={{ color: statusToData[headerStatus].banner.color }}
            className={classes.bannerText}
          >
            {statusToData[headerStatus].banner['text'](program)}
          </span>
        </div>
        <InfoCircleIcon width={20} height={20} className={classes.infoCircleIcon}/>
      </div>
    </div>
  )
}

export default Header
