import { useSelector } from 'react-redux'

import '../../../assets/styles/components/common/ReactTabs.scss'
import '../../../assets/styles/components/classes/ClassesPage.scss'
import { checkLicensePermission } from '../../../utility/communityLicense'
import UnavailableClassesPage from './PaymentsLockedPage'
import CoachProgramsPage from './CoachProgramsPage'

const ClassesPage = () => {
  const community = useSelector(state => state.user.current_user)?.communities[0]
  const permittedLicenseType = checkLicensePermission(community?.license_type, ['standard', 'standard_legacy'])
  const stripePaymentsReady = community?.selected_stripe_connected_account && community?.available_packages_count > 0

  return (
    <div>
      {permittedLicenseType || stripePaymentsReady
        ? <CoachProgramsPage />
        : <UnavailableClassesPage />
      }
    </div>
  )
}

export default ClassesPage
