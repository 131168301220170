import { useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select, { components } from 'react-select'
import { toast } from 'react-toastify'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import * as courseActions from '../../../../actions/courseActions'
import * as enrollmentActions from '../../../../actions/enrollmentActions'
import ConfirmationModal2 from '../../../common/ConfirmationModal2'
import DropdownIndicator from './DropDownIndicator'
import Option from './Option'
import colors from '../../../../assets/styles/globals.scss'
import TooltipText from '../../../common/TooltipText'
import * as Constants from '../../../../misc/Constants'

import './ColumnPackageSelect.scss'
import Tooltip from '../../../common/Tooltip'

const selectStyles = (paidOnline) => ({
  container: (provided) => ({
    ...provided, height: '45px',
  }),
  control: (provided) => ({
    ...provided, cursor: 'pointer', border: '0', background: 'none', boxShadow: 'none', padding: '0',
  }),
  option: (provided) => ({
    ...provided, cursor: 'pointer',
  }),
  valueContainer: (provided) => ({
    ...provided, height: '45px', padding: '0', cursor: paidOnline ? 'auto' : 'pointer', input: { position: 'absolute', height: '0', border: 'none' },
  }),
})

const NO_PACKAGE = { id: null, name: 'Select package', price: '--', archived: false }
const NO_PACKAGES_ATTACHED = { id: null, name: 'No packages attached to class', price: '--', archived: false }

const ColumnPackageSelect = ({ course, enrollment, menuOpen, setMenuOpen }) => {
  const dispatch = useDispatch()

  const availablePackages = course.availablePackages
  const paidOnline = enrollment.paymentStatus === 'paid_online'
  const [showPackageChangeConfirmationModal, setShowPackageChangeConfirmationModal] = useState(false)
  const [selectedPackage, setSelectedPackage] = useState(enrollment.package || (availablePackages?.length > 0 ? NO_PACKAGE : NO_PACKAGES_ATTACHED))
  const [selectLoader, setSelectLoader] = useState(false)
  const [packageToBeUpdated, setPackageToBeUpdated] = useState()
  const currency = useSelector(state => state.user.current_user.communities[0].currency)

  const handlePackageChange = (newPackage) => {
    const packageHasBeenUpdated = (newPackage.id !== selectedPackage?.id ||
                                  (selectedPackage.id && selectedPackage.price !== enrollment.transactionAmount) ||
                                  (selectedPackage.id && currency.code !== enrollment.currency.code))
    if (packageHasBeenUpdated) {
      const packageUpdateNeedsConfirmation = (selectedPackage.archived ||
                                             (selectedPackage.id && selectedPackage.price !== enrollment.transactionAmount) ||
                                             (selectedPackage.id && currency.code !== enrollment.currency.code) ||
                                             (selectedPackage.id && !course.availablePackages.some(pkg => pkg.id === selectedPackage.id)))

      console.log({ selectedPackage, newPackage })

      if (packageUpdateNeedsConfirmation) {
        setPackageToBeUpdated(newPackage)
        setShowPackageChangeConfirmationModal(true)
      } else {
        changePackage(newPackage)
      }
    }
  }

  const changePackage = (newPackage) => {
    const oldPackage = { ...selectedPackage }

    setSelectLoader(true)
    setSelectedPackage({ id: null, name: 'Loading...' })
    setPackageToBeUpdated(null)
    setShowPackageChangeConfirmationModal(false)

    dispatch(enrollmentActions.updateEnrollment({ enrollmentData: { id: enrollment.id, packageId: newPackage.id } })).unwrap()
      .then(() => setSelectedPackage(newPackage))
      .catch(() => {
        setSelectedPackage(oldPackage)
        toast.error('Something went wrong. Please try again.', { position: toast.POSITION.TOP_RIGHT })
      })
      .finally(() => setSelectLoader(false))
  }

  const options = useMemo(() => (
    availablePackages?.length > 0 ? [NO_PACKAGE, ...availablePackages] : [NO_PACKAGES_ATTACHED]
  ), [availablePackages])

  const SingleValue = ({ data, innerProps } ) => (
    <div {...innerProps} key={data.id} className='d-flex align-items-center package-selected-value'>
      <Tooltip offset={{ top: '-5' }} />
      { data.id
        ? <TooltipText text={data.name} twoLinesEllipsis noEllipsis={menuOpen} />
        : <p style={{ color: colors.placeholderGray }}>{data.name}</p>
      }
    </div>
  )

  SingleValue.propTypes = {
    data: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    innerProps: PropTypes.object,
  }

  return (
    <div id='ColumnPackageSelect' className={classNames({ 'disabled': selectLoader })}>
      <Select
        name='package-select'
        className='package-select'
        value={selectedPackage}
        options={options}
        components={{
          DropdownIndicator: paidOnline ? () => null : DropdownIndicator,
          Menu: paidOnline ? () => null : components.Menu,
          IndicatorSeparator: () => null,
          SingleValue,
          Option,
        }}
        isSearchable={false}
        onChange={handlePackageChange}
        styles={selectStyles(paidOnline)}
        isDisabled={selectLoader}
        menuPortalTarget={Constants.DOCUMENT_BODY_REFERENCE}
        onMenuOpen={() => setMenuOpen(true)}
        onMenuClose={() => setMenuOpen(false)}
        menuPlacement='auto'
      />
      <ConfirmationModal2
        showModal={showPackageChangeConfirmationModal}
        closeModal={() => { setPackageToBeUpdated(null); setShowPackageChangeConfirmationModal(false) }}
        onDeny={() => { setPackageToBeUpdated(null); setShowPackageChangeConfirmationModal(false) }}
        denyMessage='Cancel'
        onProceed={() => changePackage(packageToBeUpdated)}
        proceedMessage='Change package'
        icon={<i className='fa fa-question-circle' style={{ fontSize: '72px' }} />}
        title='Change package?'
        message='Are you sure you want to replace the package for this enrollment? The current package has been detached, archived or its
                 price/currency has been updated since the time it was added. If you change it now, you will not be able to change it back.'
      />
    </div>
  )
}

ColumnPackageSelect.propTypes = {
  course: PropTypes.object,
  enrollment: PropTypes.object,
  menuOpen: PropTypes.bool,
  setMenuOpen: PropTypes.func,
}

export default ColumnPackageSelect
