import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { groupBy, isEmpty } from 'lodash'
import moment from 'moment-timezone'

import ScheduleSessionCard from './ScheduleSessionCard'

import classes from './SessionsList.module.scss'
import { ReactComponent as CalendarIcon } from '../../assets/images/common/icons/circle-calendar.svg'

const SessionsList = ({
  schedule,
  timezone,
  isEditable,
  showPastSessions,
  className,
}) => {
  const groupedSessions = useMemo(() => {
    const sessions = showPastSessions
      ? schedule?.scheduleSessions
      : schedule?.scheduleSessions.filter(
        (s) => moment(s.startDate) >= moment(new Date())
      )

    return groupBy(sessions, (session) =>
      moment.tz(session.startDate, timezone).format('YYYY-MM-DD')
    )
  }, [showPastSessions, schedule, timezone])

  return (
    <div className={className}>
      {!schedule || isEmpty(groupedSessions) ? (
        <div className='d-flex flex-column align-items-center'>
          <CalendarIcon className={classes.calendarCardIcon} />
          <h4 className={classnames('mt-2', classes.title)}>No Sessions Scheduled</h4>
          <p className={classes.content}>
            Let prospective sign-ups and active enrollments know your schedule
            for the class.
            <br />
            It will be visible on your class sign-up page and in your
            students’ mobile app.
          </p>
        </div>
      ) : (
        Object.entries(groupedSessions)
          .map(([date, sessions]) => (
            <ScheduleSessionCard
              key={date}
              sessions={sessions}
              isEditable={isEditable}
              timezone={timezone}
            />
          ))
      )}
    </div>
  )
}

SessionsList.propTypes = {
  schedule: PropTypes.object,
  className: PropTypes.string,
  timezone: PropTypes.string.isRequired,
  showPastSessions: PropTypes.bool,
  isEditable: PropTypes.bool,
}

export default SessionsList
