import { getDashboardProgram } from '../actions/programActions'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { getAllCommunityStudents } from '../actions/communitiesActions'
import { addEnrollmentManually, deleteEnrollment, updateEnrollment } from '../actions/enrollmentActions'
import { getProgramSchedule } from '../actions/scheduleActions'
import { deleteScheduleSession, updateScheduleSession } from '../actions/scheduleSessionActions'
import { defaultScheduleSessionCompareFunction, defaultUserEnrollmentCompareFunction } from '../utility/helperFunctions'

const allCommunityStudentsAdapter = createEntityAdapter()

const initialState = {
  program: null,
  schedule: null,
  isLoading: false,
  allCommunityStudents: allCommunityStudentsAdapter.getInitialState(),
  isLoadingAllStudents: false,
  isLoadingScheduleTab: true,
}

const programDashboardReducer = createSlice({
  name: 'programDashboard',
  initialState,
  reducers: {
    resetDashboard: () => initialState,
  },
  extraReducers: builder => {
    builder
      // Get Program Overview Data
      .addCase(getDashboardProgram.pending, state => {
        state.isLoading = true
      })
      .addCase(getDashboardProgram.fulfilled, (state, action) => {
        state.program = action.payload.course
        state.isLoading = false
      })

      // Get All Community Students Data
      .addCase(getAllCommunityStudents.pending, state => {
        state.isLoadingAllStudents = true
      })
      .addCase(getAllCommunityStudents.fulfilled, (state, action) => {
        allCommunityStudentsAdapter.setAll(state.allCommunityStudents, action.payload.students)
        state.isLoadingAllStudents = false
      })

      // Add student manually
      .addCase(addEnrollmentManually.fulfilled, (state, action) => {
        const enrollment = action.payload.enrollment
        const user = {
          id: enrollment.user.id,
          changes: {
            enrollments: [{
              courseId: enrollment.courseId,
              userId: enrollment.user.id,
              status: enrollment.status,
            }],
            actionFailed: false,
          },
        }

        allCommunityStudentsAdapter.updateOne(state.allCommunityStudents, user)
        if (state.program) {
          state.program.enrollments.push(enrollment)
          state.program.enrollments.sort(defaultUserEnrollmentCompareFunction)
        }
      })
      .addCase(addEnrollmentManually.rejected, (state, action) => {
        const user = { id: action.payload.userId, changes: { actionFailed: true } }

        allCommunityStudentsAdapter.updateOne(state.allCommunityStudents, user)
      })

      // Update enrollment
      .addCase(updateEnrollment.fulfilled, (state, action) => {
        const enrollment = action.payload.enrollment

        if (state.program) {
          const index = state.program.enrollments.findIndex(e => e.id === enrollment.id)
          state.program.enrollments[index] = Object.assign(state.program.enrollments[index], state.program.enrollments[index], enrollment)
        }
      })

      // Delete enrollment
      .addCase(deleteEnrollment.fulfilled, (state, action) => {
        const enrollmentId = action.payload.enrollment.id

        if (state.program) {
          const index = state.program.enrollments.findIndex(e => e.id === enrollmentId)
          state.program.enrollments.splice(index, 1)
        }
      })

      // Get Schedule Tab data
      .addCase(getProgramSchedule.pending, state => {
        state.isLoadingScheduleTab = true
      })
      .addCase(getProgramSchedule.fulfilled, (state, action) => {
        state.schedule = action.payload.schedule
        state.isLoadingScheduleTab = false
      })

      // Update single Schedule Session
      .addCase(updateScheduleSession.fulfilled, (state, action) => {
        if (state.schedule) {
          const index = state.schedule.scheduleSessions.findIndex(session => session.id === action.payload.scheduleSession.id)
          state.schedule.scheduleSessions[index] = action.payload.scheduleSession
          state.schedule.scheduleSessions.sort(defaultScheduleSessionCompareFunction)
        }
      })

      // Delete Single Schedule Session
      .addCase(deleteScheduleSession.fulfilled, (state, action) => {
        if (state.schedule) {
          const index = state.schedule.scheduleSessions.findIndex(session => session.id === action.payload.scheduleSession.id)
          state.schedule.scheduleSessions.splice(index, 1)
        }
      })
  },
})

export const {
  resetDashboard,
} = programDashboardReducer.actions

export const {
  selectAll: selectAllCommunityStudents,
} = allCommunityStudentsAdapter.getSelectors(state => state.programDashboard.allCommunityStudents)

export default programDashboardReducer.reducer
