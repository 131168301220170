import Modal from 'react-modal'
import PropTypes from 'prop-types'

import { ReactComponent as ModalXIcon } from '../../../../assets/images/common/icons/modal-X-icon.svg'
import { ReactComponent as LiveProgramIcon } from '../../../../assets/images/common/icons/programBuilder/live-program-icon.svg'
import { ReactComponent as DraftProgramIcon } from '../../../../assets/images/common/icons/programBuilder/draft-program-icon.svg'
import { ReactComponent as OpenEyeIcon } from '../../../../assets/images/common/icons/open-eye-icon.svg'
import { ReactComponent as ClosedEyeIcon } from '../../../../assets/images/common/icons/closed-eye-icon.svg'
import { ReactComponent as LockIcon } from '../../../../assets/images/common/icons/lock-icon.svg'
import SubmitButton from '../../../common/buttons/SubmitButton'

import './LiveModal.scss'
import { useNavigate } from 'react-router-dom'

const LiveModal = ({ program, showModal, onClose }) => {
  const navigate = useNavigate()
  const programPage = `${window.location.origin}/landing/${program.communityId}/class/${program.id}`

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={onClose}
      contentLabel='Program Builder Live Modal'
      className='live-program-builder-modal-content'
      autoFocus
    >
      <div id='LiveModal'>
        <div className='animated fadeIn'>
          <div>
            <div className='d-flex justify-content-end text-align-right'>
              <ModalXIcon id='modalXIcon' onClick={onClose} />
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center'>
              {program.is_draft ? <DraftProgramIcon id='mainIcon' /> : <LiveProgramIcon id='mainIcon' />}
              <p className='title'>
                {program.is_draft ? 'Your Program has been Saved as a Draft.' : 'Congratulations! Your Program is Now Live.'}
              </p>
              <p className='text-center message'>
                {program.is_draft ? `${program.name || ''} Saved as Draft` : `${program.name} is Live Now`}
              </p>
              <div className='d-flex align-items-center message'>
                {program.visibility === 'public' && <OpenEyeIcon className='open-eye-icon' style={{ flexShrink: '0', marginRight: '8px' }} />}
                {program.visibility === 'unlisted' && <ClosedEyeIcon className='closed-eye-icon' style={{ flexShrink: '0', marginRight: '8px' }} />}
                {program.visibility === 'private' && <LockIcon className='lock-icon' style={{ flexShrink: '0', marginRight: '8px' }} />}

                Visibility - {program.visibility.charAt(0).toUpperCase() + program.visibility.slice(1)}
              </div>
              {(!program.is_draft && program.visibility !== 'private') && (
                <div className='d-flex align-items-center w-100 justify-content-between mt-2 px-2'>
                  <div className='link-container'>
                    <p className='program-page'>Link to Program Page:</p>
                    <a className='link-program-page' href={programPage} target='_blank'>
                      {programPage}
                    </a>
                  </div>
                  <button
                    className='btn btn-primary navy-button copy-link-button ml-2'
                    onClick={() => navigator.clipboard.writeText(programPage)}
                  >
                    Copy Link
                  </button>
                </div>
              )}
            </div>
            <div className='row py-3'>
              <div className='col-12'>
                {(!program.is_draft && program.visibility !== 'private')
                  ? (
                    <SubmitButton
                      onClick={() => navigate(`/landing/${program.community_id}/class/${program.id}`)}
                      buttonMessage='View Registration Page'
                      buttonColor='orange-button'
                    />
                  ) : (
                    <SubmitButton
                      onClick={() => navigate(`/programs/${program.id}/edit`)}
                      buttonMessage='Go back to editing'
                      buttonColor='orange-button'
                    />
                  )
                }
              </div>
              <div className='col-12'>
                <SubmitButton
                  onClick={onClose}
                  buttonMessage='Close'
                  buttonColor='navy-button'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

LiveModal.propTypes = {
  program: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default LiveModal
