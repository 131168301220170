export function displayWithCurrency(amount, currency, noDecimals = false) {
  noDecimals = currency?.zeroDecimal || noDecimals

  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: noDecimals ? 0 : 2,
    minimumFractionDigits: noDecimals ? 0 : 2,
    style: 'currency',
    currency: currency?.code,
  }).format(amount)
}
