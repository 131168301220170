import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { incrementFiltersCounter, initialFilters } from '../../../reducers/dashboardReducer'
import LeaderboardFilters from './LeaderboardFilters'
import LeaderboardWrapper from '../../common/leaderboard/LeaderboardWrapper'

import styles from './CoachDashboardOp36.module.scss'

const CoachDashboardCommunityLeaderboard = () => {
  const dispatch = useDispatch()
  const { leaderboardFilters } = useSelector(state => state.dashboard)

  const filtersApplied = !_.isEqual(leaderboardFilters, initialFilters)

  return (
    <div className={classnames('p-3', styles.communityLeaderboard)}>
      <div className={classnames('d-flex justify-content-between align-items-center', styles.leaderboardHeader)}>
        <h3 className={styles.title}>Community Leaderboard</h3>
        <LeaderboardFilters />
      </div>
      <LeaderboardWrapper
        withDetailedLeaderboard
        withClearFiltersButton={filtersApplied}
        filters={leaderboardFilters}
        onClearFiltersClick={() => dispatch(incrementFiltersCounter())}
        className={classnames('pt-3', styles.leaderboardWrapper)}
      />
    </div>
  )
}

export default CoachDashboardCommunityLeaderboard
