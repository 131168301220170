/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { Collapse } from 'reactstrap'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as userActions from '../../actions/userActions'
import * as commonActions from '../../actions/commonActions'
import * as lessonPlanActions from '../../actions/lessonPlanActions'
import colors from '../../assets/styles/globals.scss'
// import { Link } from 'react-router';

// import blankIcon from "../../assets/images/common/icons/lesson_plans/blank-template.png";

import './LessonPlanPage.scss'
// import Loader from '../common/Op36Loader-web';
import AddLessonPlanModal from './modals/AddLessonPlanModal'
// import LessonPlanActionModal from "./modals/LessonPlanActionModal";
import TwoButtonModal from './modals/TwoButtonModal'
// import ChooseTemplateModal from "./modals/ChooseTemplateModal";
import Loader from '../common/Op36Loader-web'
import LessonPlanCard from './subcomponents/LessonPlanCard'
import PlaceholderLPCard from './subcomponents/PlaceholderLPCard'
import PageHeader from '../common/PageHeader/PageHeader'
import { withRouting } from '../common/hooks'
import SearchInput from '../common/SearchInput'
import { UncontrolledPopover } from 'reactstrap'
import PropTypes from 'prop-types'
import { ReactComponent as TableSelectDropDownIcon } from '../../assets/images/common/icons/drop-down-arrow.svg'

class LessonPlanPage extends React.Component {
  state = {
    loader: true, // initial loader
    view: 'finished', // which selection is active on submenu
    searchTerm: '',
    searchLoader: false,
    pinnedOpen: true, // pinned bar is open or closed
    addButtonClicked: false, // triggers modal on creating new lesson plan
    deleteModal: -1, // delete lesson plan confirmation
    templateModal: false, // template picker modal if you select to build from template
    op36TemplateLoader: true, // loader for loading up op36 templates
    picker: 'my_lesson_plans', // switch from My Lesson Plans to Community Lesson Plans
    pickerPopover: false, // shows popover if user clicks to switch 'picker' value
    tabIndex: 0,
    viewsIndex: ['finished', 'drafts', 'all-plans', 'in-use'],
  }

  componentDidMount() {
    this.props.common_actions.setCurrentTab('lesson-plans')
    this.props.common_actions.showNavBar(true)
    this.props.common_actions.showSideBar(true)
    this.props.lessonPlan_actions
      .grabLessonPlans(this.props.user.id)
      .then(() => {
        this.setState({ loader: false })
      })
    if (this.props.user.type !== 'Admin') {
      this.props.lessonPlan_actions.grabOp36Templates().then(() => {
        this.setState({ op36TemplateLoader: false })
      })
    }
  }

  render() {
    const is_admin = this.props.user.type === 'Admin'
    const { loader, deleteModal, picker } = this.state
    const { lesson_plans } = this.props
    const pageHeaderTitle = is_admin ?
      'Lesson Plan Templates'
      : picker === 'my_lesson_plans'
        ? 'My Lesson Plans'
        : 'Community Lesson Plans'
    return (
      <div id='LessonPlanPage'>
        <PageHeader title={pageHeaderTitle}
          titleActionComponent={!is_admin && this._headerTitleActionButton()}
          actionComponent={this._headerActionButton()}
        />
        <Tabs className='tabs' selectedIndex={this.state.tabIndex} onSelect={(index) => this._changeView(this.state.viewsIndex[index], index)}>
          {picker === 'my_lesson_plans' &&
            <TabList className='tabList'>
              <Tab className='tab react-tabs__tab' selectedClassName='activeTab'>Finished</Tab>
              <Tab className='tab react-tabs__tab' selectedClassName='activeTab'>Drafts</Tab>
              <Tab className='tab react-tabs__tab' selectedClassName='activeTab'>All Plans</Tab>
              {!is_admin && <Tab className='tab react-tabs__tab' selectedClassName='activeTab'>In Use</Tab>}
            </TabList>
          }
          <div className='lesson-plans-search-bar mt-2'>
            <SearchInput placeholder={
              is_admin ? 'Search Templates' : 'Search My Lesson Plans'
            }
            onChange={this._searchTerm}/>
          </div>
          {!is_admin && (
            <div className='pinned-bar'>
              <div className='row'>
                <div className='col-12 d-flex align-items-center justify-content-start'>
                  <div className='pinned-toggler'
                    onClick={this._togglePinned}
                    onKeyDown={this._togglePinned}
                    role='button'
                    tabIndex={0}
                  >
                    <i
                      className={`fa ${
                        this.state.pinnedOpen
                          ? 'fa-chevron-up'
                          : 'fa-chevron-down'
                      }`}
                    />
                  </div>{' '}
                  <h5>
                    Pinned
                    {this.state.pinnedOpen ? (
                      ''
                    ) : (
                      <span className='pin-count'>
                        {this.props.lesson_plans.pinned.length}
                      </span>
                    )}
                  </h5>
                </div>
              </div>
              <Collapse isOpen={this.state.pinnedOpen}>
                <div className='row'>
                  <div className='col-12 pinned-area'>
                    {loader ? (
                      <div className='no-pad'>
                        <Loader message={'Grabbing pinned Lesson Plans'} />
                      </div>
                    ) : (
                      <div className='pinned-card-container'>
                        {this._renderCards('pinned')}
                      </div>
                    )}
                  </div>
                </div>
              </Collapse>
            </div>
          )}
          {picker === 'my_lesson_plans' ?
            (
              <>
                <TabPanel>
                  {this._renderCardsContainer('finished', is_admin)}
                </TabPanel><TabPanel>
                  {this._renderCardsContainer('drafts', is_admin)}
                </TabPanel><TabPanel>
                  {this._renderCardsContainer('all-plans', is_admin)}
                </TabPanel>
                {!is_admin &&
                  <TabPanel>
                    {this._renderCardsContainer('in-use', is_admin)}
                  </TabPanel>
                }
              </>
            )
            :
            (
              this._renderCardsContainer('finished', is_admin)
            )
          }

        </Tabs>
        {this.state.addButtonClicked && (
          <AddLessonPlanModal
            close={this._handleAddButtonClick}
            back={this._templateModalBack}
            templates={lesson_plans.really_all_plans}
            selectTemplate={this._buildFromTemplate}
            op36Templates={lesson_plans.op36Templates}
            loader={this.state.op36TemplateLoader}
            is_admin={this.props.user.type === 'Admin'}
            selectBlank={this._startBlankBuild}
          />
        )}
        {deleteModal > 0 && (
          <TwoButtonModal
            title={'Delete Lesson Plan?'}
            subtitle={'This will be gone forever and cannot be retrieved'}
            close={this._closeDeleteModal}
            confirmText={'Delete'}
            confirmAction={this._handleDeleteLessonPlan}
          />
        )}
      </div>
    )
  }

  _headerActionButton = () => (
    <button
      className='btn btn-primary text-white pull-right mr-2'
      onClick={this._handleAddButtonClick}
    >
      New Lesson Plan
    </button>
  )

  _headerTitleActionButton = () => (
    <div className='title-dropdown'>
      <TableSelectDropDownIcon stroke={colors.lightNavy} id='titleDropdownIcon'/>
      <UncontrolledPopover
        placement='bottom'
        target='titleDropdownIcon'
        trigger='legacy'
        popperClassName='popover-container'
      >
        <div>
          <button
            className='title-button'
            onClick={() =>
              this._handlePickerChange('my_lesson_plans')
            }
          >
            My Lesson Plans
          </button>
          <button
            className='title-button'
            onClick={() =>
              this._handlePickerChange('community_lesson_plans')
            }
          >
            Community Lesson Plans
          </button>
        </div>
      </UncontrolledPopover>
    </div>
  )

  _handlePickerChange = (val) => {
    const { picker } = this.state
    if (picker !== val) {
      this.setState({ picker: val })
    }
  }

  _renderCardsContainer = (view, is_admin) => (
    <div className='lesson-content-container'>
      <div className='lesson-plans-container'>
        <h5>{is_admin ? 'Templates' : 'Plans'}</h5>
        {this.state.loader ? (
          <div className='no-pad'>
            <Loader message={'Loading Lesson Plans'} />
          </div>
        ) : (
          <div className='lesson-plan-card-container'>
            {this._renderCards(view)}
          </div>
        )}
      </div>
    </div>
  )

  _renderCards = (view) => {
    const is_admin = this.props.user.type === 'Admin'
    const { searchLoader, picker } = this.state
    const { user } = this.props
    const lesson_plans =
      picker === 'my_lesson_plans' || view === 'pinned'
        ? this.props.lesson_plans
        : this.props.lesson_plans.community_lesson_plans
    if (searchLoader) {
      return <Loader message={'Searching Lesson Plans'} />
    }
    let filteredPlans = []
    if (picker === 'my_lesson_plans' || view === 'pinned') {
      switch (view) {
        case 'finished':
          filteredPlans = this._applyFilter(lesson_plans.finished)
          return filteredPlans.length > 0 ? (
            filteredPlans.map((lp) => (
              <LessonPlanCard
                key={lp.id}
                plan={lp}
                isPinnable={!lp.is_draft && !is_admin}
                handlePinClick={this._handlePinClick}
                triggerDeleteModal={this._triggerDeleteModal}
                current_user_id={user.id}
                is_admin={is_admin}
              />
            ))
          ) : (
            <PlaceholderLPCard type={'finished'} />
          )
        case 'drafts':
          filteredPlans = this._applyFilter(lesson_plans.drafts)
          return filteredPlans.length > 0 ? (
            filteredPlans.map((lp) => (
              <LessonPlanCard
                key={lp.id}
                plan={lp}
                isPinnable={!lp.is_draft && !is_admin}
                handlePinClick={this._handlePinClick}
                triggerDeleteModal={this._triggerDeleteModal}
                current_user_id={user.id}
                is_admin={is_admin}
              />
            ))
          ) : (
            <PlaceholderLPCard type={'draft'} />
          )
        case 'all-plans':
          filteredPlans = this._applyFilter(lesson_plans.all_plans)
          return filteredPlans.length > 0 ? (
            filteredPlans.map((lp) => (
              <LessonPlanCard
                key={lp.id}
                plan={lp}
                isPinnable={!lp.is_draft && !is_admin}
                handlePinClick={this._handlePinClick}
                triggerDeleteModal={this._triggerDeleteModal}
                current_user_id={user.id}
                is_admin={is_admin}
              />
            ))
          ) : (
            <PlaceholderLPCard type={''} />
          )
        case 'in-use':
          filteredPlans = this._applyFilter(lesson_plans.in_use)
          return filteredPlans.length > 0 ? (
            filteredPlans.map((lp) => (
              <LessonPlanCard
                key={lp.id}
                plan={lp}
                isPinnable={!lp.is_draft && !is_admin}
                handlePinClick={this._handlePinClick}
                triggerDeleteModal={this._triggerDeleteModal}
                current_user_id={user.id}
                is_admin={is_admin}
              />
            ))
          ) : (
            <PlaceholderLPCard type={"'in use'"} />
          )
        case 'pinned':
          return lesson_plans.pinned.length > 0 ? (
            lesson_plans.pinned.map((lp) => (
              <LessonPlanCard
                key={lp.id}
                plan={lp}
                isPinnable={true && !is_admin}
                handlePinClick={this._handlePinClick}
                triggerDeleteModal={this._triggerDeleteModal}
                current_user_id={user.id}
                is_admin={is_admin}
              />
            ))
          ) : (
            <PlaceholderLPCard type={'pinned'} />
          )
        default:
          return <p>something went wrong</p>
      }
    } else {
      filteredPlans = this._applyFilter(lesson_plans)
      return filteredPlans.length > 0 ? (
        filteredPlans.map((lp) => (
          <LessonPlanCard
            key={lp.id}
            plan={lp}
            isPinnable={!lp.is_draft && !is_admin && user.id === lp.user_id}
            handlePinClick={this._handlePinClick}
            triggerDeleteModal={this._triggerDeleteModal}
            current_user_id={user.id}
            is_admin={is_admin}
          />
        ))
      ) : (
        <PlaceholderLPCard type={'community'} />
      )
    }
  }

  _applyFilter = (lessonPlans) => {
    const { searchTerm } = this.state
    if (!searchTerm) {
      return lessonPlans
    }
    return lessonPlans.filter((lp) => {
      let val = false
      if (lp.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return true
      }

      lp.tags.forEach((tag) => {
        if (tag.toLowerCase().includes(searchTerm.toLowerCase())) {
          val = true
        }
      })
      return val
    })
  }

  _triggerDeleteModal = (id) => {
    this.setState({ deleteModal: id })
  }

  _closeDeleteModal = () => {
    this.setState({ deleteModal: -1 })
  }

  _handleDeleteLessonPlan = () => {
    this.props.lessonPlan_actions
      .deleteLessonPlan(this.state.deleteModal)
      .then(() => {
        this.setState({ deleteModal: -1 })
      })
  }

  _handlePinClick = (e, id) => {
    e.stopPropagation()
    this.props.lessonPlan_actions.handlePinClick(id)
  }

  _startBlankBuild = () => {
    this.props.navigate('/lesson-plan/builder')
  }

  _buildFromTemplate = (id) => {
    this.props.navigate(`/lesson-plan/builder/${id}/new`)
  }

  _chooseTemplate = () => {
    this.setState({ templateModal: true, addButtonClicked: false })
  }

  _templateModalBack = () => {
    this.setState({ addButtonClicked: true, templateModal: false })
  }

  _hardCloseTemplateModal = () => {
    this.setState({ templateModal: false })
  }

  _handleAddButtonClick = () => {
    this.setState({ addButtonClicked: !this.state.addButtonClicked })
  }

  _togglePinned = () => {
    this.setState({ pinnedOpen: !this.state.pinnedOpen })
  }

  // _searchTerm = term => {
  //   this.setState({ searchTerm: term });
  // };

  _searchTerm = (e) => {
    const term = e.target.value
    this.setState({ searchLoader: true }, () => {
      this.setState({ searchTerm: term }, () => {
        this.setState({ searchLoader: false })
      })
    })
  }

  _changeView = (view, index) => {
    const prevView = this.state.view
    if (view !== prevView) {
      this.setState({ view, tabIndex: index })
    }
  }
}

LessonPlanPage.propTypes = {
  navigate: PropTypes.func,
  lessonPlan_actions: PropTypes.object,
  user: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
  }),
  common_actions: PropTypes.shape({
    setCurrentTab: PropTypes.func,
    showNavBar: PropTypes.func,
    showSideBar: PropTypes.func,
  }),
  lesson_plans: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      community_lesson_plans: PropTypes.array,
      really_all_plans: PropTypes.array,
      op36Templates: PropTypes.array,
      pinned: PropTypes.array,
    }),
  ]),
}

function mapStateToProps(state) {
  return {
    ui: state.ui,
    user: state.user.current_user,
    lesson_plans: state.lessonPlans,
    community_lesson_plans: state.lessonPlans.community_lesson_plans,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    lessonPlan_actions: bindActionCreators(lessonPlanActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouting(LessonPlanPage))
