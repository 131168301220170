import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PlacesAutocomplete from 'react-places-autocomplete'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as communitiesActions from '../../actions/communitiesActions'
import colors from '../../assets/styles/globals.scss'
import { ReactComponent as SearchIcon } from '../../assets/images/common/icons/search-icon.svg'
import CommunityCard from './CommunityCard'
import CommunityPlaceholder from './CommunityPlaceholder'
import NoNearbyCommunities from './NoNearbyCommunities'
import Input from '../common/form/Input'
import MCElement from '../common/MCElement'
import LoadingSpinner from '../common/LoadingSpinner'
import certBadge from '../../assets/images/pins/certified_preview_badge.svg'
import top50Badge from '../../assets/images/pins/top50_preview_badge.svg'

import classes from './MapSidebar.module.scss'

const MapSidebar = ({ filters, reCenterMap, handleFilterClick }) => {
  const dispatch = useDispatch()

  const [address, setAddress] = useState('')
  const [communityLoader, setCommunityLoader] = useState(false)
  const [nearbyCommunities, setNearbyCommunities] = useState(null)

  const handleSearch = useCallback((value) => {
    setAddress(value)
    setCommunityLoader(true)

    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode(
      { address: value },
      (response, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          const lat = response[0].geometry.location.lat()
          const lng = response[0].geometry.location.lng()
          const service = new window.google.maps.DistanceMatrixService()

          reCenterMap(lat, lng)
          dispatch(communitiesActions.getNearbyCommunities({ lat, lng, limit: 25 }))
            .then((res) => {
              const responseCommunities = res.payload.communities
              if (responseCommunities.length === 0) {
                setNearbyCommunities([])
                setCommunityLoader(false)

                return
              }

              const communitiesCoordinates = responseCommunities.map((community) => (
                new window.google.maps.LatLng(community.address.lat, community.address.lng)
              ))

              service.getDistanceMatrix(
                {
                  origins: [new window.google.maps.LatLng(lat, lng)],
                  destinations: communitiesCoordinates,
                  travelMode: 'DRIVING',
                  avoidHighways: false,
                  avoidTolls: false,
                  unitSystem: window.google.maps.UnitSystem.IMPERIAL,
                },
                (res, status) => {
                  if (status === 'OK') {
                    const enhancedCommunities = []

                    for (let index = 0; index < responseCommunities.length; index++) {
                      enhancedCommunities.push({
                        ...responseCommunities[index],
                        distance: res.rows[0].elements[index].distance,
                        duration: res.rows[0].elements[index].duration,
                      })
                    }

                    setNearbyCommunities(enhancedCommunities)
                  } else {
                    setNearbyCommunities(responseCommunities)
                  }

                  setCommunityLoader(false)
                }
              )
            })
        } else {
          setNearbyCommunities(null)
          setCommunityLoader(false)
        }
      }
    )
  }, [dispatch, reCenterMap])

  useEffect(() => {
    const communityMapAddress = localStorage.getItem('communityMapAddress')
    if (communityMapAddress) {
      setAddress(communityMapAddress)
      handleSearch(communityMapAddress)

      localStorage.removeItem('communityMapAddress')
    }
  }, [handleSearch])

  const nearbyFilteredCommunities = useCallback(() => {
    if (!nearbyCommunities) {
      return []
    }

    if (filters.length === 0) {
      return nearbyCommunities.sort((a, b) => a.distance.value - b.distance.value)
    }

    return nearbyCommunities.filter((community) => (
      (filters.includes('certified') && community.isCertified) || (filters.includes('top50') && community.isTop50)
    )).sort((a, b) => a.distance.value - b.distance.value)
  }, [nearbyCommunities, filters])

  return (
    <div className={classNames(classes.mapSidebar, 'd-flex flex-column align-items-center')}>
      <section className={classNames(classes.topSearchArea, 'd-flex flex-column flex-nowrap')}>
        <h3 className={classes.searchTitle}>Find a Program Training Facility</h3>

        <div className={classes.searchContainer}>
          <PlacesAutocomplete
            onChange={(value) => setAddress(value)}
            onSelect={(value) => handleSearch(value)}
            value={address}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
              <div>
                <Input
                  {...getInputProps({
                    className: classes.searchInput,
                    placeholder: 'Search a location',
                  })}
                  symbol={<SearchIcon className={classes.searchIcon} />}
                />

                <div className={classNames({ [classes.searchDropdownContainer]: suggestions.length > 0 })}>
                  {suggestions.map((suggestion, index) => (
                    <div
                      key={index}
                      {...getSuggestionItemProps(
                        suggestion,
                        { className: classNames(classes.suggestionItem, { [classes.activeSuggestionItem]: suggestion.active }) }
                      )}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>

        <div className={classNames(classes.filterCheckboxContainer, 'mt-3')}>
          <div className={classNames(classes.checkboxWrapper, 'd-flex align-items-center w-100')}>
            <MCElement
              type='checkbox'
              isSelected={filters.indexOf('top50') > -1}
              clicked={() => handleFilterClick('top50')}
              primaryColor={colors.primaryOrange}
              secondaryColor={colors.whiteColor}
            />

            <img src={top50Badge} alt='top50' className={classes.filterBadge} />

            <div className={classes.filterInformation}>
              <h5 className={classes.filterTitle}>
                Top 50 Coach Locations
              </h5>

              <small className={classes.filterDescription}>
                Led by professionals that have done extensive training and run world-class locations.
              </small>
            </div>
          </div>

          <div className={classNames(classes.checkboxWrapper, 'd-flex align-items-center mt-3 w-100')}>
            <MCElement
              type='checkbox'
              isSelected={filters.indexOf('certified') > -1}
              clicked={() => handleFilterClick('certified')}
              primaryColor={colors.primaryOrange}
              secondaryColor={colors.whiteColor}
            />

            <img src={certBadge} alt='certified' className={classes.filterBadge} />

            <div className={classes.filterInformation}>
              <h5 className={classes.filterTitle}>
                Certified Coach Locations
              </h5>

              <small className={classes.filterDescription}>
                Hand-picked by us in HQ for demonstrating the best practices in the network.
              </small>
            </div>
          </div>
        </div>
      </section>

      <section className={classNames(classes.communityContentArea, { 'd-flex justify-content-center': nearbyFilteredCommunities().length === 0 })}>
        {!nearbyCommunities ? (
          <CommunityPlaceholder />
        ) : (
          communityLoader ? (
            <LoadingSpinner
              classNames={{
                wrapper: 'h-100',
                spinner: classNames(classes.searchSpinner, 'fa-4x'),
              }}
            />
          ) : (
            nearbyFilteredCommunities().length === 0 ? (
              <NoNearbyCommunities address={address} />
            ) : (
              nearbyFilteredCommunities().map((community) =>
                <div key={community.id} className='mb-2'>
                  <CommunityCard community={community} />
                </div>
              )
            )
          )
        )}
      </section>
    </div>
  )
}

MapSidebar.propTypes = {
  filters: PropTypes.array,
  reCenterMap: PropTypes.func,
  handleFilterClick: PropTypes.func,
}

export default MapSidebar
